import React from "react";

import HighFidelity from "../HighFidility";

export default function HighFid() {
  return (
    <div className="w-full max-w-7xl mx-auto">
      <div className="flex flex-col lg:flex-row bg-white rounded-xl overflow-hidden">
        <div className="lg:w-1/2 p-8 lg:p-12 ">
          <div className="space-y-6">
            <h2 className="text-3xl lg:text-4xl font-bold text-primary">
              Comprehensive monitoring of your machines
            </h2>

            <p className="text-primary text-lg">
              Capture intricate machine behavior and operational patterns across
              your entire plant. High-frequency sampling reveals hidden
              connections, anomalies, and optimization opportunities in your
              industrial network.
            </p>
          </div>
        </div>
        {/* Right Image Section */}
        <div className="lg:w-1/2">
          <div className="h-full relative flex items-center justify-center">
            <HighFidelity />
          </div>
        </div>
      </div>
    </div>
  );
}
