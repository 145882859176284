import React from "react";

const Industries = () => {
  const industryTypes = [
    "Manufacturing & Heavy Industries",
    "Process Manufacturing",
    "Energy Generation",
    "Utilities & Infrastructure",
    "Transportation & Mobility",
  ];

  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-12 md:py-16">
      {/* Title Section */}
      <div className="mb-12">
        <h2 className="text-3xl md:text-4xl font-bold text-[#14387F] mb-4">
          Industries
        </h2>
        <div className="w-24 h-1 bg-secondary rounded-full mb-8"></div>

        {/* Description paragraphs */}
        <div className="text-primary text-base leading-tight">
          <p className="mb-4">
            Every industry defines the nature of operations which eventually
            defines the complexity of the problem around machines. iHz™ is a
            industrial IoT system that is applied in industries after thoroughly
            evaluating and understanding the intrinsic complexity of operations.
          </p>
          <p className="mb-4">
            Based on our experience, the nature of the heavy industries like
            Steel plants, makes the machines to take heavy lateral thrusts
            compare to chemical plants where the radial forces are high on
            machines. Pumps in water treatment and utilities are observed to be
            having few fault modes but the cost of not predicting early is very
            high. The effect of cavitation in a pump severely affects the
            plant's OEE than that in steel plants. There are fans in Chemical
            plants operated in hazardous and also inaccessible areas due to
            which the cost of false positive is very high.
          </p>
          <p className="mb-4">
            We optimize our iHz™ systems to accurately diagnose these phenomena,
            minimizing false positives, improving certainty in fault detection,
            simplifying device maintenance, and ensuring scalability without
            additional operational costs. This empowers users to proactively
            prevent issues, breaking the cycle of reactive corrections and
            moving towards complete prevention.
          </p>
          <p className="mb-4">
            Contact us to schedule a workshop on our real-life industrial case
            studies with our iHz™ industrial IoT system which is accurate and
            reliable for your electrical rotating equipment.
          </p>
        </div>
      </div>

      {/* Grid of industry types */}
      <div className="max-w-3xl">
        <ul className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {industryTypes.map((useCase, index) => (
            <li
              key={index}
              className="text-primary text-base p-2 flex items-start"
            >
              <span className="mr-2">•</span>
              {useCase}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Industries;
