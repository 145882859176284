import React from "react";
import whiteLogo from "../assets/whitelogo-mintoai.png";

function getLocalISOString(date) {
  const offset = date.getTimezoneOffset();
  const offsetAbs = Math.abs(offset);
  const offsetSign = offset > 0 ? "-" : "+";
  const offsetHours = String(Math.floor(offsetAbs / 60)).padStart(2, "0");
  const offsetMinutes = String(offsetAbs % 60).padStart(2, "0");

  return (
    date.getFullYear() +
    "-" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(date.getDate()).padStart(2, "0") +
    "T" +
    String(date.getHours()).padStart(2, "0") +
    ":" +
    String(date.getMinutes()).padStart(2, "0") +
    ":" +
    String(date.getSeconds()).padStart(2, "0") +
    offsetSign +
    offsetHours +
    ":" +
    offsetMinutes
  );
}

const currentDate = getLocalISOString(new Date()).split("T")[0];
const currentTime = new Date().toLocaleTimeString();
const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="bg-primary text-white py-12 px-8">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row justify-between items-start mb-8">
          <div className="text-2xl font-bold mb-4 lg:mb-0">
            <h3>
              spiderAI™{" "}
              <div className="h-4 w-[2px] bg-gray-300 mx-1 inline-block"></div> iHz™
            </h3>
          </div>
          <div className="text-sm">{`${currentDate}  ${currentTime}`}</div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-12 items-start">
          <div className="lg:col-span-1 flex flex-col">
            <div className="w-full h-20 sm:h-24 lg:h-28 mb-6">
              <img
                src={whiteLogo}
                alt="minto.ai logo"
                className="h-full w-auto object-contain object-left"
              />
            </div>

            <p className="text-base sm:text-base mb-6">
              Reliable and Accurate Electrical Signature Analysis: iHz™ -
              Advanced Monitoring System for Your Critical Assets.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-4">
              <h3 className="font-semibold text-xl mb-3">Pages</h3>
              <ul className="text-sm sm:text-base space-y-2">
                {[
                  "Home",
                  "spiderAI™",
                  "iHz™",
                  "About ",
                  "Blog",
                  "Contact Us",
                ].map((item, index) => (
                  <li key={index}>
                    <a
                      href={
                        item === "Home"
                          ? "/"
                          : `/${item
                            .toLowerCase()
                            .replace(/™/g, "")
                            .replace(/\s+/g, "")}`
                      }
                      className="hover:text-[#f87171] transition duration-300"
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="space-y-4">
              <h3 className="font-semibold text-xl mb-3">Socials</h3>
              <ul className="text-sm sm:text-base space-y-2">
                {[
                  {
                    name: "Facebook",
                    url: "https://www.facebook.com/profile.php?id=100042907616152",
                  },
                  {
                    name: "Instagram",
                    url: "https://www.instagram.com/inside_minto.ai/",
                  },
                  {
                    name: "Twitter",
                    url: "https://x.com/mintoai_iiot?lang=en",
                  },
                  {
                    name: "LinkedIn",
                    url: "https://in.linkedin.com/company/mintoai",
                  },
                ].map((item, index) => (
                  <li key={index}>
                    <a
                      href={item.url}
                      className="hover:text-[#f87171] transition duration-300"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="font-semibold text-xl mb-3">Address</h3>
            <p className="text-sm sm:text-base">
              Plot no. 114, at sy no 66/2 Street No. 03, floor no. 04, Rai
              Durgam, Prashanth Hills Gachi Bowli, Nav Khalsa, Serilingampally,
              Hyderabad, Telangana 500008
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
