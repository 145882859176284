import React from "react";

const MachinesEquipment = () => {
  const machines = [
    "Pumps",
    "Rod Mills",
    "Compressors",
    "Conveyor Belts",
    "Industrial Fans",
    "Mixers",
    "Induction Motors",
    "Other",
  ];

  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-12 md:py-16">
      {/* Title Section */}
      <div className="mb-12">
        <h2 className="text-3xl md:text-4xl font-bold text-[#14387F] mb-4">
          Machines & Equipment
        </h2>
        <div className="w-24 h-1 bg-secondary rounded-full mb-8"></div>

        {/* Description paragraphs */}
        <div className="space-y-6">
          <p className="text-primary text-base leading-tight">
            Fundamentally if it is known that your operations are powered by
            rotating machines driven by AC then iHz™ is the most accurate &
            reliable technology system to implement.
          </p>
          <p className="text-primary text-base leading-tight">
            iHz™ understands how these machines work, are working and will work
            in your operational settings. iHz™ is extensively tested and proven
            over years for its IoT+AI related application to electrical rotating
            machines.
          </p>
        </div>
      </div>

      {/* Simple grid of machines */}
      <div className="max-w-3xl">
        <ul className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {machines.map((useCase, index) => (
            <li
              key={index}
              className="text-primary text-base p-2 flex items-start"
            >
              <span className="mr-2">•</span>
              {useCase}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MachinesEquipment;
