import React from "react";
import { ChevronRight } from "lucide-react";

// import ai from "../assets/tech_ai.jpg";
// import edge from "../assets/industrial-grade-design.jpg";
// import iot from "../assets/iot.jpg";
// import conversational from "../assets/technologies/conversational.png";

const ImageOverlay = ({ imageSrc, title, description, category }) => {
  return (
    <div className="relative w-full h-[600px] overflow-hidden">
      <img
        src={imageSrc || "/api/placeholder/1200/900"}
        alt="Background"
        className="w-full h-full object-cover"
      />
      <div className="absolute bottom-8 left-8 bg-primary text-white p-6 max-w-[80%]">
        <p className="text-sm font-semibold mb-2">{category}</p>
        <h3 className="text-2xl font-bold mb-2">{title}</h3>
        <p className="text-base">{description}</p>
        <ChevronRight className="absolute bottom-6 right-6 h-8 w-8" />
      </div>
    </div>
  );
};

const UseCases = () => {
  return (
    <div className=" mx-[5%]  pt-24">
      <h1 className="text-5xl font-bold text-primary ">
        Intelligent Industrial Diagnostics
      </h1>
      <div className="h-1 bg-secondary w-24 mb-12"></div>

      <p className="text-lg text-primary  max-w-3xl pb-10">
        Our technologies bridge the gap between complex industrial data and
        actionable insights. iHz, our core diagnostic system, captures intricate
        machine behaviors through advanced current signature analysis. SpiderAI
        then elevates this data, transforming it into an intelligent asset
        management platform. Together, they create a powerful ecosystem that
        simplifies industrial complexity, predicts issues before they occur, and
        optimizes your operations for peak performance
      </p>

      <div>
        <h2 className="text-4xl font-semibold text-primary ">
          To Find out More
        </h2>
        <div className="h-1 bg-secondary w-24 mb-12"></div>
      </div>

      {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16 w-full ">
        <ImageOverlay
          imageSrc={iot}
          category="EXPERTISE"
          title="IOT"
          description="Simplified explanation of ESA enabling non-invasive monitoring"
        />
        <ImageOverlay
          imageSrc={edge}
          category="EXPERTISE"
          title="Imagining, designing, maintaining and optimizing nuclear reactors"
          description="Comprehensive solutions for the entire lifecycle of nuclear reactors"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
        <ImageOverlay
          imageSrc={ai}
          category="EXPERTISE"
          title="AI assisted  analysis"
          description="Simplified explanation of ESA enabling non-invasive monitoring"
        />
        <ImageOverlay
          imageSrc={edge}
          category="EXPERTISE"
          title="Edge Computing"
          description="Specialized in creating key components for nuclear facilities"
        />
      </div> */}
    </div>
  );
};

export default UseCases;
