import { useEffect, useState } from "react";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";

import { cn } from "../../lib/utils";

export function WordRotate({
  words,
  duration = 2500,
  framerProps = {
    initial: { opacity: 0, y: -50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 50 },
    transition: { duration: 0.25, ease: "easeOut" },
  },
  className,
}) {
  const [index, setIndex] = useState(0);
  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, duration);

    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, [words, duration]);

  useEffect(() => {
    // Calculate the maximum width of all words
    const tempElement = document.createElement("span");
    tempElement.style.visibility = "hidden";
    tempElement.style.position = "absolute";
    tempElement.style.whiteSpace = "nowrap";
    document.body.appendChild(tempElement);

    const maxWidth = words.reduce((max, word) => {
      tempElement.textContent = word;
      return Math.max(max, tempElement.offsetWidth);
    }, 0);

    document.body.removeChild(tempElement);
    setMaxWidth(maxWidth);
  }, [words]);

  return (
    <div
      className="overflow-hidden"
      //   style={{ width: `${maxWidth}px`, display: "inline-block" }}
    >
      <AnimatePresence mode="wait">
        <motion.h1
          key={words[index]}
          className={cn(className)}
          {...framerProps}
        >
          {words[index]}
        </motion.h1>
      </AnimatePresence>
    </div>
  );
}
