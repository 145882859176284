import React from "react";
import {
  Calendar,
  AlertCircle,
  AlertTriangle,
  CheckCircle2,
  Clock,
  ChartLine,
  Microscope,
  DollarSign,
} from "lucide-react";
import FrequencySpectrum from './usecase_graphs/motor_graphs_dfft_hotsaw';

const FaultAnalysis2 = () => {
  const sections = [
    {
      title: "Event Details",
      icon: <AlertCircle className="w-5 h-5 text-primary" />,
      content: {
        type: "grid",
        items: [
          {
            label: "Event Date",
            value: "March 22, 2023",
            icon: <Calendar className="w-4 h-4" />
          },
          {
            label: "Event",
            value: "Motor Replaced",
            icon: <AlertTriangle className="w-4 h-4" />
          },
          {
            label: "Fault",
            value: "Motor Bearing Housing Looseness",
            icon: <AlertCircle className="w-4 h-4" />
          },
          {
            label: "Days before the Event",
            value: "10 Days",
            valueClass: "font-medium text-primary",
            icon: <Clock className="w-4 h-4" />
          }
        ]
      }
    },
    {
      title: "Observed Fault Signatures",
      icon: <ChartLine className="w-5 h-5 text-primary" />,
      content: {
        type: "list",
        items: [
          "Multiples of PPF, modulating 1X Belt rpm",
          "Multiples of PPF, modulating 1X Motor rpm",
          "↑ in Motor 1X rpm Amplitudes"
        ],
        graph: true
      }
    },
    {
      title: "Diagnosis",
      icon: <Microscope className="w-5 h-5 text-primary" />,
      content: {
        type: "list",
        items: [
          "Fault in Motor started affecting belt.",
          "Play in the Motor Bearing Housing DE or Broken Rotor Bar"
        ]
      }
    },
    {
      title: "Value Impact",
      icon: <DollarSign className="w-5 h-5 text-primary" />,
      content: {
        type: "text",
        value: "No serious breakdown and averted major damage to Motor and its Drive train."
      }
    }
  ];

  return (
    <div className="bg-white min-h-screen">
      {/* Header */}
      <div className="bg-secondary-background rounded-lg p-4 mb-6">
        <div className="flex items-center space-x-3">
          <div className="bg-blue-100 p-3 rounded-lg">
            <CheckCircle2 className="w-6 h-6" />
          </div>
          <h1 className="text-2xl font-semibold text-primary">
            Motor - Bearing Housing Play
          </h1>
        </div>
      </div>

      {/* Main Content */}
      <div className="space-y-2">
        {sections.map((section, index) => (
          <div
            key={section.title}
            className="bg-white rounded-lg p-6 hover:bg-gray-50 transition-colors duration-200"
          >
            <div className="flex items-center space-x-3 mb-4">
              <div className="bg-blue-50 p-3 rounded-lg">{section.icon}</div>
              <h2 className="text-xl font-semibold text-primary">
                {section.title}
              </h2>
            </div>

            <div className="pl-2">
              {section.content.type === "grid" && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {section.content.items.map((item, i) => (
                    <div key={i} className="flex items-center space-x-3">
                      <div className="text-primary">{item.icon}</div>
                      <div>
                        <div className="text-sm font-bold text-primary">{item.label}</div>
                        <div className={item.valueClass || "text-primary"}>
                          {item.value}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {section.content.type === "list" && (
                <div className="space-y-4">
                  <ul className="space-y-2">
                    {section.content.items.map((item, i) => (
                      <li key={i} className="flex items-start space-x-2">
                        <div className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-2" />
                        <span className="text-primary">{item}</span>
                      </li>
                    ))}
                  </ul>
                  {section.content.graph && (
                    <div className="mt-4 border rounded-lg bg-gray-50 overflow-hidden">
                      <div className="w-full max-w-full aspect-[16/5]">
                        <FrequencySpectrum />
                      </div>
                    </div>
                  )}
                </div>
              )}

              {section.content.type === "text" && (
                <p className="text-primary">{section.content.value}</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaultAnalysis2;
