import { CardStack } from "./ui/CardStack";
import { cn } from "../lib/utils";

export function HelpfullNess() {
  return (
    <div className="h-[24rem] md:h-[32rem] lg:h-[40rem] flex items-center justify-center w-full">
      <CardStack items={CARDS} />
    </div>
  );
}

export const Highlight = ({ children, className }) => {
  return (
    <span
      className={cn(
        "font-bold bg-[#184384]/[0.2] text-[#184384] dark:bg-[#184384]/[0.2] dark:text-[#184384] px-1 py-0.5",
        className
      )}
    >
      {children}
    </span>
  );
};

const CARDS = [
  {
    id: 1,
    mainHeading: "Color",
    subHeading: "Cause, Effect, Recommendation",
    content: (
      <p>
        <Highlight>spiderAI™</Highlight> leverages color to categorize and
        display crucial information such as
        <Highlight>causes, effects, and recommendations</Highlight>. This visual
        segmentation ensures quick interpretation of diagnostics, allowing
        faster decision-making in high-pressure environments.
      </p>
    ),
  },
  {
    id: 2,
    mainHeading: "Intelligent Error Handling",
    subHeading: "Seamless handling of service issues",
    content: (
      <p>
        If a particular service fails or an unexpected situation arises,{" "}
        <Highlight>spiderAI™</Highlight> can
        <Highlight>gracefully degrade or switch strategies</Highlight> without
        compromising the overall user experience. This enhances robustness and
        reliability in challenging industrial settings.
      </p>
    ),
  },
  {
    id: 3,
    mainHeading: "Users",
    subHeading: "Supporting maintenance engineers and technicians",
    content: (
      <p>
        <Highlight>spiderAI™</Highlight> serves maintenance professionals by
        providing
        <Highlight>
          actionable insights from spectrum data and diagnostics
        </Highlight>{" "}
        to improve machine reliability and efficiency in demanding industrial
        settings.
      </p>
    ),
  },
  {
    id: 4,
    mainHeading: "Personality",
    subHeading: "Empathetic, professional, responsive",
    content: (
      <p>
        <Highlight>spiderAI™</Highlight> adopts a warm, approachable tone when
        interacting with users and is capable of
        <Highlight>
          recognizing user emotions and responding with empathy
        </Highlight>
        , creating a pleasant and engaging experience.
      </p>
    ),
  },
  {
    id: 5,
    mainHeading: "Few Shot",
    subHeading: "Examples",
    content: (
      <p>
        <Highlight>spiderAI™</Highlight> leverages few-shot learning to deliver
        precise answers with limited input. By using{" "}
        <Highlight>minimal examples</Highlight>, it quickly adapts to new
        queries, enhancing its ability to handle diverse and dynamic industrial
        scenarios without the need for extensive training data.
      </p>
    ),
  },
  {
    id: 6,
    mainHeading: "Tone",
    subHeading: "Clear, professional, and adaptable",
    content: (
      <p>
        <Highlight>spiderAI™</Highlight> maintains a professional yet accessible
        tone, adapting to the user's technical proficiency. It delivers{" "}
        <Highlight>precise, concise, and clear responses</Highlight>, ensuring
        effective communication with both seasoned engineers and newer
        technicians.
      </p>
    ),
  },
];
