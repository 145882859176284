import React from "react";
import gears from "../assets/gears.jpg";
import GetAccessForm from "../components/GetAccessForm";
import GetAccessForm2 from "../components/getAccess/getAccessForm2";
import accurate from "../assets/Accurate.png";
import StyledInfoComponent from "../components/StyledInfoComponent";
import ihz from "../assets/agiile-and-adaptive.jpg";
import FeaturesSection from "../components/getAccess/FeaturesSection";
import audio from "../assets/Podcast_audio.wav";
import installation from "../assets/installationPic.png";
import KeyValueDrivers from "../components/keyValueDriver";
import Breadcrumb from "../components/BreadCrumb";
import GetDemoHeroSection from "../components/GetDemoHeroSection";
import ScrollResponsiveText from "../components/ScrollResponsiveText";
import { motion } from "framer-motion";
import hifi from "../assets/hifi-meets-intelligence.png";

import {
  TruthOverInsight,
  RoboustReliability,
  AccurateIntelligent,
} from "../components/GetdemoSections";

const FadeInSection = ({ children, delay = 0 }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 40 }}
      whileInView={{
        opacity: 1,
        y: 0,
        transition: { duration: 1, delay, ease: "easeOut" },
      }}
      viewport={{ once: true, margin: "-100px" }}
    >
      {children}
    </motion.div>
  );
};

export default function GetAccess() {
  return (
    <div>
      {/* Header Section Start */}

      <GetDemoHeroSection />
      <Breadcrumb />



      <section className="pt-16 bg-white text-primary">
        <div className="container mx-auto px-[5%] max-w-full">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="mb-2"
          >
            <div className="w-32 h-1 bg-secondary mb-4"></div>
            <h2 className="text-3xl font-semibold leading-tight">
              Without the path of{" "}
              <span className="text-secondary">
                Total Equipment Intelligence
              </span>
              , the goal of monitoring your equipment is either flawed or only
              partially defined, making it unachievable. This means you're never
              operating your machines to their full potential.Total Equipment Intelligence is the way to begin uncovering the truth.
            </h2>
          </motion.div>

          <div className="grid grid-cols-1 mb-8">
            <div className="text-primary">
              <div className="w-full py-3">
                <div className="flex flex-col md:flex-row items-start gap-4">
                  <div className="flex-shrink-0 md:max-w-md">
                    <p className="font-medium text-primary text-xl ">
                      Simply identifying faults, receiving notifications and
                      alerts, and monitoring various parameters is vastly
                      different from truly understanding what's happening within
                      the machine—the truth.
                      <span className="text-secondary font-bold">
                        {" "}
                        The truth will emerge when Hi-Fi data meets
                        intelligence.
                      </span>{" "}
                      To scale this, you need an accurate, reliable, and robust
                      technological system.
                    </p>
                  </div>
                  <div className="w-full md:w-2/3 flex items-center justify-center">
                    <img
                      src={hifi}
                      alt="hifi meets intelligence"
                      className="w-full h-auto object-contain"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="max-w-6xl mx-auto">
        <div className="text-center">
          <h3 className="text-3xl font-bold text-secondary">
            Learn More About iHz™ Industrial IoT System Today!
          </h3>
        </div>
      </div>
      <FadeInSection>
        <div className="bg-white">
          <TruthOverInsight htmlPageUrl="https://d3dviy6vivq6am.cloudfront.net/ps_11_DFFT_animation_500x500.html" />
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="bg-secondary-background pb-28">
          <RoboustReliability htmlPageUrl="https://d3dviy6vivq6am.cloudfront.net/Machine_utilisation_of_a_Conveyor_Belt.html" />
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="bg-white">
          <AccurateIntelligent approachImage={accurate} />
        </div>
      </FadeInSection>

      <div className="bg-secondary-background">
        <KeyValueDrivers />

        <StyledInfoComponent audioSrc={audio} imageSrc={installation} />

        <div className="bg-secondary-background">
          <GetAccessForm2 />
        </div>
      </div>
    </div>
  );
}
