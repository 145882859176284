import React, { useRef, useState, useEffect } from "react";
import Hardware from "./Hardware";
import Tech from "./Tech";
import Intelligence from "./Intelligence";

export default function IhzFeatured() {
  const hardwareRef = useRef(null);
  const techRef = useRef(null);
  const intelligenceRef = useRef(null);
  const containerRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => {
      const containerRect = container.getBoundingClientRect();
      const startTrigger = 200;
      const endTrigger = 80;

      const isScrolledPastTop = containerRect.top <= endTrigger;
      const isScrolledPastBottom = containerRect.bottom <= window.innerHeight;

      if (containerRect.top <= endTrigger) {
        setScrollProgress(1);
      } else if (containerRect.top >= startTrigger) {
        setScrollProgress(0);
      } else {
        const progress =
          (startTrigger - containerRect.top) / (startTrigger - endTrigger);
        setScrollProgress(Math.min(1, Math.max(0, progress)));
      }

      setIsSticky(isScrolledPastTop && !isScrolledPastBottom);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="relative" ref={containerRef}>
      <div className="relative h-16">
        <nav
          className={`
            w-full
            transition-all duration-500
            z-[40]
            ${
              isSticky
                ? "fixed shadow-lg top-16 md:top-20 bg-white/90 backdrop-blur-sm"
                : "relative bg-transparent"
            }
          `}
        >
          <div className="max-w-7xl mx-auto px-2 sm:px-4">
            <div className="relative flex items-center justify-center h-16">
              {/* Lines container - Hidden on mobile */}
              <div className="absolute inset-0 items-center w-full hidden sm:flex">
                {/* Left line */}
                <div
                  className="h-px bg-secondary flex-1 transform origin-right transition-transform duration-500 ease-out"
                  style={{ transform: `scaleX(${1 - scrollProgress})` }}
                />

                {/* Spacer for center content */}
                <div className="px-4 sm:px-8 md:px-16 lg:px-24 invisible">
                  <ul className="flex space-x-2 sm:space-x-4 md:space-x-8">
                    <li>
                      <span className="px-2 sm:px-3 font-bold">Hardware</span>
                    </li>
                    <li>
                      <span className="px-2 sm:px-3 font-bold ">Tech</span>
                    </li>
                    <li>
                      <span className="px-2 sm:px-3 font-bold">
                        Intelligence
                      </span>
                    </li>
                  </ul>
                </div>

                {/* Right line */}
                <div
                  className="h-px bg-secondary flex-1 transform origin-left transition-transform duration-500 ease-out"
                  style={{ transform: `scaleX(${1 - scrollProgress})` }}
                />
              </div>

              {/* Navigation buttons - Now in front of lines */}
              <div className="relative z-10">
                <ul className="flex space-x-2 sm:space-x-4 md:space-x-8 overflow-x-auto scrollbar-hide">
                  <li>
                    <button
                      onClick={() => scrollToSection(hardwareRef)}
                      className="px-2 sm:px-3 text-base sm:text-base text-primary hover:text-secondary font-bold transition-colors whitespace-nowrap bg-white/0"
                    >
                      Hardware
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => scrollToSection(techRef)}
                      className="px-2 sm:px-3 text-base sm:text-base text-primary hover:text-secondary font-bold transition-colors whitespace-nowrap bg-white/0"
                    >
                      Tech
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => scrollToSection(intelligenceRef)}
                      className="px-2 sm:px-3 text-base sm:text-base text-primary hover:text-secondary font-bold transition-colors whitespace-nowrap bg-white/0"
                    >
                      Intelligence
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>

      {/* Content Sections */}
      <div className="w-full mx-auto px-4">
        <div ref={hardwareRef} className="pt-4">
          <Hardware />
        </div>
        <div ref={techRef}>
          <Tech />
        </div>
        <div ref={intelligenceRef}>
          <Intelligence />
        </div>
      </div>
    </div>
  );
}
