import React, { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { X } from "lucide-react";

const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
};

const Modal = ({ isOpen, onClose, tag, title, subTag, children }) => {
  const modalRef = useRef(null);
  useOutsideClick(modalRef, onClose);

  if (!isOpen) return null;

  return createPortal(
    <div className="fixed inset-0 z-[9999] overflow-y-auto">
      <div className="fixed inset-0 bg-black/80 backdrop-blur-sm transition-opacity" />
      <div className="flex min-h-screen items-center justify-center p-4">
        <div
          ref={modalRef}
          className="relative w-full max-w-xl sm:max-w-2xl md:max-w-4xl lg:max-w-6xl mx-auto bg-white rounded-xl shadow-2xl p-4 sm:p-6 overflow-hidden"
        >
          <button
            onClick={onClose}
            className="absolute top-2 right-2 sm:top-4 sm:right-4 p-2 rounded-full hover:bg-gray-100 transition-colors"
          >
            <X className="w-5 h-5 sm:w-6 sm:h-6 text-gray-500" />
          </button>
          <div className="mb-4 sm:mb-6">
            <p className="mt-1 sm:mt-3 text-sm font-bold text-gray-900">
              {title}
            </p>
            <p className="mt-1 sm:mt-2 text-sm sm:text-base md:text-lg text-gray-600">
              {subTag}
            </p>
          </div>
          <div className="mt-4 sm:mt-6">{children}</div>
        </div>
      </div>
    </div>,
    document.body
  );
};

const FullWidthCard = ({ title, description, imageSrc, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.body.style.overflow = "hidden";
      window.addEventListener("keydown", handleEscape);
    }
    return () => {
      document.body.style.overflow = "auto";
      window.removeEventListener("keydown", handleEscape);
    };
  }, [isOpen]);

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="group relative w-full min-h-[300px] aspect-square sm:aspect-[4/3] md:aspect-[3/2] lg:aspect-[2/1] overflow-hidden rounded-2xl"
      >
        {/* Image Container */}
        <div className="absolute inset-0">
          <img
            src={imageSrc}
            alt={title}
            className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
          />
          {/* Bottom gradient overlay */}
          <div className="absolute inset-x-0 bottom-0 h-48 bg-gradient-to-t from-primary/50 to-transparent" />
        </div>

        {/* Content Container */}
        <div className="absolute inset-x-0 bottom-0 p-6 text-left">
          <h3 className="text-xl text-white font-medium mb-2">{title}</h3>
          <p className="text-sm text-gray-100 mb-3">{description}</p>
          <div className="flex items-center text-secondary text-sm">
            Find out more
            <svg
              className="ml-1 w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </div>
        </div>
      </button>

      {/* Modal */}
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={title}
        tag=""
        subTag={description}
      >
        {children}
      </Modal>
    </>
  );
};

export default FullWidthCard;
