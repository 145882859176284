import React, { useEffect, useRef, useState } from "react";
import awsLogo from "../assets/patner-logos/aws.png";
import Electroohms from "../assets/patner-logos/Electroohms.png";
import HIL from "../assets/patner-logos/HIL.png";
import microchip from "../assets/patner-logos/microchip.png";
import nvidea from "../assets/patner-logos/nvidea.png";
import PAtil from "../assets/patner-logos/PAtil.png";
import pluginalliance from "../assets/patner-logos/pluginalliance.png";
import sail from "../assets/patner-logos/sail.png";
import TSI from "../assets/patner-logos/TSl.png";
import tworks from "../assets/patner-logos/tworks.png";

// import "./CompaniesCarousle.css";

const logos = [
  { src: awsLogo, alt: "AWS Logo" },
  { src: Electroohms, alt: "Electroohms Logo" },
  { src: HIL, alt: "HIL Logo" },
  { src: microchip, alt: "Microchip Logo" },
  { src: nvidea, alt: "NVIDIA Logo" },
  { src: PAtil, alt: "PAtil Logo" },
  { src: pluginalliance, alt: "Plugin Alliance Logo" },
  { src: sail, alt: "Sail Logo" },
  { src: TSI, alt: "TSI Logo" },
  { src: tworks, alt: "T-Works Logo" },
];

const LogoScroll = () => {
  const scrollerRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!scrollerRef.current) return;

    const scroller = scrollerRef.current;

    const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
    if (!mediaQuery.matches) {
      scroller.setAttribute("data-animated", "true");
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          scroller.setAttribute("data-animated", "true");
        } else {
          scroller.setAttribute("data-animated", "false");
        }
      });
    });

    observer.observe(scroller);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="relative w-full overflow-hidden bg-primary-background py-4">
      <style>
        {`
          .scroller {
      
          }

          .scroller[data-animated="true"] {
            animation: scroll var(--_animation-duration, 40s) linear infinite;
          }

          .scroller[data-direction="right"] {
            --_animation-direction: reverse;
          }

          .scroller[data-direction="left"] {
            --_animation-direction: normal;
          }

          .scroller[data-speed="fast"] {
            --_animation-duration: 20s;
          }

          .scroller[data-speed="slow"] {
            --_animation-duration: 60s;
          }

          @keyframes scroll {
            to {
              transform: translate(calc(-50% - 3rem));
            }
          }

          .scroller:hover {
            animation-play-state: paused;
          }

          .logo-container {
           
            border-radius: 8px;
      
          
          }

       
        `}
      </style>
      <div
        ref={scrollerRef}
        className="scroller flex w-max gap-12"
        data-speed="normal"
        data-direction="left"
      >
        {/* First set of logos */}
        {logos.map((logo, index) => (
          <div
            key={`logo-${index}`}
            className="logo-container flex h-20 w-36 items-center justify-center p-4"
          >
            <img
              src={logo.src}
              alt={logo.alt}
              className="max-h-14 w-auto object-contain"
            />
          </div>
        ))}
        {/* Duplicate set for seamless scrolling */}
        {logos.map((logo, index) => (
          <div
            key={`logo-duplicate-${index}`}
            className="logo-container flex h-20 w-36 items-center justify-center p-4"
          >
            <img
              src={logo.src}
              alt={logo.alt}
              className="max-h-14 w-auto object-contain"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoScroll;
