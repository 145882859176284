import React, { useState } from "react";
import { Plus, Minus } from "lucide-react";
import deployment from "../../assets/deployment.jpg";
import ApplicationGrid from "../ApplicationGrid";

import Kallakal from "../Projects/Kallakal";
import kallakal from "../../assets/foundery_kalakal_website_display.jpg";
import Bokaro from "../Projects/Bokaro";
import SailBokaro from "../Projects/SailBokaro";

import tata from "../../assets/clients/tataSteel.jpg";
import bokaro from "../../assets/clients/bokaro.jpg";

import mechanical from "../../assets/applications/mechanical_failures.jpg";
import electrical from "../../assets/applications/electrical_fault.jpg";
import sub from "../../assets/applications/sub-system-level-fault.jpg";
import compo from "../../assets/applications/component_level_fault_diagnosis.jpg";

const Robust = () => {
  return (
    <img
      src={deployment}
      alt="deployment"
      className="w-full h-auto object-cover rounded-lg"
      loading="lazy"
    />
  );
};

const cardData1 = [
  {
    title: "Bokaro",
    location: "",
    imageSrc: bokaro,
    children: <Bokaro />,
  },
];

const cardData2 = [
  {
    title: "Sail Bokaro",
    location: "",
    imageSrc: bokaro,
    children: <SailBokaro />,
  },
  {
    title: "Kallakal",
    location: "",
    imageSrc: kallakal,
    children: <Kallakal />,
  },
];

const cardData3 = [
  {
    title: "Blooming Mill",
    location: "",
    imageSrc: tata,
    children: <Bokaro />,
  },
  {
    title: "Kallakal",
    location: "",
    imageSrc: kallakal,
    children: <Kallakal />,
  },
];

const cardData4 = [
  {
    title: "Blooming Mill",
    location: "",
    imageSrc: tata,
    children: <Bokaro />,
  },
];

const FaultTypesAccordon = () => {
  // Changed initial state to match first section key
  const [expandedSection, setExpandedSection] = useState("machineFaults");

  const sections = {
    machineFaults: {
      title: "Mechanical Faults",
      description:
        "Every rotating machine, despite its purpose, faces fundamental mechanical challenges. Through ESA, we've mastered the detection of classic mechanical issues: misalignment of shafts that creates destructive forces, looseness in mountings, unbalance that stresses bearings, and bearing deterioration that can lead to catastrophic failures. What makes our approach unique is detecting these mechanical faults through the motor's electrical signature.",
      content: (
        <ApplicationGrid
          cards={cardData3}
          title="Featured Projects"
          heroImage={mechanical}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    electrical: {
      title: "Electrical Faults",
      description:
        "The motor is the heart of any rotating system, and its electrical health directly impacts performance and reliability. We detect both developing and acute electrical issues - from rotor bar problems and winding degradation to Current unbalance effects and VFD Related issues.",
      content: (
        <ApplicationGrid
          cards={cardData1}
          title="Featured Projects"
          heroImage={electrical}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    subsystemLevel: {
      title: "Subsystem Level-Fault diagnosis",
      description:
        "When a machine fails, looking at individual components often doesn't tell the complete story. Real insights come from understanding how different parts of the system interact - how power flows from motor through transmission elements to the final load, how misalignment in one component creates stress in another, or how thermal conditions affect the entire drive train. Our diagnostics look at these interactions, identifying not just what failed, but why it failed and how it impacts the complete system.",
      content: (
        <ApplicationGrid
          cards={cardData4}
          title="Featured Projects"
          heroImage={sub}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    CompoenntLevel: {
      // Note: there's a typo in "Component" that you might want to fix
      title: "Component Level-Fault diagnosis",
      description:
        "Moving beyond just identifying that something is wrong, component-level diagnosis pinpoints exactly which element is failing and how. Whether it's shaft damage, specific bearing race deterioration, key way wear, or blade looseness, precise identification allows maintenance teams to prepare the exact spares and plan repairs efficiently. This level of detail eliminates guesswork, reduces maintenance time, and ensures the right fix the first time.",
      content: (
        <ApplicationGrid
          cards={cardData2}
          title="Featured Projects"
          heroImage={compo}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Other: {
      title: "Other",
      description: "",
      content: (
        <div className="flex h-full w-full items-center justify-center p-8 sm:p-12">
          <p className="max-w-2xl text-center text-lg sm:text-xl md:text-2xl font-medium leading-relaxed text-primary">
            Yeah! Our applications extend across numerous use cases and
            industries.{" "}
            <span className="relative inline-block">
              <span className="relative z-10">
                Let's connect and explore how iHz™ can address your unique
                monitoring needs.
              </span>
            </span>
          </p>
        </div>
      ),
    },
  };

  const handleSectionClick = (key) => {
    setExpandedSection(expandedSection === key ? null : key);
    // Modified to allow toggling sections closed
  };

  return (
    <div className="max-w-6xl mx-auto p-4 sm:p-6">
      <div className="bg-white/80 backdrop-blur-sm rounded-xl p-4 sm:p-6 lg:p-8 mb-8 shadow-sm border border-blue-100">
        <div className="flex flex-col lg:flex-row gap-6 lg:gap-8">
          {/* Content preview section */}
          <div className="w-full lg:w-2/3 order-2 lg:order-1">
            <div className="rounded-lg p-3 sm:p-4 transition-all duration-300 ease-in-out min-h-[250px] sm:min-h-[300px] lg:min-h-[400px]">
              {expandedSection && sections[expandedSection]?.content}
            </div>
          </div>

          {/* Accordion section */}
          <div className="w-full lg:w-1/3 order-1 lg:order-2">
            {Object.entries(sections).map(([key, section]) => (
              <div
                key={key}
                className="border-b border-blue-100 last:border-b-0"
              >
                <div className="py-3 sm:py-4">
                  <button
                    className={`w-full flex items-center justify-between text-left transition-colors duration-200 ease-in-out ${
                      expandedSection === key
                        ? "text-primary"
                        : "text-gray-700 hover:text-primary"
                    }`}
                    onClick={() => handleSectionClick(key)}
                    aria-expanded={expandedSection === key}
                    aria-controls={`fault-content-${key}`}
                  >
                    <span className="font-semibold text-sm sm:text-base pr-2">
                      {section.title}
                    </span>
                    <span
                      className="flex-shrink-0 transform transition-transform duration-200 ease-in-out"
                      aria-hidden="true"
                    >
                      {expandedSection === key ? (
                        <Minus className="w-4 h-4" />
                      ) : (
                        <Plus className="w-4 h-4" />
                      )}
                    </span>
                  </button>
                  <div
                    id={`fault-content-${key}`}
                    className={`transition-all duration-300 ease-in-out overflow-hidden ${
                      expandedSection === key
                        ? "max-h-72 opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                  >
                    {section.description && (
                      <div className="mt-2 text-sm text-primary pr-4">
                        {section.description}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaultTypesAccordon;
