import React from "react";

import solutioning from "../../assets/solutioning.png";
import SpiderAISearch from "../SpiderAISearch";

export default function Monitor() {
  const features = [
    "Early detection with intelligent notifications",
    "Conversational AI for quick insights",
    "Visual answers tailored to your questions",
  ];

  return (
    <div className="w-full max-w-7xl mx-auto">
      <div className="flex flex-col lg:flex-row bg-white rounded-xl overflow-hidden">
        <div className="lg:w-1/2 p-8 lg:p-12 flex flex-col justify-center">
          <div className="space-y-6">
            <h2 className="text-3xl lg:text-4xl font-bold text-primary">
              spiderAI™ - Asset Intelligence Platform for Machine Health,
              Efficiency, and Reliability
            </h2>

            <ul className="space-y-4">
              {features.map((feature, index) => (
                <li key={index} className="flex items-center space-x-3">
                  <span className="flex-shrink-0 w-5 h-5 bg-secondary rounded-full flex items-center justify-center">
                    <svg
                      className="w-3 h-3 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={3}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </span>
                  <span className="text-primary">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* Right Image Section */}
        <div className="lg:w-1/2">
          <img
            src="https://minto-spiderai-website.s3.us-east-2.amazonaws.com/2024-10-30-spiderai_minto_ai.gif"
            alt="Electrical Signature Analysis"
            className="w-full h-auto object-contain "
          />
        </div>
      </div>
    </div>
  );
}
