import React from "react";
import { PhoneCall, Mail } from "lucide-react";

const ContactUsLogo = () => {
  return (
    <div className="relative w-6 h-6 rounded-full bg-secondary flex justify-center items-center group">
      <PhoneCall
        size={14}
        color="white"
        className="transform group-hover:scale-110 transition-transform duration-300 animate-[wiggle_0.82s_ease-in-out_infinite]"
      />
      <div className="absolute -bottom-0.5 -right-0.5 bg-primary rounded-full w-2.5 h-2.5 flex items-center justify-center animate-pulse">
        <Mail size={6} color="white" className="animate-bounce" />
      </div>
    </div>
  );
};

export default ContactUsLogo;
