import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

let interval;

export const CardStack = ({ items, offset, scaleFactor }) => {
  const CARD_OFFSET = offset || 10;
  const SCALE_FACTOR = scaleFactor || 0.06;
  const [cards, setCards] = useState(items);

  useEffect(() => {
    startFlipping();
    return () => clearInterval(interval);
  }, []);

  const startFlipping = () => {
    interval = setInterval(() => {
      setCards((prevCards) => {
        const newArray = [...prevCards];
        newArray.unshift(newArray.pop());
        return newArray;
      });
    }, 5000);
  };

  return (
    <div className="relative h-60 w-60 md:h-60 md:w-96">
      {cards.map((card, index) => {
        return (
          <motion.div
            key={card.id}
            className="absolute bg-white h-60 w-60 md:h-60 md:w-96 rounded-3xl p-4 md:p-6 shadow-xl border border-neutral-200 flex flex-col overflow-hidden"
            style={{
              transformOrigin: "top center",
            }}
            animate={{
              top: index * -CARD_OFFSET,
              scale: 1 - index * SCALE_FACTOR,
              zIndex: cards.length - index,
            }}
          >
            <div className="mb-2 md:mb-3">
              <h2 className="text-base md:text-lg font-bold text-neutral-800  line-clamp-2">
                {card.mainHeading}
              </h2>
              <h3 className="text-xs md:text-sm font-medium text-neutral-500  line-clamp-2">
                {card.subHeading}
              </h3>
            </div>
            <div className="font-normal text-neutral-700 text-xs md:text-sm line-clamp-8 md:line-clamp-none">
              {card.content}
            </div>
          </motion.div>
        );
      })}
    </div>
  );
};
