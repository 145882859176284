import React from "react";

const FaultTypes = () => {
  const faultCategories = [
    "Mechanical Faults",
    "Electrical Faults",
    "Subsystem Level-Fault diagnosis",
    "Component Level-Fault diagnosis",
  ];

  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-12 md:py-16">
      {/* Title Section */}
      <div className="mb-12">
        <h2 className="text-3xl md:text-4xl font-bold text-[#14387F] mb-4">
          Fault Types
        </h2>
        <div className="w-24 h-1 bg-secondary rounded-full mb-8"></div>

        {/* Description paragraph */}
        <p className="text-primary text-base leading-tight">
          iHz™ systems not only diagnose electrical abnormalities as it is
          apparent but also diagnose mechanical faults at the component level
          and in real-time reveal the condition of the asset at sub-system
          level. This makes iHz™ far better investment for use-cases such as
          preventing unplanned breakdowns, condition monitoring, predictive
          maintenance and condition based maintenance.
        </p>
      </div>

      {/* Simple grid of fault categories */}
      <div className="max-w-3xl">
        <ul className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {faultCategories.map((useCase, index) => (
            <li
              key={index}
              className="text-primary text-base p-2 flex items-start"
            >
              <span className="mr-2">•</span>
              {useCase}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FaultTypes;
