import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import embrace from "../../assets/blog-images/embrace-evolution/image1-evol.webp";
import bokaro from "../../assets/blog-images/bokaro/bokaro.jpg";
import FeaturesofaI from "../../assets/blog-images/features-of-ai/unplanned_dt.jpg";
import Mintorecognisedas from "../../assets/blog-images/minto-recognised-as/PHOTO-2019-11-28-12-45-32-2-1-1024x576-1.jpg";
import ProFoundImpact from "../../assets/blog-images/profound-impact/profound_impact.jpg";
import unveilingThe from "../../assets/blog-images/unveiling-the/unveiling.jpg";

import { Twitter, Linkedin, Mail, Link, Clock } from "lucide-react";

import image1 from "../../assets/blog-images/minto-recognised-as/PHOTO-2019-11-28-12-45-32-2-1-1024x576-1.jpg";

import image2 from "../../assets/blog-images/minto-recognised-as/1f0eaafe-91cd-41a8-8c69-2e0f65573851.jpg";

import image3 from "../../assets/blog-images/minto-recognised-as/4a7614b8-b711-41a1-9efb-8a049f762433.jpg";

import image4 from "../../assets/blog-images/minto-recognised-as/IMG_0029.jpg";
import image5 from "../../assets/blog-images/minto-recognised-as/61c1f37f-012a-413c-916a-e34721302345.jpg";

const MintoRecognisedas = () => {
  const blogPostsData = [
    {
      author: "spiders",
      categories: ["Product"],
      date: "2019-02-21T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title:
        "Features of an AI-Driven IoT Product That Solves the Problem of Unplanned Downtime",
      description:
        "In our previous articles, we've discussed the significant financial losses manufacturing companies and asset owners face due to unplanned downtime and why existing maintenance methods fall short. In this final article of the series, we will explore the features of an AI-driven IoT product that can dramatically reduce downtime and extend the lifespan of machines, providing a compelling investment opportunity for asset owners.",
      sortOrder: 5,
      featuredImage: FeaturesofaI,
      route: "/blog/features-of-ai-driven-iot-product",
    },
    {
      author: "spiders",
      categories: ["Value"],
      date: "2019-09-03T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title: "The Profound Impact of minto.ai™ Beyond Monetary Benefits",
      description:
        "In my journey with minto.ai™, I often pondered whether our product truly makes a difference in the world and brings meaningful change to people's lives. Beyond the financial gains, are we adding significant value to individuals and organizations?",
      sortOrder: 4,
      featuredImage: ProFoundImpact,
      route: "/blog/profound-impact-of-minto-ai",
    },
    {
      author: "spiders",
      categories: ["Awards"],
      date: "2019-06-12T00:00:00.000Z",
      featured: false,
      image: "../../assets/favicon.ico",
      title:
        "minto.ai™ recognized as top 2 innovative startups in the country by CK Birla group",
      description:
        "minto.ai is recognized by Birlasoft as one of the top two startups and runner-up across the country in the grand Finale happened at Birlasoft Xccelerate4startups event in Noida.",
      sortOrder: 3,
      featuredImage: Mintorecognisedas,
      route: "/blog/minto-ai-recognized-as-top-2-innovative-startups",
    },
    {
      author: "spiders",
      categories: ["Customers"],
      date: "2021-11-24T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title:
        "Bokaro Steel Plant(SAIL) partners with minto.ai™ to begin trials of AI-based Predictive Monitoring system",
      description:
        "We at minto.ai™ started the deployment of its Industrial IoT system connected to its platform **spidersense™** in steel plants. With the ongoing and future deployments in Steel Plants, we have entered an important segment where we believe there is a need to add value with our Industrial IoT platform called **spidersense™.**",
      sortOrder: 2,
      featuredImage: bokaro,
      route: "/blog/bokaro-steel-plant-partners-with-minto-ai",
    },
    {
      author: "Raviteja Valluri",
      categories: ["Product"],
      date: "2024-05-18T00:00:00.000Z",
      featured: false,
      image: embrace,
      title:
        "Embracing Evolution From Domesticators to Innovators with spiderAI™",
      description:
        "Humanity's journey from hunter-gatherers to technological pioneers is a testament to our species' ingenuity and resilience. Our relationship with tools are very old that in 2011, researchers",
      sortOrder: 1,
      featuredImage: embrace,
      route: "/blog/embracing-evolution-with-spider-ai",
    },
    {
      author: "spiders",
      categories: ["Problem"],
      date: "2019-02-06T00:00:00.000Z",
      featured: false,
      title: "Unveiling the Inefficiencies in Traditional Maintenance Methods",
      description:
        "As we delve further into the complexities of machine failures and disruptions in manufacturing facilities, it's evident that even the best maintenance strategies—such as RCM, FEMA, RBI, Failure Elimination, and Root Cause Analysis—fall short in",
      sortOrder: 6,
      image: "../../assets/people/ape-ravi.jpeg",
      featuredImage: unveilingThe,
      route:
        "/blog/unveiling-inefficiencies-in-traditional-maintenance-methods",
    },
  ];

  const navigate = useNavigate();
  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    const shuffled = [...blogPostsData].sort(() => 0.5 - Math.random());
    setRelatedArticles(shuffled.slice(0, 3));
  }, []);

  const handleArticleClick = (route) => {
    navigate(route);
  };

  return (
    <div className="pt-20  bg-white">
      <div className="max-w-3xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-primary  mb-6">
          minto.ai™ recognized as top 2 innovative startups in the country by CK
          Birla group
        </h1>

        <div className="flex items-center text-primary mb-6 space-x-4">
          <span>June 12, 2019</span>
        </div>

        <div className="text-primary mb-8">
          Topic: <span className="text-secondary">Awards</span>
        </div>

        <img
          src={image1}
          alt="minto.ai at Birlasoft Xccelerate4startups event"
          className="w-full h-full object-cover mb-6"
        />

        <p className="mb-8 text-primary ">
          minto.ai is recognized by Birlasoft as one of the top two startups and
          runner-up across the country in the grand Finale happened at Birlasoft
          Xccelerate4startups event in Noida.
        </p>

        <p className="mb-8 text-primary ">
          As part of the month-long evaluation process, 40+ start-ups had
          registered for the contest, out of which 28 were invited for the
          Preliminary Round, where each was evaluated by distinguished expert
          panels. Six start-ups were selected to showcase their solutions at a
          fast-track presentation and discussion session during the Grand
          Finale. The start-ups were evaluated based on{" "}
          <strong>
            innovation quotient, business impact, uniqueness, relevance to the
            market, and maturity of their solution / platform / product.
          </strong>
        </p>

        <img
          src={image2}
          alt="Raviteja Valluri presenting in the preliminary round"
          className="w-full h-full object-cover mb-6"
        />

        <p className="mb-6 text-primary ">
          Raviteja Valluri, Co-Founder & CEO, minto.ai presenting in the
          preliminary round
        </p>

        <p className="mb-8 text-primary ">
          <strong>Birlasoft</strong> [BSE: 532400, NSE: BSOFT], part of the USD
          2.4 billion diversified The CK Birla Group hosted the{" "}
          <strong>Grand Finale of the 1st edition</strong> of{" "}
          <strong>#Xccelerate4Startups</strong> to select the top two out of the
          6 startups who reached the final.
        </p>

        <img
          src={image3}
          alt="minto.ai Presentation at the Grand Finale"
          className="w-full h-full object-cover mb-6"
        />

        <p className="mb-6 text-primary ">
          minto.ai Presentation at the Grand Finale, xccelerate4startups
        </p>

        <p className="mb-8 text-primary ">
          Birlasoft, in a press release, mentioned that "As part of the day-long
          event, Birlasoft announced CoRover,{" "}
          <strong>a cognitive chatbot company</strong> as the winner of the
          first edition of its start-up contest - #Xccelerate4Startups. The
          award is aimed at identifying excellence and setting benchmarks among
          start-ups working in digital technologies. Applications were invited
          from start-ups (across India) who have products, solutions or
          platforms that are geared towards solving customers' problems in new
          and unique ways using technologies such as AI/ML, IoT, Blockchain,
          Analytics, Customer Experience, Intelligent Automation, among others.{" "}
          <em>
            Acknowledged for its innovation quotient, minto.ai, providing a{" "}
            <strong>
              Spectrum-based AI platform for Predictive Maintenance
            </strong>
            , was conferred upon as the runner-up of the event.
          </em>
        </p>

        <img
          src={image4}
          alt="minto.ai, Runner-up in the Xccelerate4startups event"
          className="w-full h-full object-cover mb-6"
        />

        <p className="mb-6 text-primary ">
          minto.ai, Runner-up in the Xccelerate4startups event
        </p>

        <p className="mb-8 text-primary ">
          minto.ai is glad to have companies like Birlasoft who are going
          forward with a collaborative mindset and open to leverage agility &
          innovation uniquely available with startups.
        </p>

        <img
          src={image5}
          alt="minto.ai with other startups participated in the Grand Finale"
          className="w-full h-full object-cover mb-6"
        />

        <p className="mb-6 text-primary ">
          minto.ai with other startups participated in the Grand Finale
        </p>

        <p className="mb-8 text-primary ">
          This award gave enormous confidence to the team at{" "}
          <strong>minto.ai</strong> and the motivation to continue to add value
          to the customers. minto.ai is eagerly looking forward to work with the
          CK Birla group.
        </p>
      </div>

      <div className="px-4 sm:px-8 md:px-16 lg:px-24 pb-6 sm:pb-8 md:pb-10">
        <div className="mt-8 sm:mt-12 md:mt-16 px-4 sm:px-8 md:px-16 lg:px-24">
          <h2 className="text-xl sm:text-2xl font-bold text-secondary mb-6 sm:mb-8">
            Related Articles
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
            {relatedArticles.map((article, index) => (
              <div
                key={index}
                className="flex flex-col cursor-pointer"
                onClick={() => handleArticleClick(article.route)}
              >
                <img
                  src={article.featuredImage}
                  alt={article.title}
                  className="w-full h-48 object-cover mb-4"
                />
                <div className="text-xs sm:text-sm font-semibold text-primary mb-2">
                  {article.categories[0]}
                </div>
                <h3 className="text-base sm:text-lg font-bold text-primary mb-2">
                  {article.title}
                </h3>
                <div className="mt-auto">
                  <div className="text-xs sm:text-sm text-primary mt-1 flex">
                    <span>Topic: </span>
                    <div className="text-secondary ml-1">
                      {article.categories.join(", ")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MintoRecognisedas;
