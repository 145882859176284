import React from "react";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "lucide-react";

const ContactUs = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contactus");
  };

  return (
    <div className="flex-shrink-0">
      <button
        onClick={handleClick}
        className="inline-flex items-center px-6 py-3 border border-transparent text-base font-bold text-white bg-secondary hover:bg-secondary-dark transition duration-150 ease-in-out"
      >
        Contact Us
        {/* <ArrowRight className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" /> */}
        <ChevronRight className="text-white" size={24} />
      </button>
    </div>
  );
};

export default ContactUs;
