import React from "react";
import ProductPage from "../components/productPageComponent";

import IhzFeatured from "../components/IhzFeatured";
import UseCases from "../components/ui/Usecases";
import usecaseHeader from "../assets/useCasePageHero.jpg";
import ApplicationsCTA from "../components/ApplicationCTA";

import usecase1 from "../assets/useCase-hotSaw.jpg";
import usecase2 from "../assets/CaseStudy-2.jpg";

import Seamless from "../components/ProductTour/SeamLessCollab";
import Performance from "../components/ProductTour/Performance";

import FaultDiagnosis from "../components/ProductTour/FaultDiagnosis";

import Stepper from "../components/Stepper";
import ProductTour from "../components/ProductTour";

import InstallMcc from "../components/How/InstallMcc";
import NonInvasiveSensors from "../components/How/NonInvasiveSensors";
import HighFid from "../components/How/HighFid";
import RobustConn from "../components/How/RobustConn";
import Monitor from "../components/How/Monitor";

import Breadcrumb from "../components/BreadCrumb";

const iframeStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
};

const DemoIFrame = ({ src }) => {
  return (
    <div className=" w-full h-full">
      <iframe
        className="w-full h-full"
        src={src}
        loading="lazy"
        title="spiderAI™"
        allow="clipboard-write"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        style={iframeStyle}
      />
    </div>
  );
};

// Individual tab components with their specific URLs
export const PlantLevel = () => (
  <DemoIFrame src="https://app.supademo.com/embed/cm2vhnze71yzvesgvpudxeyei?embed_v=2" />
);

export const Health = () => (
  <DemoIFrame src="https://app.supademo.com/embed/cm2ud7bnv1j2sesgv5n77heaz?embed_v=2" />
);

const steps = [
  { name: "Install Inside MCC", id: "1" },
  { name: "Non Invasive Sensors", id: "2" },
  { name: "Robust Connectivity", id: "3" },
  { name: "High-Fidelity Data Capture", id: "4" },
  { name: "Diagnosis", id: "5" },
];

const tabs = [
  { title: "Plant Level Insights", content: <PlantLevel /> },
  { title: "Performance of the Machine", content: <Performance /> },
  { title: "Health of Particular Component ", content: <Health /> },
  { title: "Fault Diagnosis", content: <FaultDiagnosis /> },
  { title: "Seamless Collaboration", content: <Seamless /> },
];

const Section = ({ children, title, bgColor = "bg-white" }) => (
  <section className={`${bgColor} py-10 md:py-5`}>
    <div className="container mx-auto px-4 md:px-6">{children}</div>
  </section>
);

export default function Ihz() {
  const boxes = [
    {
      title: "Understanding Hot Saws Beyond Traditional Monitoring",
      image: usecase1,
      alt: "Worker analyzing machine data",
      url: "/usecase-health-and-load-profile",
    },
    {
      title: "the Vibro Motor Challenge - Monitor Where Vibration Sensors Fail",
      image: usecase2,
      alt: "Machine health monitoring system",
      url: "/comingsoon",
    },
  ];

  return (
    <div>
      <ProductPage />
      <Breadcrumb />

      {/* Section 1- How it works */}

      <div className="container mx-auto py-5 px-4 md:px-6 mt-5">
        <div className="flex flex-col items-center">
          <h2 className="text-3xl md:text-4xl text-secondary font-bold text-center mb-2">
            How it works
          </h2>
          {/* <div className="w-32 h-1 bg-primary"></div> */}
        </div>
      </div>

      <Stepper steps={steps}>
        <InstallMcc />
        <NonInvasiveSensors />
        <RobustConn />
        <HighFid />
        <Monitor />
      </Stepper>

      <div className="container mx-auto mt-10 px-4 md:px-6">
        <div className="flex flex-col items-center">
          <h2 className="text-3xl md:text-4xl text-secondary font-bold text-center mb-2">
            iHz™ in action
          </h2>
          {/* <div className="w-32 h-1 bg-primary"></div> */}
        </div>
      </div>

      {/* Section 2- Product Tour */}
      <Section title="iHz™ in action" bgColor="bg-white">
        <div className="max-w-7xl mx-auto">
          <ProductTour tabs={tabs} />
        </div>
      </Section>

      <div className="container mx-auto px-4 md:px-6">
        <div className="flex flex-col items-center">
          <h2 className="text-3xl md:text-4xl text-secondary font-bold text-center mb-2">
            Capabilities
          </h2>
          {/* <div className="w-32 h-1 bg-primary"></div> */}
        </div>
      </div>

      {/* Section 3- Featured */}
      <IhzFeatured />

      {/* <Testimonials2 /> */}

      {/* Section 4- Applications */}
      {/* <Applications /> */}
      {/* <DiagnosticSystems /> */}
      <ApplicationsCTA headerImage={usecaseHeader} />
      {/* <div className="w-full p-12 bg-primary ">
        <div className="flex flex-col md:flex-row items-start  mx-[5%] justify-between">
          <div className="flex-grow pr-6">
            <h2 className=" text-2xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-bold text-white leading-relaxed mb-8 md:mb-0">
              "High-fidelity insights await in every asset. Discover them with
              iHz™."
            </h2>
          </div>
          <div className="w-full md:w-auto flex items-center justify-center">
            <ContactUs />
          </div>
        </div>
      </div> */}
      <div className="bg-primary-background pb-5">
        <div className="mx-[5%] pt-12 ">
          <h2 className="text-4xl flex justify-start font-bold text-primary mb-2">
            UseCases
          </h2>
          <div className="h-1 bg-secondary w-12"></div>
        </div>
      </div>
      <div className="bg-primary-background pb-10">
        <div className="mx-[5%]">
          <UseCases boxes={boxes} />
        </div>
      </div>
    </div>
  );
}
