import React from "react";

const testimonials = [
    {
        text: "Kudos to the team at Minto Ai for identifying the problem accurately. Their timely detection helped us replace the mandrel assembly, preventing further damage caused by the smaller pulley key and keyway getting crushed.",
        position: "Deputy Divisional Head,",
        group: "TATA Steel Long Products Limited",
    },
];

const Testimonials2 = () => {
    return (
        <div className="bg-secondary-background py-8 px-4 sm:py-12 sm:px-6 lg:px-8 w-full">
            <div className="container mx-auto px-4 md:px-6">
                <h2 className="text-3xl md:text-4xl text-primary font-bold text-center mb-12">
                    Testimonial
                </h2>
            </div>
            <div className="max-w-7xl mx-auto">

                <div className="relative bg-secondary-background p-4 sm:p-8 overflow-hidden">
                    <div className="overflow-hidden">
                        <div className="max-w-3xl mx-auto px-2 sm:px-4">
                            <p className="text-primary mb-4 sm:mb-6 text-base sm:text-xl">
                                {testimonials[0].text}
                            </p>
                            <div className="flex items-center">
                                <div>
                                    <p className="text-xs sm:text-sm text-primary">
                                        {testimonials[0].position}
                                    </p>
                                    <p className="text-base sm:text-lg font-semibold text-primary">
                                        {testimonials[0].group}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonials2;