import React, { useState } from 'react';

const EmailCapture = () => {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (email) {
            // Handle email submission logic here (e.g., API call to send the email)
            setSubmitted(true);
        }
    };

    return (
        <div style={styles.container}>
            {submitted ? (
                <p style={styles.thankYouMessage}>Thank you! The case study will be sent to your email shortly.</p>
            ) : (
                <>
                    <h2 style={styles.heading}>Curious How iHz™ Performs in Tough Environments?</h2>
                    <p style={styles.subheading}>
                        Take a look at our work with a leading steel manufacturer and see how iHz™ tackled extreme
                        conditions to improve reliability and performance.
                    </p>
                    <p style={styles.ctaText}>Enter your email below, and we’ll contact you to discuss the details about this case study.</p>
                    <form onSubmit={handleSubmit} style={styles.form}>
                        <input
                            type="email"
                            placeholder="Your email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={styles.input}
                            required
                        />
                        <button type="submit" style={styles.button}>
                            Send Me the Case Study
                        </button>
                    </form>
                </>
            )}
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '500px',
        margin: '0 auto',
        padding: '20px',
        backgroundColor: 'white',
        textAlign: 'center',

    },
    heading: {
        fontSize: '1.5em',
        color: '#FF5757',
        fontWeight: 'bold',
    },
    subheading: {
        fontSize: '1em',
        color: '#184384',
        margin: '10px 0',
    },
    ctaText: {
        fontSize: '1em',
        color: '#184384',
        margin: '10px 0 20px',
        fontWeight: 'bold',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    input: {
        width: '100%',
        padding: '10px',
        fontSize: '1em',
        margin: '10px 0',
        borderRadius: '4px',
        border: '1px solid #184384',
    },
    button: {
        padding: '10px 20px',
        fontSize: '1em',
        color: 'white',
        backgroundColor: '#184384',
        border: 'none',
        cursor: 'pointer',
        fontWeight: 'bold',
    },
    thankYouMessage: {
        fontSize: '1.2em',
        color: '#FF5757',
        fontWeight: 'bold',
    },
};

export default EmailCapture;
