import React from 'react';

const MissionSection = () => {
    const missionPoints = [
        {
            number: "1.",
            title: "IF YOU'RE SERIOUS",
            description: "in making this world run on time sustainable and efficiently?"
        },
        {
            number: "2.",
            title: "IF YOU BELIEVE",
            description: "that this world needs more accurate and reliable technology systems?"
        },
        {
            number: "3.",
            title: "THEN YOU MUST",
            description: "connect to us to know our work!"
        }
    ];

    return (

        <div className="max-w-7xl mx-auto px-8 py-10">

            <div className="flex flex-col md:flex-row gap-12 md:gap-20">
                {missionPoints.map((point, index) => (
                    <div key={index} className="flex-1">

                        <h2 className="text-lg font-bold mb-3 text-secondary uppercase">
                            {point.number} {point.title}
                        </h2>
                        <p className="text-primary text-lg">
                            {point.description}
                        </p>
                    </div>
                ))}
            </div>


        </div>

    );
};

export default MissionSection;