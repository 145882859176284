// src/utils/seoTester.js
const testSEO = () => {
    const seoData = {
        title: document.title,
        description: document.querySelector('meta[name="description"]')?.content,
        keywords: document.querySelector('meta[name="keywords"]')?.content,
        ogTitle: document.querySelector('meta[property="og:title"]')?.content,
        ogDescription: document.querySelector('meta[property="og:description"]')?.content,
        ogImage: document.querySelector('meta[property="og:image"]')?.content,
        ogType: document.querySelector('meta[property="og:type"]')?.content,
        ogSiteName: document.querySelector('meta[property="og:site_name"]')?.content,
        twitterCard: document.querySelector('meta[name="twitter:card"]')?.content,
        twitterTitle: document.querySelector('meta[name="twitter:title"]')?.content,
        twitterDescription: document.querySelector('meta[name="twitter:description"]')?.content,
        currentURL: window.location.href
    };

    console.group('SEO Test Results for: ' + window.location.pathname);
    console.table(seoData);

    // Validation checks
    const issues = [];

    // Check for missing values
    Object.entries(seoData).forEach(([key, value]) => {
        if (!value) issues.push(`Missing ${key}`);
    });

    // Check title length (recommended: 50-60 characters)
    if (seoData.title && (seoData.title.length < 30 || seoData.title.length > 60)) {
        issues.push(`Title length (${seoData.title.length}) should be between 30-60 characters`);
    }

    // Check description length (recommended: 150-160 characters)
    if (seoData.description && (seoData.description.length < 120 || seoData.description.length > 160)) {
        issues.push(`Description length (${seoData.description.length}) should be between 120-160 characters`);
    }

    // Check title match
    if (seoData.title !== seoData.ogTitle) {
        issues.push('Title mismatch between <title> and og:title');
    }

    // Check description match
    if (seoData.description !== seoData.ogDescription) {
        issues.push('Description mismatch between meta description and og:description');
    }

    if (issues.length > 0) {
        console.warn('⚠️ SEO Issues Found:');
        issues.forEach(issue => console.warn(`- ${issue}`));
    } else {
        console.log('✅ All SEO checks passed!');
    }

    console.groupEnd();
    return seoData;
};

if (typeof window !== 'undefined') {
    window.testSEO = testSEO;
}

export default testSEO;