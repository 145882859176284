import React from "react";
import { PlaceholdersAndVanishInput } from "./PlaceHolderVanishInput";

export default function SpiderAISearch() {
  // content in the textareas
  const placeholders = [
    "How's my machine doinggg",
    "Why did Machine 102 stop at 2PM yesterday?",
    "Did @Gupta Mentioned anything on today's inspection ?",
  ];
  return (
    <div className="p-10 w-full h-full">
      <div className="relative z-10 w-full h-full rounded-lg  max-w-4xl mx-auto bg-transparent ">
        <div className="text-center mb-10 sm:mb-20">
          <h2 className="text-xl sm:text-5xl text-primary inline-flex items-center justify-center pt-20">
            <span className="font-bold">#Ask</span>
            {/* <span className="inline-block w-4 sm:w-8"></span> */}
            <span className="font-bold"> SpiderAI </span>
          </h2>
        </div>
        <div className="w-full">
          <PlaceholdersAndVanishInput placeholders={placeholders} />
        </div>
      </div>
    </div>
  );
}
