import React from "react";

export default function SignalCapture() {
  return (
    <div
      className="w-full h-full p-2 rounded-lg z-30"
      id="profile"
      role="tabpanel"
      aria-labelledby="profile-tab"
    >
      <div className="relative mx-auto bg-gray-800 border-gray-800 rounded-lg border-[6px] md:border-[8px] lg:border-[10px] max-w-3xl h-full max-h-[350px] md:max-h-[400px] lg:max-h-[450px]">
        {/* Bottom decorative elements - scaled down */}
        <div className="w-4 md:w-6 lg:w-8 h-[2px] md:h-[2px] lg:h-[3px] bg-gray-800 absolute -bottom-[8px] md:-bottom-[10px] lg:-bottom-[12px] left-[34px] md:left-[52px] lg:left-[70px] rounded-s-lg"></div>
        <div className="w-6 md:w-8 lg:w-10 h-[2px] md:h-[2px] lg:h-[3px] bg-gray-800 absolute -bottom-[8px] md:-bottom-[10px] lg:-bottom-[12px] left-[64px] md:left-[94px] lg:left-[124px] rounded-s-lg"></div>
        <div className="w-6 md:w-8 lg:w-10 h-[2px] md:h-[2px] lg:h-[3px] bg-gray-800 absolute -bottom-[8px] md:-bottom-[10px] lg:-bottom-[12px] left-[99px] md:left-[138px] lg:left-[177px] rounded-s-lg"></div>

        {/* Top decorative element - scaled down */}
        <div className="w-8 md:w-12 lg:w-16 h-[2px] md:h-[2px] lg:h-[3px] bg-gray-800 absolute -top-[8px] md:-top-[10px] lg:-top-[12px] left-[26px] md:left-[102px] lg:left-[137px] rounded-e-lg"></div>

        {/* Main content container */}
        <div className="rounded-md overflow-hidden bg-white  h-full">
          {/* iframe container with adjusted height */}
          <div className="relative w-full aspect-video  min-h-[250px] max-h-[600px]]">
            <img
              src="https://minto-spiderai-website.s3.us-east-2.amazonaws.com/spectrum_ihz.gif"
              alt="Electrical Signature Analysis"
              className="w-full h-auto object-contain max-h-[60vh]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
