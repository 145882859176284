import React from "react";

const Users = () => {
  const teams = ["Operation", "Maintenance", "Reliability", "Production"];

  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-12 md:py-16">
      <div className="mb-12">
        <h2 className="text-3xl md:text-4xl font-bold text-[#14387F] mb-4">
          Users
        </h2>
        <div className="w-24 h-1 bg-secondary rounded-full mb-8 "></div>

        <p className="text-primary text-base leading-tight mb-8 max-w-3xl mx-auto">
          iHz™'s core strength lies in its advanced diagnostic capabilities for
          rotating equipment. Through precise electrical signature analysis
          combined with spiderAI™, it delivers specific insights that each team
          needs - from real-time condition alerts for operations to detailed
          fault progression data for maintenance planning. This diagnostic
          intelligence helps teams solve their unique challenges: production
          teams plan targets based on equipment capabilities, operations teams
          execute production while maintaining equipment health, maintenance
          teams prevent failures through early intervention, and reliability
          teams optimize long-term performance.
        </p>

        <div className="max-w-3xl">
          <ul className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {teams.map((useCase, index) => (
              <li
                key={index}
                className="text-primary text-base p-2 flex items-start"
              >
                <span className="mr-2">•</span>
                {useCase}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Users;
