import React, { useState } from "react";
import { Plus, Minus } from "lucide-react";

import ApplicationGrid from "../ApplicationGrid";

import SailBilai from "../Projects/SailBilai";
import Kallakal from "../Projects/Kallakal";
import HIL from "../Projects/HIL";

import Bokaro from "../Projects/Bokaro"; //blooming mill
import tata from "../../assets/clients/tataSteel.jpg";

import hotstripmill from "../../assets/clients/bokaro.jpg";
import SailBokaro from "../Projects/SailBokaro";

import hil from "../../assets/clients/HIL.jpg";

import sail from "../../assets/clients/sail.jpg";
import kallakal from "../../assets/foundery_kalakal_website_display.jpg";

// Placeholder component for content
const ContentPlaceholder = ({ title }) => (
  <div className="flex items-center justify-center h-full min-h-[300px] bg-gray-50 rounded-lg border-2 border-dashed border-gray-200">
    <div className="text-center p-6">
      <h3 className="text-lg font-medium text-gray-600">{title}</h3>
      <p className="mt-2 text-sm text-gray-500">
        Use case details and visualization will appear here
      </p>
    </div>
  </div>
);

const UseCaseAccordion = () => {
  const cardData1 = [
    {
      title: "HIL",
      location: "",
      imageSrc: hil,
      children: <HIL />,
    },
  ];

  const cardData2 = [
    {
      title: "Kallakal",
      location: "",
      imageSrc: kallakal,
      children: <Kallakal />,
    },
  ];

  const cardData3 = [
    {
      title: "Blooming Mill",
      location: "",
      imageSrc: tata,
      children: <Bokaro />,
    },
  ];

  const cardData4 = [
    {
      title: "Blooming Mill",
      location: "",
      imageSrc: tata,
      children: <Bokaro />,
    },
    {
      title: "Kallakal",
      location: "",
      imageSrc: kallakal,
      children: <Kallakal />,
    },
  ];

  // Set initial state to the first section key
  const [expandedSection, setExpandedSection] = useState(
    "predictiveMaintenance"
  );

  const sections = {
    predictiveMaintenance: {
      title: "Predictive Maintenance",
      description:
        "The journey from reactive to predictive maintenance is often seen as a technology upgrade, but the real transformation lies in redefining how we understand machine health. Rather than waiting for alarms or scheduled checks, we enable maintenance teams to track developing issues days or even months in advance. This early insight isn't just about preventing failures - it's about optimizing maintenance planning, reducing spare parts inventory, and eliminating emergency repairs.",
      content: (
        <ApplicationGrid
          cards={cardData2}
          title="Featured Projects"
          heroImage=""
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    conditionMonitoring: {
      title: "Condition Monitoring",
      description:
        "Moving beyond just tracking machine parameters, true condition monitoring means understanding the health of your equipment in real-time. Through high-fidelity ESA, we provide deep insights into how machines are actually performing under their operating conditions.",
      content: (
        <ApplicationGrid
          cards={cardData1}
          title="Featured Projects"
          heroImage=""
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    reliability: {
      title: "Reliability Engineering",
      description:
        "Traditional reliability often relies on statistical probabilities and historical data. We transform this approach by providing actual, real-time health data of critical machines and their subsystems. This enables reliability teams to move from probable time-to-failure estimates to data-driven decisions. Understanding exactly how machines are performing, how they're degrading, and what's causing issues helps build more robust maintenance strategies. Over time, this deep insight helps identify systemic issues, optimize maintenance intervals, and improve overall equipment reliability..",
      content: (
        <ApplicationGrid
          cards={cardData4}
          title="Featured Projects"
          heroImage=""
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    rootCauseAnalysis: {
      title: "Root Cause Analysis / FMEA",
      description:
        "When machines fail, understanding the true cause is crucial to prevent recurrence. Traditional analysis often stops at the component level - a bearing failed, a shaft broke. Our high-fidelity data and system-level understanding help uncover the deeper story - why did the bearing fail, what conditions led to the shaft break? By capturing machine behavior through ESA patterns over time, we can trace issues back to their origins, whether it's operational stress, misalignment, or process conditions. This comprehensive understanding makes solutions more effective and lasting.",
      content: (
        <ApplicationGrid
          cards={cardData3}
          title="Featured Projects"
          heroImage=""
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    digitalTransformation: {
      title: "Digital Transformation",
      description:
        "While many see digital transformation as just adding sensors and collecting data, true transformation means converting that data into actionable insights. Our approach focuses on delivering real value - whether it's preventing unplanned downtime, extending equipment life, or optimizing maintenance resources. By combining high-fidelity ESA with intelligent analytics, we help plants move from reactive firefighting to proactive, data-driven decision making. This isn't about digitization for its own sake; it's about using technology to solve real industrial challenges.",
      content: (
        <ApplicationGrid
          cards={cardData1}
          title="Featured Projects"
          heroImage=""
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Other: {
      title: "Other",
      description: "",
      content: (
        <div className="flex h-full w-full items-center justify-center p-8 sm:p-12">
          <p className="max-w-2xl text-center text-lg sm:text-xl md:text-2xl font-medium leading-relaxed text-primary">
            Yeah! Our applications extend across numerous use cases and
            industries.{" "}
            <span className="relative inline-block">
              <span className="relative z-10">
                Let's connect and explore how iHz™ can address your unique
                monitoring needs.
              </span>
            </span>
          </p>
        </div>
      ),
    },
  };

  const handleSectionClick = (key) => {
    setExpandedSection(expandedSection === key ? null : key);
  };

  return (
    <div className="max-w-6xl mx-auto p-4 sm:p-6">
      <div className="bg-white rounded-xl p-4 sm:p-6 lg:p-8 mb-8 shadow-lg border border-blue-100">
        <div className="flex flex-col lg:flex-row gap-6 lg:gap-8">
          {/* Content preview section */}
          <div className="w-full lg:w-2/3 order-2 lg:order-1">
            <div className="rounded-lg transition-all duration-300 ease-in-out">
              {expandedSection ? (
                sections[expandedSection]?.content
              ) : (
                <ContentPlaceholder title="Select a use case to view details" />
              )}
            </div>
          </div>

          {/* Accordion section */}
          <div className="w-full lg:w-1/3 order-1 lg:order-2">
            {Object.entries(sections).map(([key, section]) => (
              <div
                key={key}
                className="border-b border-blue-100 last:border-b-0"
              >
                <div className="py-3 sm:py-4">
                  <button
                    className={`w-full flex items-center justify-between text-left transition-colors duration-200 ease-in-out ${
                      expandedSection === key
                        ? "text-primary"
                        : "text-gray-700 hover:text-primary"
                    }`}
                    onClick={() => handleSectionClick(key)}
                    aria-expanded={expandedSection === key}
                    aria-controls={`content-${key}`}
                  >
                    <span className="font-semibold text-sm sm:text-base pr-2">
                      {section.title}
                    </span>
                    <span
                      className="flex-shrink-0 transform transition-transform duration-200 ease-in-out"
                      aria-hidden="true"
                    >
                      {expandedSection === key ? (
                        <Minus className="w-4 h-4" />
                      ) : (
                        <Plus className="w-4 h-4" />
                      )}
                    </span>
                  </button>
                  <div
                    id={`content-${key}`}
                    className={`transition-all duration-300 ease-in-out overflow-hidden ${
                      expandedSection === key
                        ? "max-h-72 opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                  >
                    {section.description && (
                      <div className="mt-2 text-sm text-primary pr-4">
                        {section.description}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseCaseAccordion;
