import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import embrace from "../../assets/blog-images/embrace-evolution/image1-evol.webp";
import bokaro from "../../assets/blog-images/bokaro/bokaro.jpg";
import FeaturesofaI from "../../assets/blog-images/features-of-ai/unplanned_dt.jpg";
import MintoRecognisedas from "../../assets/blog-images/minto-recognised-as/PHOTO-2019-11-28-12-45-32-2-1-1024x576-1.jpg";
import ProFoundImpact from "../../assets/blog-images/profound-impact/profound_impact.jpg";
import unveilingThe from "../../assets/blog-images/unveiling-the/unveiling.jpg";

import { Twitter, Linkedin, Mail, Link, Clock, Youtube } from "lucide-react";

const Introducing = () => {
  const blogPostsData = [
    {
      author: "spiders",
      categories: ["Product"],
      date: "2019-02-21T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title:
        "Features of an AI-Driven IoT Product That Solves the Problem of Unplanned Downtime",
      description:
        "In our previous articles, we've discussed the significant financial losses manufacturing companies and asset owners face due to unplanned downtime and why existing maintenance methods fall short. In this final article of the series, we will explore the features of an AI-driven IoT product that can dramatically reduce downtime and extend the lifespan of machines, providing a compelling investment opportunity for asset owners.",
      sortOrder: 5,
      featuredImage: FeaturesofaI,
      route: "/blog/features-of-ai-driven-iot-product",
    },
    {
      author: "spiders",
      categories: ["Value"],
      date: "2019-09-03T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title: "The Profound Impact of minto.ai™ Beyond Monetary Benefits",
      description:
        "In my journey with minto.ai™, I often pondered whether our product truly makes a difference in the world and brings meaningful change to people's lives. Beyond the financial gains, are we adding significant value to individuals and organizations?",
      sortOrder: 4,
      featuredImage: ProFoundImpact,
      route: "/blog/profound-impact-of-minto-ai",
    },
    {
      author: "spiders",
      categories: ["Awards"],
      date: "2019-06-12T00:00:00.000Z",
      featured: false,
      image: "../../assets/favicon.ico",
      title:
        "minto.ai™ recognized as top 2 innovative startups in the country by CK Birla group",
      description:
        "minto.ai is recognized by Birlasoft as one of the top two startups and runner-up across the country in the grand Finale happened at Birlasoft Xccelerate4startups event in Noida.",
      sortOrder: 3,
      featuredImage: MintoRecognisedas,
      route: "/blog/minto-ai-recognized-as-top-2-innovative-startups",
    },
    {
      author: "spiders",
      categories: ["Customers"],
      date: "2021-11-24T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title:
        "Bokaro Steel Plant(SAIL) partners with minto.ai™ to begin trials of AI-based Predictive Monitoring system",
      description:
        "We at minto.ai™ started the deployment of its Industrial IoT system connected to its platform **spidersense™** in steel plants. With the ongoing and future deployments in Steel Plants, we have entered an important segment where we believe there is a need to add value with our Industrial IoT platform called **spidersense™.**",
      sortOrder: 2,
      featuredImage: bokaro,
      route: "/blog/bokaro-steel-plant-partners-with-minto-ai",
    },
    {
      author: "Raviteja Valluri",
      categories: ["Product"],
      date: "2024-05-18T00:00:00.000Z",
      featured: false,
      image: embrace,
      title:
        "Embracing Evolution From Domesticators to Innovators with spiderAI™",
      description:
        "Humanity's journey from hunter-gatherers to technological pioneers is a testament to our species' ingenuity and resilience. Our relationship with tools are very old that in 2011, researchers",
      sortOrder: 1,
      featuredImage: embrace,
      route: "/blog/embracing-evolution-with-spider-ai",
    },
    {
      author: "spiders",
      categories: ["Problem"],
      date: "2019-02-06T00:00:00.000Z",
      featured: false,
      title: "Unveiling the Inefficiencies in Traditional Maintenance Methods",
      description:
        "As we delve further into the complexities of machine failures and disruptions in manufacturing facilities, it's evident that even the best maintenance strategies—such as RCM, FEMA, RBI, Failure Elimination, and Root Cause Analysis—fall short in",
      sortOrder: 6,
      image: "../../assets/people/ape-ravi.jpeg",
      featuredImage: unveilingThe,
      route:
        "/blog/unveiling-inefficiencies-in-traditional-maintenance-methods",
    },
  ];

  const navigate = useNavigate();
  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    const shuffled = [...blogPostsData].sort(() => 0.5 - Math.random());
    setRelatedArticles(shuffled.slice(0, 3));
  }, []);

  const handleArticleClick = (route) => {
    navigate(route);
  };

  return (
    <div className="pt-20 bg-white">
      <div className="max-w-3xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-primary  mb-6">
          Introducing Copilot for Vibration analysis with spiderAI™ |
          Communicate & Reason with Machines
        </h1>

        <div className="flex items-center text-primary mb-6 space-x-4">
          <span>February 6, 2019</span>
        </div>

        <div className="text-primary mb-8">
          Topic: <span className="text-secondary">Product Launch</span>
        </div>

        <p className="mb-8 text-primary ">
          Witness the convergence of artificial intelligence and industrial
          machinery. Our Spider AI technology is revolutionizing vibration
          analysis, bringing unprecedented clarity to machine diagnostics.
        </p>

        <h2 className="text-2xl font-bold text-primary  mb-6">
          Introducing spiderAI™: Your Assistive Copilot
        </h2>

        <p className="mb-8 text-primary ">
          We are thrilled to announce the beta release of{" "}
          <strong>spiderAI™</strong>, an assistive copilot designed to
          revolutionize how we interact with industrial machines and equipment.{" "}
          <strong>spiderAI™</strong> enables users to communicate and reason
          with machines in simple language, dramatically improving
          decision-making accuracy, productivity, and efficiency. Initial tests
          have shown that a maintenance engineer by asking questions in simple
          language can generate a world class expert level vibration report in
          10 minutes.
        </p>

        <div className="mb-8">
          <video
            className="w-full max-h-full rounded-lg border-gray-300"
            controls
            preload="auto"
          >
            <source
              src="https://s3.us-east-2.amazonaws.com/minto.ai-resources/spiderAI/short10+(1).mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="flex justify-center mb-8">
          <a
            href="https://www.youtube.com/watch?v=jeZeS1-UnoY"
            className="inline-flex items-center font-medium text-blue-600 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            View on YouTube <Youtube className="w-4 h-4 ml-2" />
          </a>
        </div>
      </div>

      <div className="px-4 sm:px-8 md:px-16 lg:px-24 pb-6 sm:pb-8 md:pb-10">
        <div className="mt-8 sm:mt-12 md:mt-16 px-4 sm:px-8 md:px-16 lg:px-24">
          <h2 className="text-xl sm:text-2xl font-bold text-secondary mb-6 sm:mb-8">
            Related Articles
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
            {relatedArticles.map((article, index) => (
              <div
                key={index}
                className="flex flex-col cursor-pointer"
                onClick={() => handleArticleClick(article.route)}
              >
                <img
                  src={article.featuredImage}
                  alt={article.title}
                  className="w-full h-48 object-cover mb-4"
                />
                <div className="text-xs sm:text-sm font-semibold text-primary mb-2">
                  {article.categories[0]}
                </div>
                <h3 className="text-base sm:text-lg font-bold text-primary mb-2">
                  {article.title}
                </h3>
                <div className="mt-auto">
                  <div className="text-xs sm:text-sm text-primary mt-1 flex">
                    <span>Topic: </span>
                    <div className="text-secondary ml-1">
                      {article.categories.join(", ")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introducing;
