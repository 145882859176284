import React from "react";
import {
  Factory,
  Wrench,
  AlertTriangle,
  Lightbulb,
  MessageSquare,
  ExternalLink,
  Mail,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const HIL = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contactus");
  };

  const sections = [
    {
      title: "Plant Summary",
      icon: <Factory className="w-5 h-5 text-primary" />,
      content: `This is a PVC compounding section within HIL Limited - a CK Birla Group flagship company and world's largest manufacturer of fiber cement roofing. The plant's heart consists of critical hot and cold mixers working in tandem to create precise PVC compounds. The hot mixer, operating at high circumferential speeds of 20-50 m/s, brings the mixture to 130-140°C through intensive fluidization, while the water-cooled cold mixer brings the temperature down to 40°C. These mixers, driven by VFD-controlled motors, are subjected to extreme thermal and torsional stresses as they blend PVC with various additives. Any fault in these mixing operations - from blade wear to inconsistent temperature cycles - doesn't just affect the batch quality but can cascade into catastrophic failures downstream. When poor quality compounds reach the extruders, they can cause seizures leading to 2-3 days of downtime, making the loss twofold: valuable raw material waste and significant production stoppage.`,
    },
    {
      title: "Requirement",
      icon: <Wrench className="w-5 h-5 text-primary" />,
      content: `Provide comprehensive health and operational intelligence for PVC mixing machines to ensure batch quality and prevent catastrophic downstream failures.`,
    },
    {
      title: "Execution Challenges",
      icon: <AlertTriangle className="w-5 h-5 text-primary" />,
      content: `In PVC compounding, the margin for error is razor-thin. The mixing blades, constantly battling high temperatures and torsional forces, can develop subtle wear patterns that traditional monitoring systems struggle to detect. The VFD-driven operation adds another layer of complexity, making conventional vibration monitoring nearly ineffective. Temperature cycles, critical for proper binding, must be precisely maintained - yet the high-stress environment makes sensor placement and reliability a constant challenge. The real stakes become clear at the extruder - where a single compromised batch can trigger days of downtime and substantial material waste.`,
    },
    {
      title: "Solution",
      icon: <Lightbulb className="w-5 h-5 text-primary" />,
      content: `Understanding these critical interdependencies, we implemented an integrated health monitoring system combining both electrical and thermal signatures. Through high-fidelity data capture, we can detect subtle changes in blade performance and mixing efficiency before they impact batch quality. This early warning system helps maintain precise control over the mixing process, protecting not just the mixers but the entire downstream operation, especially the critical extruders. By preventing even a single extruder seizure, the system delivers significant value in both material savings and production continuity.`,
    },
  ];

  return (
    <div className="bg-gray-50 min-h-screen p-6">
      {/* Main Content */}
      <div>
        {sections.map((section, index) => (
          <div
            key={section.title}
            className="mb-8 last:mb-0 hover:bg-gray-50 rounded-lg transition-colors duration-200 p-4"
          >
            <div className="flex items-center space-x-3 mb-4">
              <div className="bg-blue-50 p-3 rounded-lg">{section.icon}</div>
              <h2 className="text-xl font-semibold text-gray-800">
                {section.title}
              </h2>
            </div>
            <div className="pl-14">
              <p className="text-gray-600 leading-relaxed">{section.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HIL;
