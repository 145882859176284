import React from "react";
import { Timeline } from "../components/ui/timeline";
import ParallaxScroll from "../components/ParallaxScroll";
import TeamMembers from "../components/Team";
import CareersComponent from "../components/CareersComponent";
import timelineData from "../data/timelineData";
import suryaImage from "../assets/pictures/surya_edited.jpg";
import career4 from "../assets/HeroImgAboutus.jpg";
import career1 from "../assets/career_1.jpeg";
import ihz from "../assets/onsiteInstallation.jpg";
import ihz_hero from "../assets/iHz_hero.png";
import bhargav from "../assets/Individual/Bhargav.jpeg";
import solution from "../assets/solutioning.png";
import Breadcrumb from "../components/BreadCrumb";
import MissionStatement from "../components/aboutus/MissionStatement";
import SEO from "../components/SEO";
import { seoConfig } from "../config/seo";
import {
  ProductSection,
  PeopleSection,
  ApproachSection,
} from "../components/aboutus/Sections";
import person from "../assets/Shadow.jpeg";

import MissionSection from "../components/aboutus/MissionSection";

import { motion } from "framer-motion";

const socialLinks = [
  {
    href: "https://www.linkedin.com/in/suryapenmetsa/",
    color: "#39569c",
    iconPath:
      "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm0 3c1.11 0 2 .89 2 2s-.89 2-2 2-2-.89-2-2 .89-2 2-2zm0 14c-2.5 0-4.71-1.28-6-3.22 0-2 4-3.08 6-3.08 2.01 0 6 1.07 6 3.08C16.71 17.72 14.5 18 12 18z",
  },
];

const FadeInSection = ({ children, delay = 0 }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 40 }}
      whileInView={{
        opacity: 1,
        y: 0,
        transition: { duration: 1, delay, ease: "easeOut" },
      }}
      viewport={{ once: true, margin: "-100px" }}
    >
      {children}
    </motion.div>
  );
};

export default function AboutUs() {
  return (

    <>
      <SEO
        {...seoConfig.about}
        image="https://s3.us-east-2.amazonaws.com/minto.ai-resources/website/spiderAI_favicon.png"
        url="https://minto.ai/about"
      />


      <div className="bg-white">
        <ParallaxScroll />

        <Breadcrumb />

        <FadeInSection>
          <MissionStatement />
        </FadeInSection>

        <div className="">
          <div className="max-w-7xl mx-auto px-4">
            <h2 className="text-3xl font-bold text-start text-secondary font-heading">
              What we do!
            </h2>
          </div>
        </div>

        <FadeInSection>
          <div className="bg-white">
            <ProductSection productImage={ihz} />
          </div>
        </FadeInSection>

        <FadeInSection>
          <div className="bg-secondary-background">
            <PeopleSection peopleImage={person} />
          </div>
        </FadeInSection>

        <FadeInSection>
          <div className="bg-white">
            <ApproachSection approachImage={solution} />
          </div>
        </FadeInSection>

        <div className="bg-white">
          <div className="max-w-7xl mx-auto px-4 ">
            <h2 className="text-3xl font-bold text-start text-secondary font-heading">
              We go where we are needed the most!
            </h2>
          </div>
        </div>

        <FadeInSection>
          <MissionSection missionImage={bhargav} />
        </FadeInSection>

        <div className="bg-white">
          <div className="max-w-7xl mx-auto px-4">
            <h2 className="text-3xl font-bold text-start text-secondary font-heading">
              Our Tribe!
            </h2>
          </div>
        </div>

        <TeamMembers />

        <section className="bg-white">
          <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
            <h2 className="mb-12 text-4xl font-bold text-primary">Investors</h2>
            <div className="flex justify-center">
              <div className="text-center">
                <img
                  src={suryaImage}
                  alt="Surya Penmetsa"
                  className="mx-auto mb-4 w-36 h-36 rounded-full grayscale"
                />
                <h3 className="mb-2 text-2xl font-bold text-primary">
                  Surya Penmetsa
                </h3>
                <p className="text-red-400">Co-founder and Investor</p>
                <div className="mt-4">
                  <a href="#" className="text-primary">
                    <svg
                      className="w-6 h-6 mx-auto"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm0 3c1.11 0 2 .89 2 2s-.89 2-2 2-2-.89-2-2 .89-2 2-2zm0 14c-2.5 0-4.71-1.28-6-3.22 0-2 4-3.08 6-3.08 2.01 0 6 1.07 6 3.08C16.71 17.72 14.5 18 12 18z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="bg-white">
          <div className="max-w-7xl mx-auto px-4">
            <h2 className="text-3xl font-bold text-start text-secondary font-heading">
              Our Story
            </h2>
          </div>
        </div>

        <Timeline data={timelineData} />

        <CareersComponent />
      </div>

    </>
  );
}
