import React from "react";
// import usecasepic from "../assets/usecase1.jpg";
import { ArrowRight } from "lucide-react";
import uday from "../assets/pictures/uday.jpg";
import bhargav from "../assets/pictures/bhargav.jpg";
import bokaro from "../assets/clients/bokaro.jpg";
import demo from "../assets/mahcine_parts.jpg";
import Breadcrumb from "../components/BreadCrumb";

export default function UseCasePage() {
  return (
    <div>
      <div className="pt-20">
        <div className="bg-primary flex flex-col md:flex-row items-start md:items-center">
          <div className="flex-grow flex justify-center p-8">
            <h1 className="text-4xl font-bold text-white max-w-3xl">
              Monitoring rolling mill motors to predict and prevent breakdowns
              in steel production.
            </h1>
          </div>

          <div className="w-full md:w-1/3 mt-8 md:mt-0 md:ml-8 h-64 md:h-auto">
            <img
              src="../api"
              alt="Industrial machine"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>

      <Breadcrumb />

      <div className="max-w-full mx-[10%]  p-6">
        <h1 className="text-3xl font-bold text-primary mb-6 w-full lg:w-2/3">
          Our nuclear and industrial expertise at the service of the space
          industry
        </h1>

        <div className="flex flex-col lg:flex-row gap-8">
          <div className="lg:w-2/3">
            <div className="sticky top-6">
              <h2 className="text-2xl font-semibold text-primary mb-4">
                A new age of space travel
              </h2>

              <p className="mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>

              <p className="mb-4">
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>

              <p className="mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>

              <div className="w-full h-48 bg-orange-200 mt-6 mb-4"></div>

              <p className="mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>

              <h2 className="text-2xl font-semibold text-blue-800 mb-4">
                A new age of space travel bla bla bla bla bla bla
              </h2>

              <p className="mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>

          <div className="lg:w-1/3  ">
            <div className="sticky top-6">
              <h3 className="text-xl font-semibold mb-4">News</h3>
              <div className="bg-primary-background p-4 rounded-lg">
                <img
                  src={bokaro}
                  alt="Space"
                  className="w-full h-40 object-cover mb-4 rounded"
                />
                <h4 className="text-lg font-semibold mb-2">
                  Framatome and Qosmosys partner to shape the future of space
                  exploration together
                </h4>
                <button className="bg-blue-600 text-white px-4 py-2 rounded flex items-center">
                  Find out more
                  <ArrowRight className="ml-2" size={16} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-primary-background py-16">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center gap-8">
            <div className="md:w-1/3">
              <img
                src={uday}
                alt="Grégoire Lambert"
                className="rounded-lg shadow-lg w-full h-full"
              />
            </div>
            <div className="md:w-2/3 ">
              <div className="relative">
                <div className="absolute -left-4 -top-4 text-primary text-6xl">
                  "
                </div>
                <p className="text-2xl text-primary mb-4 pl-8">
                  With the creation of Framatome Space, Framatome stands ready
                  to play a decisive role in the future of space exploration. We
                  firmly believe that nuclear is a game changer to provide the
                  amount of energy needed by any development.
                </p>
                <div className="absolute -right-4 bottom-0 text-primary text-6xl">
                  "
                </div>
              </div>
              <p className="text-sm text-primary font-semibold mt-4 uppercase tracking-wider">
                Grégoire Lambert, Vice President, Strategy at Framatome and
                Framatome Space
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mx-[10%] p-6">
        <h1 className="text-3xl font-bold text-primary mb-6 w-full lg:w-2/3">
          Our nuclear and industrial expertise at the service of the space
          industry
        </h1>

        <div className="flex flex-col lg:flex-row gap-8">
          <div className="lg:w-2/3">
            <div className="sticky top-6">
              <h2 className="text-2xl font-semibold text-primary mb-4">
                A new age of space travel
              </h2>

              <p className="mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>

              <p className="mb-4">
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>

              <p className="mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>

              <div className="w-full h-48 bg-orange-200 mt-6 mb-4"></div>

              <p className="mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>

              <h2 className="text-2xl font-semibold text-blue-800 mb-4">
                A new age of space travel bla bla bla bla bla bla
              </h2>

              <p className="mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row bg-primary-background px-[5%] py-[5%] ">
        <div className="md:w-1/2 pr-8">
          <h2 className="text-3xl font-bold text-primary mb-4">
            Did you know?
          </h2>
          <div className="h-1 w-16 bg-secondary mb-6"></div>
          <ul className="space-y-4 text-primary">
            <li>
              Framatome supplies Hafnium for the hardened alloys of spacecraft
            </li>
            <li>Framatome manufactures domes for the tanks of launchers</li>
            <li>1 lunar night = 14 Earth days!</li>
            <li>
              Nuclear thermal propulsion is twice as fast as a chemical rocket
            </li>
            <li>
              Temperature on the moon: up to 130°C during lunar daytime and
              -130°C at night
            </li>
          </ul>
        </div>
        <div className="md:w-1/2 mt-8 md:mt-0">
          <img
            src={demo}
            alt="Earth viewed from the moon"
            className="w-full h-auto object-cover rounded-lg"
          />
        </div>
      </div>
    </div>
  );
}
