import React from "react";
import { Settings, Gauge, LineChart } from "lucide-react";

const KeyValueDrivers = () => {
  const features = [
    {
      icon: Settings,
      title: "Maximise Asset Performance",
      description: [
        "Prevent faults",
        "Reduce maintenance cycles",
        "Extend equipment cycles",
        "Minimise downtime",
      ],
    },

    {
      icon: LineChart,
      title: "Massive improvement in efficiency",
      description: [
        "Identify efficiency bottlenecks",
        "Optimise operations",
        "Reduce energy waste",
      ],
    },
    {
      icon: Gauge,
      title: "World Class Reliability",
      description: [
        "Get high fidelity data from machines/equipments",
        "Find truth not just insight",
        "Know How exactly your machine/equipment is doing in realtime",
      ],
    }
  ];

  return (
    <section className="py-12 px-4 bg-secondary-background">
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-12">
          <h3 className="text-3xl font-bold mb-4 text-secondary">
            Key Value Drivers
          </h3>
          <p className="text-primary max-w-2xl mx-auto">
            Enhance your operational excellence with our comprehensive solutions
          </p>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-start md:items-start relative">
          {features.map((feature, index) => (
            <div
              key={index}
              className="flex flex-col items-center mb-8 md:mb-0 w-full md:w-1/3 relative px-4"
            >
              <div className="w-20 h-20 bg-primary-background rounded-full flex items-center justify-center mb-4 relative z-10">
                <feature.icon className="w-10 h-10 text-primary" />
              </div>
              <h4 className="text-xl font-semibold mb-4 text-[#ff5757] text-center">
                {feature.title}
              </h4>
              <div className="flex justify-center w-full">
                {/* Original */}
                <ul className="space-y-3">
                  {feature.description.map((item, itemIndex) => (
                    <li key={itemIndex} className="text-primary text-sm">
                      {item}{""}
                      {itemIndex === feature.description.length - 1 ? "." : ","}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
          <div
            className="hidden md:block absolute top-10 left-1/6 right-1/6 h-0.5 bg-white"
            aria-hidden="true"
          />
        </div>
      </div>
    </section>
  );
};

export default KeyValueDrivers;
