import React from "react";
import { Layout, HelpCircle } from "lucide-react";

const HybridUILogo = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          width: "100px",
          height: "100px",
          borderRadius: "50%",
          backgroundColor: "#184384",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div>
          <Layout
            size={48}
            color="white"
            style={{
              animation: "pulse 2s infinite",
            }}
          />
        </div>

        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            padding: "5px",
            borderRadius: "50%",
            backgroundColor: "#ff5757",
          }}
        >
          <HelpCircle
            size={24}
            color="white"
            style={{
              animation: "bounce 1s infinite",
            }}
          />
        </div>
      </div>
      <style jsx>{`
        @keyframes pulse {
          0%,
          100% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.1);
          }
        }
        @keyframes bounce {
          0%,
          100% {
            transform: translateY(-10%);
          }
          50% {
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

export default HybridUILogo;
