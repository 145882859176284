import React, { useState, useEffect } from "react";

const MapCard = () => {
  const [seconds, setSeconds] = useState(0);
  const [isBlinking, setIsBlinking] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((s) => s + 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const blinker = setInterval(() => {
      setIsBlinking((b) => !b);
    }, 800);
    return () => clearInterval(blinker);
  }, []);

  const formatTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const remainingSeconds = secs % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="w-full max-w-md bg-white shadow-lg rounded-lg overflow-hidden">
      {/* Header Section */}
      <div className="bg-[#4a7198] text-white p-4">
        <h2 className="text-xl font-bold">R112</h2>
        <p className="text-sm opacity-90 truncate">
          200 kW, 1485 rpm, 338 Amps, 4 ...
        </p>
      </div>

      {/* Content Container */}
      <div className="p-4 space-y-6">
        {/* Current Status Card */}
        <div className="bg-white shadow-sm rounded-md p-4">
          <h3 className="text-gray-500 text-sm mb-2">Current Status</h3>
          <div className="flex items-center gap-2">
            <div
              className={`w-2 h-2 rounded-full bg-green-500 ${
                isBlinking ? "opacity-100" : "opacity-40"
              }`}
              style={{ transition: "opacity 0.3s ease-in-out" }}
            />
            <span className="text-blue-500 font-medium">Running</span>
          </div>
        </div>

        {/* Time Status Grid */}
        <div className="grid grid-cols-3 gap-4">
          {/* Idle */}
          <div className="bg-white shadow-sm rounded-md p-3 flex flex-col items-center">
            <span className="text-blue-500 font-medium">00:00</span>
            <span className="text-gray-500 text-sm">Idle</span>
          </div>

          {/* Running */}
          <div className="bg-white shadow-sm rounded-md p-3 flex flex-col items-center">
            <span className="text-green-600 font-medium">
              {formatTime(seconds)}
            </span>
            <span className="text-gray-500 text-sm">Running</span>
          </div>

          {/* Stopped */}
          <div className="bg-white shadow-sm rounded-md p-3 flex flex-col items-center">
            <span className="text-red-500 font-medium">02:05</span>
            <span className="text-gray-500 text-sm">Stopped</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapCard;
