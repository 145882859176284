import React, { useEffect, useRef, useState } from "react";
import { useScroll, useTransform, motion } from "framer-motion";

export const Timeline = ({ data }) => {
  const ref = useRef(null);
  const containerRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setHeight(rect.height);
    }
  }, [ref]);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start 10%", "end 50%"],
  });

  const heightTransform = useTransform(scrollYProgress, [0, 1], [0, height]);
  const opacityTransform = useTransform(scrollYProgress, [0, 0.1], [0, 1]);

  return (
    <div className="w-full bg-white font-sans md:px-10" ref={containerRef}>
      <div className="max-w-7xl mx-auto py-20 px-4 md:px-8 lg:px-10">
        <h2 className="text-4xl font-bold md:text-4xl mb-4 text-primary">
          Finding truth is essential to manage entropy and keep the world
          running smoothly. The quest for finding the truth in the machines that
          runs our world : The minto.ai Story
        </h2>
        <p className="text-primary text-sm md:text-base">
          From accurate MCSA Innovation to reliable IoT systems and on to the
          future with AI-Powered Co-Intelligence
        </p>
      </div>
      <div ref={ref} className="relative max-w-7xl mx-auto pb-20">
        {data.map((item, index) => (
          <div
            key={index}
            className="flex justify-start pt-10 md:pt-40 md:gap-10"
          >
            <div className="sticky flex flex-col md:flex-row z-40 items-center top-40 self-start max-w-xs lg:max-w-sm md:w-full">
              <div className="h-10 absolute left-3 md:left-3 w-10 rounded-full bg-white flex items-center justify-center">
                <div className="h-4 w-4 rounded-full bg-secondary border border-neutral-300 p-2" />
              </div>
              <h3 className="hidden md:block text-xl md:pl-20 md:text-3xl font-bold text-primary">
                {item.title}
              </h3>
            </div>
            <div className="relative pl-20 pr-4 md:pl-4 w-full">
              <h3 className="md:hidden block text-2xl mb-4 text-left font-bold text-primary">
                {item.title}
              </h3>
              <div className="text-primary text-xs md:text-sm font-normal mb-8">
                {item.content}
              </div>
              {item.images && item.images.length > 0 && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  {item.images.map((image, imgIndex) => (
                    <div key={imgIndex} className="relative">
                      <img
                        src={image.src}
                        alt={
                          image.alt || `Timeline event image ${imgIndex + 1}`
                        }
                        className="rounded-lg object-cover h-60 w-full shadow-lg"
                      />
                      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent pt-8 pb-2 px-3 rounded-b-lg">
                        <p className="text-white text-xs">
                          {image.alt || `Timeline event image ${imgIndex + 1}`}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {item.link && (
                <a
                  href={item.link}
                  className="mt-4 text-secondary font-semibold text-sm hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More →
                </a>
              )}
            </div>
          </div>
        ))}
        <div
          style={{
            height: height + "px",
          }}
          className="absolute md:left-8 left-8 top-0 overflow-hidden w-[2px] bg-[linear-gradient(to_bottom,var(--tw-gradient-stops))] from-transparent from-[0%] via-neutral-200 to-transparent to-[99%] [mask-image:linear-gradient(to_bottom,transparent_0%,black_10%,black_90%,transparent_100%)]"
        >
          <motion.div
            style={{
              height: heightTransform,
              opacity: opacityTransform,
            }}
            className="absolute inset-x-0 top-0 w-[2px] bg-gradient-to-t from-primary via-secondary to-transparent from-[0%] via-[10%] rounded-full"
          />
        </div>
      </div>
    </div>
  );
};

export default Timeline;
