import React from "react";

const DriveTrainComplexity = () => {
  const components = [
    "Eccentric Mass Unbalance",
    "Reciprocating Systems",
    "Fluid Coupling",
    "Cardan Shafts",
    "V-belt",
    "Gearbox",
  ];

  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-12 md:py-16">
      {/* Title Section */}
      <div className="mb-12">
        <h2 className="text-3xl md:text-4xl font-bold text-[#14387F] mb-4">
          Drive Train Complexity
        </h2>
        <div className="w-24 h-1 bg-secondary rounded-full mb-8"></div>

        {/* Description paragraphs with improved typography and spacing */}
        <div className="space-y-6 text-primary text-base leading-tight">
          <p>
            iHz™ diagnostic systems are designed with an approach that goes
            beyond merely monitoring a machine for specific faults. Instead,
            iHz™'s fundamental approach captures the complexity of the drive
            train: individual mechanics, their interactions, and impact on
            components. This is because faults are not meant to be diagnosed,
            but prevented altogether in the first place. This heavily depends on
            understanding the machine's drive train. We've observed that bearing
            fault characteristics in a <em>vibro-motor</em> differ completely
            from those in a pump.
          </p>
          <p>
            In some machines, like <em>hot-saws</em>, the entire system operates
            with a combination of motor, belt, long cardan shaft, and a rotating
            cutting saw attached to cut blooms at 1000°C. The whole machine
            slides on a platform. Multiple reaction forces (Ra) affect the
            components in all possible degrees of freedom for which they're
            designed. Sudden shaft breakage, random looseness across parts,
            deterioration of saw functionality, and bearing housing damage
            manifests as a bearing fault are constant problems. In these
            contexts, the mere ability to diagnose a fault is insufficient.
          </p>
          <p>
            Invest in iHz™ systems that understands the drive train mechanism
            and provides you the truth to prevent failure happening again.
          </p>
        </div>
      </div>

      {/* Simple grid of components */}
      <div className="max-w-3xl">
        <ul className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {components.map((useCase, index) => (
            <li
              key={index}
              className="text-primary text-base p-2 flex items-start"
            >
              <span className="mr-2">•</span>
              {useCase}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DriveTrainComplexity;
