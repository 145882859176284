import React, { useState } from "react";

const Stepper = ({ steps, children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [previousStep, setPreviousStep] = useState(0);
  const delta = currentStep - previousStep;

  const next = () => {
    if (currentStep < steps.length - 1) {
      setPreviousStep(currentStep);
      setCurrentStep((step) => step + 1);
    }
  };

  const prev = () => {
    if (currentStep > 0) {
      setPreviousStep(currentStep);
      setCurrentStep((step) => step - 1);
    }
  };

  const handleStepClick = (index) => {
    setPreviousStep(currentStep);
    setCurrentStep(index);
  };

  const currentChild = React.Children.toArray(children)[currentStep];

  return (
    <div className="w-full p-2 md:p-4">
      {/* Main Container with Navigation and Steps */}
      <div className="relative flex items-center justify-center w-full mb-4 md:mb-8">
        {/* Previous Step Button - Desktop Only */}
        <button
          type="button"
          onClick={prev}
          disabled={currentStep === 0}
          className="absolute left-0 z-20 hidden md:block rounded bg-white p-1 md:p-2 text-primary shadow-lg ring-1 ring-inset ring-primary hover:bg-secondary disabled:cursor-not-allowed disabled:opacity-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-4 w-4 md:h-5 md:w-5 text-primary"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        </button>

        {/* Scrollable Steps Container */}
        <div className="relative flex-1 overflow-hidden mx-0 md:mx-16 w-full">
          <nav aria-label="Progress" className="overflow-x-auto scrollbar-hide">
            <ol
              role="list"
              className="flex w-full items-center justify-start md:justify-center min-w-max px-4"
            >
              {steps.map((step, index) => (
                <React.Fragment key={step.name}>
                  <li
                    className="flex-shrink-0 min-w-[100px] md:min-w-[150px] cursor-pointer"
                    onClick={() => handleStepClick(index)}
                  >
                    {currentStep > index ? (
                      <div className="group flex flex-col border-t-4 border-primary pt-2 md:pt-4 pb-1 md:pb-2">
                        <span className="text-xs md:text-sm font-bold text-primary truncate text-center">
                          {step.id}
                        </span>
                        <span className="text-xs md:text-sm text-primary  font-bold text-center">
                          {step.name}
                        </span>
                      </div>
                    ) : currentStep === index ? (
                      <div
                        className="flex flex-col border-t-4 border-primary pt-2 md:pt-4 pb-1 md:pb-2"
                        aria-current="step"
                      >
                        <span className="text-xs md:text-sm font-bold text-primary truncate text-center">
                          {step.id}
                        </span>
                        <span className="text-xs md:text-sm text-primary font-bold text-center">
                          {step.name}
                        </span>
                      </div>
                    ) : (
                      <div className="group flex flex-col border-t-4 border-gray-200 pt-2 md:pt-4 pb-1 md:pb-2">
                        <span className="text-xs md:text-sm font-bold text-primary  truncate text-center">
                          {step.id}
                        </span>
                        <span className="text-xs md:text-sm font-bold text-primary text-center">
                          {step.name}
                        </span>
                      </div>
                    )}
                  </li>
                  {index < steps.length - 1 && (
                    <div className="w-4 md:w-8 flex-shrink-0">
                      <div className="h-4" />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </ol>
          </nav>
        </div>

        {/* Next Step Button - Desktop Only */}
        <button
          type="button"
          onClick={next}
          disabled={currentStep === steps.length - 1}
          className="absolute right-0 z-20 hidden md:block rounded bg-white p-1 md:p-2 text-primary shadow-lg ring-1 ring-inset ring-primary hover:bg-secondary disabled:cursor-not-allowed disabled:opacity-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-4 w-4 md:h-5 md:w-5 text-primary"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </button>
      </div>

      {/* Content Area */}
      <div className="min-h-[200px]">
        <div
          style={{
            transform: `translateX(${delta >= 0 ? "50%" : "-50%"})`,
            opacity: 0,
            animation: "slideIn 0.3s ease-out forwards",
          }}
        >
          {currentChild}
        </div>
      </div>

      <style>
        {`
          @keyframes slideIn {
            to {
              transform: translateX(0);
              opacity: 1;
            }
          }
          
          .scrollbar-hide::-webkit-scrollbar {
            display: none;
          }
          
          .scrollbar-hide {
            -ms-overflow-style: none;
            scrollbar-width: none;
          }
        `}
      </style>
    </div>
  );
};

export default Stepper;
