import React, { useEffect, useState } from "react";
import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import hero_img from "../assets/iHz_hero.png";
import BoxReveal from "./ui/BoxReveal";
import usecaseHeader from "../assets/applicationHomePage.webp";

const ApplicationsHeroSection = () => {
  const [offset, setOffset] = useState(0);
  const navigate = useNavigate();

  const handleGetDemo = () => {
    navigate("/getaccess");
  };

  useEffect(() => {
    const handleScroll = () => {
      setOffset(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="relative h-[80vh] overflow-hidden flex flex-col justify-center mt-12">
      <div
        className="absolute inset-0 bg-c bg-no-repeat"
        style={{
          backgroundImage: `url(${usecaseHeader})`,
          transform: `translateY(${offset * 0.5 + 150}px)`,
          backgroundPosition: "center right",
          backgroundSize: "auto",
          height: "130%",
          top: "-15%",
        }}
      />

      <div className="absolute inset-0 bg-gradient-to-r from-black/100 via-black/80 to-black/10" />

      <div className="relative z-10 container mx-auto px-4 lg:px-8 mt-8">
        <div className="max-w-2xl lg:max-w-3xl">
          <BoxReveal boxColor={"#ff5757"} duration={0.4}>
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold text-white mb-6 leading-relaxed">

              Where High{" "}
              <span className="inline-block mb-2">
                <span className="border-b-4 border-secondary">Fidelity (Hi-Fi)</span>
              </span>{" "}
              data meets{" "}
              <span className="inline-block mb-2">
                <span className="border-b-4 border-secondary">
                  Intelligence
                </span>
              </span>{" "}
            </h1>
          </BoxReveal>

          <BoxReveal boxColor={"#ff5757"} duration={0.4}>
            <div className="bg-black/50 backdrop-blur-sm p-6 border-l-2 border-secondary max-w-2xl mb-8">
              <p className="text-white text-lg lg:text-xl">
                Our <span className="border-b-2 border-secondary">iHz™ technology</span> system, <span className="border-b-2 border-secondary">accurate</span> and <span className="border-b-2 border-secondary">reliable</span> bridges the value delivery gap between your equipment monitoring and their use cases.
              </p>
            </div>
          </BoxReveal>
        </div>
      </div>
    </div>
  );
}; 

export default ApplicationsHeroSection;
