import React from "react";

const MissionStatement = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      <div className="flex flex-col md:flex-row gap-8">
        <div className="flex-1">
          <h2 className="text-3xl font-bold text-start mb-6 text-secondary font-heading">
            Why we're here!
          </h2>
        </div>

        <div className="flex-1">
          <div className="space-y-6 text-start text-lg font-body text-primary">
            <p className="leading-relaxed">
              We believe that the world we live in must be,
            </p>

            <div className="flex justify-start">
              <ul className="space-y-2 font-medium inline-block text-left">
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Sustainable
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Reliable
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Productive and Efficient
                </li>
              </ul>
            </div>

            <p className="leading-relaxed">
              So, We develop accurate and reliable technology systems to ensure
              that the machines, equipment, and infrastructure powering our
              world make it sustainable, reliable, and efficient.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionStatement;
