import React, { useEffect, useState } from "react";
import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import hero_img from "../assets/iHz_hero.png";
import aboutus_hero from "../assets/aboutus/aboutus_herosextion.jpg";
import BoxReveal from "./ui/BoxReveal";
import usecaseHeader from "../assets/applicationHomePage.webp";

const ParallaxScroll = () => {
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setOffset(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="relative h-[80vh] overflow-hidden flex flex-col justify-center mt-12">
      {/* Background Image with Parallax - Adjusted position */}
      <div
        className="absolute inset-0 bg-contain bg-no-repeat"
        style={{
          backgroundImage: `url(${aboutus_hero})`,
          transform: `translateY(${offset * 0.5 + 150}px)`,
          backgroundPosition: "center right", // Changed to keep device visible
          backgroundSize: "cover", // Changed from 115% auto to cover
          height: "170%",
          top: "-20%",
        }}
      />
      {/* Gradient Overlay - Adjusted to keep right side more visible */}
      <div className="absolute inset-0 bg-gradient-to-r from-secondary/50 via-black/50 to-black/10" />

      {/* Hero Content */}
      <div className="relative z-10 container mx-auto px-4 lg:px-8 mt-8">
        <div className="max-w-2xl lg:max-w-3xl">
          {/* Main Title */}

          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold text-white mb-6 leading-relaxed">
            We build accurate and reliable industrial IoT system that keep the
            world running on time, efficiently!
          </h1>
        </div>
      </div>
    </div>
  );
};

export default ParallaxScroll;
