import React from 'react';
import { Cpu, Activity, ZapOff, BarChart2, Brain, Waypoints } from 'lucide-react';

const FeatureCard = ({ Icon, title }) => (
    <div className="bg-white bg-opacity-5 p-6 rounded-lg backdrop-blur-sm transition-all duration-300 hover:transform hover:-translate-y-1 h-full">
        <div className="flex items-center">
            <Icon className="w-6 h-6 text-secondary mr-3" />
            <h3 className="text-lg font-semibold text-primary">{title}</h3>
        </div>
    </div>
);

const FeaturesSection = () => {
    const features = [
        {
            icon: Cpu,
            title: "Total equipment Intelligence",
        },
        {
            icon: Waypoints,
            title: "High-Fidelity Data",
        },
        {
            icon: Activity,
            title: "Electrical Signatures Driven",
        },
        {
            icon: ZapOff,
            title: "Installation inside Electrical panel",
        },
        {
            icon: BarChart2,
            title: "Scalable for diverse drive trains & equipment operations",
        },
        {
            icon: Brain,
            title: "AI Driven workflows",
        },
    ];

    return (
        <div className="w-full max-w-7xl mx-auto mb-6">
            {/* Features Grid - 2 rows x 3 columns */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
                {features.map((feature, index) => (
                    <div key={index} className="sm:col-span-1">
                        <FeatureCard
                            Icon={feature.icon}
                            title={feature.title}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FeaturesSection;