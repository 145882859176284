import React from "react";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "lucide-react";

const Getdemo = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/getaccess"); // Replace "/contact" with your desired route
  };

  return (
    <button
      onClick={handleClick}
      className="flex items-center ml-6 gap-2.5 px-3 py-2 text-xs font-medium tracking-wide text-white uppercase bg-secondary bg-blend-normal"
    >
      <span className="flex-grow  font-bold">Get Demo</span>
      <ChevronRight className=" flex-shrink-0 " size={18} />
    </button>
  );
};

export default Getdemo;
