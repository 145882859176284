import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { NumberTicker } from "../components/ui/NumberTicker";
import { useMediaQuery } from "react-responsive";


// [Previous data generation code remains unchanged]
const createCycleVariation = (
  baseData,
  cycleNumber,
  variationFactor = 0.07
) => {
  return baseData.map((point) => ({
    time: point.time,
    [`load_cycle${cycleNumber}`]:
      point.load * (1 + (Math.random() * 2 - 1) * variationFactor),
  }));
};

// [Base data definitions remain unchanged]
const baseData1 = [
  { time: 0, load: 47 },
  { time: 1, load: 47 },
  { time: 1.1, load: 300 },
  { time: 2, load: 301 },
  { time: 3, load: 305 },
  { time: 4, load: 300 },
  { time: 5.5, load: 295 },
  { time: 7, load: 290 },
  { time: 7.1, load: 510 },
  { time: 10, load: 455 },
  { time: 12, load: 400 },
  { time: 13, load: 50 },
  { time: 14, load: 50 },
];

const baseData2 = [
  { time: 0, load: 45 },
  { time: 2, load: 45 },
  { time: 3, load: 45 },
  { time: 3.5, load: 190 },
  { time: 4, load: 150 },
  { time: 4.5, load: 45 },
  { time: 6, load: 43 },
  { time: 8, load: 40 },
  { time: 10, load: 50 },
  { time: 12, load: 45 },
  { time: 14, load: 45 },
];

const baseData3 = [
  { time: 0, load: 45 },
  { time: 2, load: 43 },
  { time: 4, load: 47 },
  { time: 6, load: 44 },
  { time: 8, load: 45 },
  { time: 8.5, load: 230 },
  { time: 9, load: 150 },
  { time: 9.5, load: 170 },
  { time: 10.3, load: 45 },
  { time: 11, load: 47 },
  { time: 12, load: 44 },
  { time: 13, load: 46 },
  { time: 14, load: 45 },
];

const createMultiCycleData = (baseData) => {
  const cycles = Array.from({ length: 15 }, (_, i) => i + 1).map((cycleNum) =>
    createCycleVariation(baseData, cycleNum)
  );

  return baseData.map((_, index) => {
    const point = { time: baseData[index].time };
    cycles.forEach((cycle, i) => {
      point[`load_cycle${i + 1}`] = cycle[index][`load_cycle${i + 1}`];
    });
    return point;
  });
};

const data1 = createMultiCycleData(baseData1);
const data2 = createMultiCycleData(baseData2);
const data3 = createMultiCycleData(baseData3);

const Graph = ({ data, value, title, description }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isSmall = useMediaQuery({ maxWidth: 480 });

  // Adjusted margins based on screen size
  const margins = isSmall
    ? { top: 15, right: 15, left: 25, bottom: 20 }
    : isMobile
      ? { top: 20, right: 20, left: 30, bottom: 25 }
      : { top: 20, right: 30, left: 35, bottom: 30 };

  return (
    <div className="bg-white p-4 lg:p-6 border mb-4 rounded-lg">
      {/* Header Section */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
        <h3 className="text-lg lg:text-xl font-semibold text-blue-900 mb-2 sm:mb-0">
          {title}
        </h3>
        <div className="text-right">
          {isMobile ? (
            <div className="flex">
              <span className="font-bold text-4xl text-secondary">{value}</span>
              <p className="text-secondary font-bold pt-3 pl-1">cuts</p>
            </div>
          ) : (
            <div className="flex">
              <NumberTicker
                value={value}
                className="font-bold text-4xl text-secondary"
              />{" "}
              <p className="text-secondary font-bold pl-1 pt-3">cuts</p>
            </div>
          )}
        </div>
      </div>

      {/* Graph Container with Aspect Ratio */}
      <div className="relative w-full" style={{ paddingTop: "75%" }}>
        <div className="absolute top-0 left-0 w-full h-full">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data} margin={margins}>
              <CartesianGrid strokeDasharray="3 3" opacity={0.6} />
              <XAxis
                dataKey="time"
                stroke="#184384"
                label={{
                  value: "Time in Seconds",
                  position: "insideBottom",
                  offset: -10,
                  fill: "#184384",
                  fontSize: isSmall ? 10 : 12,
                }}
                tick={{
                  fill: "#184384",
                  fontSize: isSmall ? 10 : 12,
                  dx: isSmall ? -2 : 0,
                }}
                tickCount={isSmall ? 5 : 8}
              />
              <YAxis
                stroke="#184384"
                label={{
                  value: "Current (% of full Load Current)",
                  angle: -90,
                  fill: "#184384",
                  position: "insideLeft",
                  style: { textAnchor: "middle" },
                  dx: 0, // Reduced the dx value
                  dy: 0, // Adjusted vertical position
                  fontSize: isSmall ? 10 : 12,
                }}
                tick={{
                  fill: "#184384",
                  fontSize: isSmall ? 10 : 12,
                  dx: isSmall ? -2 : -4, // Adjusted tick position
                }}
                tickCount={isSmall ? 5 : 7}
              />
              {Array.from({ length: 15 }, (_, i) => i + 1).map((cycleNum) => (
                <Line
                  key={cycleNum}
                  type="monotone"
                  dataKey={`load_cycle${cycleNum}`}
                  stroke="#708090"
                  strokeWidth={1}
                  dot={false}
                  legendType="none"
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Description Section */}
      <div className="bg-primary-background p-3 lg:p-4 rounded-lg mt-4">
        <div className="space-y-2">
          <p className="text-primary font-medium text-sm">Characterized by:</p>
          <ul className="list-disc ml-4 text-primary text-sm">
            {description.map((item, index) => (
              <li key={index} className="text-xs sm:text-sm">
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const TataSteelGraphs = () => {
  const categories = [
    {
      id: 1,
      value: 95,
      data: data1,
      title: "Profile-1",
      description: [
        "Extended duration: 12-14 seconds per cut",
        "High current draw: Peak at ~500% of base load",
        "Distinct pattern: Initial steep rise (1.1s), sustained plateau (300% load, 6s), final peak (500%, 7.1s)",
      ],
    },
    {
      id: 2,
      value: 85,
      data: data3,
      title: "Profile-2",
      description: [
        "Medium duration: 2-2.3 seconds per cut",
        "Moderate current draw: Peak at ~240% of base load",
        "Pattern: Quick rise (8.5s), Initial sharp peak (240%), rapid decline to no load (10.3s)",
      ],
    },
    {
      id: 3,
      value: 60,
      data: data2,
      title: "Profile-3",
      description: [
        "Short duration: 1-1.5 seconds per cut",
        "Lower current draw: Peak at ~200% of base load",
        "Pattern: Rapid rise (3.5s), single peak (200%), quick return to baseline (4.5s)",
      ],
    },
  ];

  return (
    <div className="w-full p-2 sm:p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {categories.map((category) => (
          <div key={category.id} className="w-full">
            <Graph
              data={category.data}
              value={category.value}
              title={category.title}
              description={category.description}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TataSteelGraphs;
