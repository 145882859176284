import React, { useState } from "react";

import MachinesAccordon from "./ApplicationAccordons/MachinesAccordon";
import FaultTypesAccordon from "./ApplicationAccordons/FaultTypesAccordon";
import DriveTrainAccordion from "./ApplicationAccordons/DriveTrainAccordon";
import IndustriesAccordion from "./ApplicationAccordons/IndustriesAccordon";
import OperationAccordion from "./ApplicationAccordons/OperationAccordon";
import UseCaseAccordion from "./ApplicationAccordons/UseCaseAccordon";
import TeamsAccordon from "./ApplicationAccordons/TeamsAccordon";

const ApplicationsLayout = () => {
  const [expandedSection, setExpandedSection] = useState("machinesEquipment");

  const sections = {
    machinesEquipment: {
      title: " Machines & Equipments",
      description: (
        <div className="space-y-4 text-primary">
          <p className="  text-primary">
            Fundamentally if it is known that your operations are powered by
            <span> rotating machines driven by AC </span>
            then iHz™ is the most accurate & reliable industrial IoT system to
            implement.
          </p>
          <p>
            iHz™ understands how these machines work, are working and will work
            in your operational settings.
            <span className="text-primary">
              {" "}
              iHz™ is extensively tested and proven{" "}
            </span>
            over years for its IoT+AI related application to electrical rotating
            machines.
          </p>
        </div>
      ),
      content: <MachinesAccordon />,
    },
    faultTypes: {
      title: " Fault Types",
      description: (
        <div className="space-y-4">
          <p className="text-primary">
            <span className="text-primary">
              iHz™ systems not only diagnose electrical abnormalities as it is
              apparent but also diagnose mechanical faults at the component
              level and in real-time reveal the condition of the asset at
              sub-system level
            </span>
            . This makes iHz™ far better investment for use-cases such as{" "}
            <span className="text-primary">
              preventing unplanned breakdowns, condition monitoring, predictive
              maintenance and condition based maintenance
            </span>
            .
          </p>
        </div>
      ),
      content: <FaultTypesAccordon />,
    },
    driveTrainComplexity: {
      title: " Drive Train Complexity",
      description: (
        <div className="space-y-4 text-primary">
          <p>
            iHz™ diagnostic systems are designed with an approach that goes
            beyond merely monitoring a machine for specific faults. Instead,{" "}
            <span>
              iHz™'s fundamental approach captures the complexity of the drive
              train: individual mechanics, their interactions, and impact on
              components
            </span>
            . This is because faults are not meant to be diagnosed, but
            prevented altogether in the first place. This heavily depends on
            understanding the machine's drive train. We've observed that bearing
            fault characteristics in a <i>vibro-motor</i> differ completely from
            those in a pump.
          </p>
          <p>
            In some machines, like <i>hot-saws</i>, the entire system operates
            with a combination of motor, belt, long cardan shaft, and a rotating
            cutting saw attached to cut blooms at 1000°C. The whole machine
            slides on a platform. Multiple reaction forces (Ra) affect the
            components in all possible degrees of freedom for which they're
            designed. Sudden shaft breakage, random looseness across parts,
            deterioration of saw functionality, and bearing housing damage
            manifests as a bearing fault are constant problems. In these
            contexts, the mere ability to diagnose a fault is insufficient.
          </p>
          <p>
            Invest in iHz™ systems that understands the drive train mechanism
            and provides you the truth to prevent failure happening again.
          </p>
        </div>
      ),
      content: <DriveTrainAccordion />,
    },
    operationalComplexity: {
      title: " Operation Complexity",
      description: (
        <div className="space-y-4 text-primary">
          <p>
            The context of the machine/equipment is defined by{" "}
            <span>
              how it operates, why it operates, and what it operates with
            </span>
            . Where it operates provides significant constraints on its
            maintenance and machine life time. In Industries, the operations and
            maintenance engineers inherently knows the impact of these factors
            in understanding the condition of the machine.{" "}
            <span>
              iHz™ diagnostic system is reliable to provide precise
              understanding to the engineers to prevent the faults for machines
              which are operating under the influence of these factors
            </span>
            .
          </p>

          <p>
            As an example, <i className="">VFD</i> defines the operating
            characteristics of the electrical rotating machines.{" "}
            <span>
              iHz™ can be installed for VFD or star-delta driven and captures
              the operating characteristics in this context
            </span>
            . In these lines, iHz™ can be dependent up on to monitor machines in
            these circumstances.
          </p>
        </div>
      ),
      content: <OperationAccordion />,
    },
    useCase: {
      title: " Use-case Type ",
      description: (
        <div className="space-y-4 text-primary">
          <p>
            Clients approached us with the following use cases, and we
            collaborated to refine their understanding, carefully defining each
            use case to evaluate the potential application of iHz™.
          </p>

          <p className="pl-4 border-l-4 border-primary mb-4">
            A customer approached us to <span>reduce unplanned breakdowns</span>{" "}
            in their plant, where we identified that the core issue at the
            execution level was the need for real-time condition monitoring of
            subsystems for half of the machines in the plant, along with
            alerting the maintenance team.{" "}
            <span className="">
              After deploying iHz™ the plant has seen instances where there is
              not even a single downtime continuously for 3 months
            </span>
            .
          </p>

          <p className="pl-4 border-l-4 border-primary  mb-4">
            A large steel manufacturing company engaged us to trial our product
            as part of their digital transformation efforts. We implemented iHz™
            after reframing the challenge as{" "}
            <span>
              early and precise fault detection in the drive train to allow for
              planned maintenance
            </span>
            .{" "}
            <span className="= ">
              Within three months of deployment, we received written
              testimonials from the client on the technology's effectiveness
            </span>
            .
          </p>

          <p className=" ">
            If you're considering similar challenges, we're here to partner with
            you. We'll help you refine the problem and deliver measurable impact
            you can see firsthand.
          </p>
        </div>
      ),
      content: <UseCaseAccordion />,
    },
    industries: {
      title: " Industries",
      description: (
        <div className="space-y-4 text-primary">
          <p>
            Every industry defines the nature of operations which eventually
            defines the complexity of the problem around machines.{" "}
            <span>
              iHz™ is a industrial IoT system that is applied in industries
              after thoroughly evaluating and understanding the intrinsic
              complexity of operations
            </span>
            .
          </p>

          <p>
            Based on our experience,{" "}
            <span className="">
              the nature of the heavy industries like Steel plants, makes the
              machines to take heavy lateral thrusts compare to chemical plants
              where the radial forces are high on machines
            </span>
            . Pumps in water treatment and utilities are observed to be having
            few fault modes but{" "}
            <span>the cost of not predicting early is very high</span>. The
            effect of cavitation in a pump severely affects the plant's OEE than
            that in steel plants. There are fans in Chemical plants operated in
            hazardous and also inaccessible areas due to which{" "}
            <span>the cost of false positive is very high</span>.
          </p>

          <p>
            <span>
              We optimize our iHz™ systems to accurately diagnose these
              phenomena, minimizing false positives, improving certaininty in
              fault detection, simplifying device maintenance, and ensuring
              scalability without additional operational costs
            </span>
            . This empowers users to proactively prevent issues, breaking the
            cycle of reactive corrections and moving towards complete
            prevention.
          </p>

          <p className="  mt-4">
            Contact us to schedule a workshop on our real-life industrial case
            studies with our iHz™ industrial IoT system which is accurate and
            reliable for your electrical rotating equipment.
          </p>
        </div>
      ),
      content: <IndustriesAccordion />,
    },
    users: {
      title: "Users",
      description: (
        <div className="space-y-4 text-primary">
          <p>
            iHz™'s core strength lies in its advanced diagnostic capabilities
            for rotating equipment. Through precise electrical signature
            analysis combined with spiderAI™, it delivers specific insights that
            each team needs - from real-time condition alerts for operations to
            detailed fault progression data for maintenance planning. This
            diagnostic intelligence helps teams solve their unique challenges:
            production teams plan targets based on equipment capabilities,
            operations teams execute production while maintaining equipment
            health, maintenance teams prevent failures through early
            intervention, and reliability teams optimize long-term performance.
          </p>
        </div>
      ),
      content: <TeamsAccordon />,
    },
  };

  const handleSectionClick = (key) => {
    setExpandedSection(key);
  };

  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto p-4 lg:p-8">
        {/* Header Section */}
        <div className="text-center mb-6 lg:mb-12">
          <div className="text-secondary font-semibold mb-4 tracking-wider uppercase">
            APPLICATIONS
          </div>
          <h1 className="text-3xl lg:text-4xl font-bold text-primary max-w-4xl mx-auto leading-tight">
            Explore the perfect fit for your monitoring needs with iHz™ by,
          </h1>
        </div>

        {/* Navigation and Content Container */}
        <div className="p-4 lg:p-6">
          {/* Improved Navigation Section */}
          <div className="relative w-full">
            {/* Gradient Fade Effects */}
            <div className="absolute left-0 top-0 bottom-0 w-8 bg-gradient-to-r from-white to-transparent z-10 pointer-events-none md:hidden" />
            <div className="absolute right-0 top-0 bottom-0 w-8 bg-gradient-to-l from-white to-transparent z-10 pointer-events-none md:hidden" />

            {/* Scrollable Navigation Container */}
            <div className="overflow-x-auto scrollbar-hide md:overflow-visible">
              <div className="flex flex-nowrap md:flex-wrap justify-start md:justify-center gap-3 pb-2 min-w-max md:min-w-0">
                {Object.entries(sections).map(([key, section]) => (
                  <button
                    key={key}
                    onClick={() => setExpandedSection(key)}
                    className={`
                      relative group px-6 py-2.5 rounded-full text-sm font-medium
                      transition-all duration-300 ease-in-out
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary
                      ${
                        expandedSection === key
                          ? "bg-secondary text-white  shadow-md hover:shadow-lg transform hover:translate-y-px font-bold"
                          : "bg-gray-50 text-gray-700 hover:bg-gray-100 border border-gray-200 font-bold hover:border-gray-300"
                      }
                    `}
                  >
                    <span className="relative z-10">{section.title}</span>

                    {/* Active State Animation */}
                    {expandedSection === key && (
                      <span className="absolute inset-0 bg-secondary rounded-full animate-pulse opacity-0" />
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Content Section */}
          <div className="mt-8 transition-all duration-300 ease-in-out">
            <div className="text-base leading-relaxed prose prose-lg max-w-none">
              {sections[expandedSection].description}
            </div>
            <div className="mt-8">{sections[expandedSection].content}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationsLayout;
