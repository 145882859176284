import React from "react";
import { Brain, GitBranch } from "lucide-react";

const DecisionSupportLogo = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          padding: "1.5rem",
          borderRadius: "50%",
          backgroundColor: "#184384",
          position: "relative",
        }}
      >
        <Brain
          size={48}
          color="white"
          style={{
            animation: "pulse 2s infinite",
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            padding: "0.5rem",
            borderRadius: "50%",
            backgroundColor: "#ff5757",
            animation: "spin 4s linear infinite",
          }}
        >
          <GitBranch size={24} color="white" />
        </div>
      </div>
      <style jsx>{`
        @keyframes pulse {
          0%,
          100% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.1);
          }
        }
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
};

export default DecisionSupportLogo;
