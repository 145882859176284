import React from "react";

import usecaseHeader from "../assets/usecases/usecaseHeroWindingMotor.jpg";
import { useNavigate } from "react-router-dom";
import usecase1 from "../assets/useCase-hotSaw.jpg";
import usecase2 from "../assets/CaseStudy-2.jpg";
import Breadcrumb from "../components/BreadCrumb";
import ContactUs from "../components/ContactUs";

const EDFArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    className="absolute top-0 right-0 w-10 h-10 sm:w-12 sm:h-12 md:w-14 md:h-14 lg:w-16 lg:h-16 translate-x-full"
  >
    <defs>
      <symbol viewBox="0 0 40 40" id="svgs-icon-edf-arrow">
        <path fill="#001A70" d="M0 40h40V0H0z" />
        <path
          fill="#1056C8"
          d="M13.805 5.364L11.559 7.61 23.949 20l2.246-2.246z"
        />
        <path
          fill="#1089FF"
          d="M11.559 32.39l2.246 2.246 12.39-12.39L23.949 20z"
        />
        <path fill="#FFF" d="M26.195 17.754L23.949 20l2.246 2.246L28.441 20z" />
      </symbol>
    </defs>
    <use href="#svgs-icon-edf-arrow" />
  </svg>
);

const ImageOverlay = ({ imageSrc, title, description, category, url }) => {
  const navigate = useNavigate();

  const handleCardClick = (url) => {
    if (url) {
      navigate(url);
    }
  };
  return (
    <div
      className="relative overflow-hidden shadow-lg h-[300px] sm:h-[400px] lg:h-[500px] cursor-pointer transform transition-transform duration-200 hover:scale-[1.02]"
      onClick={() => handleCardClick(url)}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          handleCardClick(url);
        }
      }}
    >
      <img
        src={imageSrc}
        alt={`${title} `}
        className="w-full h-full object-cover"
      />
      <div className="absolute top-6 left-6">
        <div className="relative bg-blue-600 w-[70%] max-w-[400px]">
          <div className="p-6">
            <div className="w-full">
              <h3 className="text-white font-semibold text-lg sm:text-xl md:text-2xl leading-tight mb-2">
                {title}
              </h3>
              {/* <p className="text-white text-sm sm:text-base">{title}</p> */}
            </div>
          </div>
          <EDFArrow />
        </div>
      </div>
    </div>
  );
};

const UseCases = () => {
  return (
    <div>
      <div className="pt-16 md:pt-20 bg-white">
        {/* Responsive height container */}
        <div className="h-[80vh] md:h-[400px] bg-primary relative">
          {/* Stack on mobile, side by side on desktop */}
          <div className="flex flex-col md:flex-row h-full">
            {/* Text content */}
            <div className="flex-1 flex items-center justify-center p-4 md:p-8">
              <div className="text-3xl md:text-4xl text-white max-w-3xl">
                <p className="text-2xl  md:text-4xl  max-w-3xl leading-relaxed">
                  <b>See iHz™ systems in action.</b>
                </p>

                <p className="text-lg md:text-3xl  max-w-3xl leading-relaxed">
                  Learn how our customers used iHz to monitor machines and
                  equipment that are not only complex in many dimensions but
                  also difficult to monitor with existing technologies.
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/3 h-1/2 md:h-full relative overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-r from-primary/50 to-transparent z-10" />
              <img
                src={usecaseHeader}
                alt="Industrial machine"
                className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-700"
              />
            </div>
          </div>
        </div>
      </div>

      <Breadcrumb />

      <div className=" mx-[5%]  pt-24">
        <div>
          <h1 className="text-4xl font-semibold text-primary ">
            Explore our case studies
          </h1>
          <div className="h-1 bg-secondary w-24 mb-12"></div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16 w-full ">
          <ImageOverlay
            imageSrc={usecase1}
            category="ABOUT"
            title="Understanding Hot Saws Beyond Traditional Monitoring"
            description=""
            url="/usecase-health-and-load-profile"
          />
          <ImageOverlay
            imageSrc={usecase2}
            category="ABOUT"
            title="the Vibro Motor Challenge - Monitor Where Vibration Sensors Fail"
            description=""
            url="/comingsoon"
          />
        </div>
        {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          <ImageOverlay
            imageSrc={ai}
            category="EXPERTISE"
            title="Electrical Signature Analysis"
            description="Simplified explanation of ESA enabling non-invasive monitoring"
          />
          <ImageOverlay
            imageSrc={conversational}
            category="EXPERTISE"
            title="Edge Computing"
            description="Specialized in creating key components for nuclear facilities"
          />
        </div> */}
      </div>
      <div className="w-full p-12 bg-primary-background ">
        <div className="flex flex-col md:flex-row items-start  mx-[5%] justify-between">
          <div className="flex-grow pr-6">
            <h2 className=" text-2xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-bold text-primary leading-relaxed mb-8 md:mb-0">
              Take a look at our growing collection of industrial insights.
            </h2>
          </div>
          <div className="w-full md:w-auto flex items-center justify-center">
            <ContactUs />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseCases;
