import { CardStack } from "./ui/CardStack";
import { cn } from "../lib/utils";
export function DiagnosticAlgo() {
  return (
    <div className="h-[24rem] md:h-[32rem] lg:h-[40rem] flex items-center justify-center w-full">
      <CardStack items={CARDS} />
    </div>
  );
}

export const Highlight = ({ children, className }) => {
  return (
    <span
      className={cn(
        "font-bold bg-[#184384]/[0.2] text-[#184384]  px-1 py-0.5",
        className
      )}
    >
      {children}
    </span>
  );
};

const CARDS = [
  {
    id: 0,
    mainHeading: "Personality and Behavior Attributes",
    subHeading:
      "Combining meticulous attention to detail with scientific brilliance",
    content: (
      <p>
        Define the AI as embodying{" "}
        <Highlight>Sherlock Holmes' detail</Highlight> and{" "}
        <Highlight>Newton's science</Highlight>, leaving no observation unturned
        in diagnostics.
      </p>
    ),
  },
  {
    id: 1,
    mainHeading: "Analytical Process",
    subHeading: "Holistic approach integrating science and deduction",
    content: (
      <p>
        Outline analysis involving <Highlight>scientific groundwork</Highlight>{" "}
        and <Highlight>deductive reasoning</Highlight>
        to uncover faults and root causes.
      </p>
    ),
  },
  {
    id: 2,
    mainHeading: "Knowledge Scope",
    subHeading: "Deep understanding of physics and industrial machinery",
    content: (
      <p>
        Emphasize expertise in{" "}
        <Highlight>physics, engineering, and dynamics</Highlight> for precise
        information recall and application.
      </p>
    ),
  },
  {
    id: 3,
    mainHeading: "Fault Codes",
    subHeading: "Leveraging advanced methodologies for fault identification",
    content: (
      <p>
        Utilize{" "}
        <Highlight>frequency, phase, and time domain analyses</Highlight> with
        fault codes to accurately diagnose machine issues.
      </p>
    ),
  },
  {
    id: 4,
    mainHeading: "Examples and Case Studies",
    subHeading: "Applying knowledge to solve complex cases",
    content: (
      <p>
        Reference <Highlight>solved cases</Highlight> to guide diagnostics,
        ensuring effective fault identification and tailored recommendations.
      </p>
    ),
  },
  {
    id: 5,
    mainHeading: "Communication",
    subHeading: "Presenting clear analysis and recommendations",
    content: (
      <p>
        Communicate <Highlight>reasoning and findings clearly</Highlight>,
        engaging users in the problem-solving process.
      </p>
    ),
  },
  {
    id: 6,
    mainHeading: "Users",
    subHeading: "Supporting maintenance engineers and technicians",
    content: (
      <p>
        Provide <Highlight>actionable insights</Highlight> to enhance machine
        reliability and efficiency in industrial settings.
      </p>
    ),
  },
];
