import { useEffect, useRef } from "react";
import { useInView, useMotionValue, useSpring } from "framer-motion";
import { cn } from "../../lib/utils";

export const Formatter = {
  // Basic number with optional decimal places
  number: (value, decimals = 0) =>
    Intl.NumberFormat("en-US").format(+value.toFixed(decimals)),

  // Decimal number with fixed decimal places
  decimal: (value, decimals = 2) =>
    Intl.NumberFormat("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(+value),

  // Currency with optional decimal places
  currency: (value, decimals = 0) =>
    Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(+value),

  // Percentage with optional decimal places
  percentage: (value, decimals = 0) =>
    Intl.NumberFormat("en-US", {
      style: "percent",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(+value / 100),
};

export default function Counter({
  format = Formatter.number,
  decimals = 0,
  targetValue,
  direction = "up",
  delay = 0,
  duration = 2, // Duration in seconds
  className,
}) {
  const ref = useRef(null);
  const isGoingUp = direction === "up";
  const motionValue = useMotionValue(isGoingUp ? 0 : targetValue);

  // Calculate spring configuration based on target value and desired duration
  const springConfig = useRef({
    damping: 30,
    stiffness: 30,
    // These values create a consistent animation duration
    duration: duration * 1000, // Convert to milliseconds
    bounce: 0,
  });

  const springValue = useSpring(motionValue, springConfig.current);

  const isInView = useInView(ref, { margin: "0px", once: true });

  useEffect(() => {
    if (!isInView) {
      return;
    }

    const timer = setTimeout(() => {
      motionValue.set(isGoingUp ? targetValue : 0);
    }, delay);

    return () => clearTimeout(timer);
  }, [isInView, delay, isGoingUp, targetValue, motionValue]);

  useEffect(() => {
    springValue.on("change", (value) => {
      if (ref.current) {
        ref.current.textContent = format(value, decimals);
      }
    });
  }, [springValue, format, decimals]);

  return (
    <span
      ref={ref}
      className={cn("text-4xl font-bold text-foreground", className)}
    />
  );
}
