import React from "react";

const OperationComplexity = () => {
  const operationFactors = [
    "VFD Driven",
    "High Voltage (HV)",
    "Low-Speed Operation",
    "Rapid Start/Stop",
    "Hard-to-Access Equipment",
    "ATEX Zones",
    "Dusty Environments",
  ];

  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-12 md:py-16">
      {/* Title Section */}
      <div className="mb-12">
        <h2 className="text-3xl md:text-4xl font-bold text-[#14387F] mb-4">
          Operation Complexity
        </h2>
        <div className="w-24 h-1 bg-secondary rounded-full mb-8"></div>

        {/* Description paragraphs */}
        <div className="text-primary text-base leading-tight">
          <p className="mb-4">
            The context of the machine/equipment is defined by how it operates,
            why it operates, and what it operates with. Where it operates
            provides significant constraints on its maintenance and machine life
            time. In Industries, the operations and maintenance engineers
            inherently knows the impact of these factors in understanding the
            condition of the machine. iHz™ diagnostic system is reliable to
            provide precise understanding to the engineers to prevent the faults
            for machines which are operating under the influence of these
            factors.
          </p>
          <p className="mb-4">
            As an example, <em>VFD</em> defines the operating characteristics of
            the electrical rotating machines. iHz™ can be installed for VFD or
            star-delta driven and captures the operating characteristics in this
            context. In these lines, iHz™ can be dependent up on to monitor
            machines in these circumstances.
          </p>
        </div>
      </div>

      {/* Grid of operation factors */}
      <div className="max-w-3xl">
        <ul className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {operationFactors.map((useCase, index) => (
            <li
              key={index}
              className="text-primary text-base p-2 flex items-start"
            >
              <span className="mr-2">•</span>
              {useCase}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default OperationComplexity;
