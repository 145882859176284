import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowUpRight } from "lucide-react";

const UseCases = ({ boxes }) => {
  const navigate = useNavigate();

  const handleBoxClick = (url) => {
    if (url) {
      navigate(url);
    }
  };

  return (
    <div className="w-full">
      <div className="max-w-[1920px] mx-auto px-4 md:px-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-6 lg:gap-8">
          {boxes.map((box, index) => (
            <div
              key={index}
              onClick={() => handleBoxClick(box.url)}
              className="group relative cursor-pointer w-full h-[400px] sm:h-[450px] md:h-[500px] overflow-hidden bg-white hover:bg-primary transition-all duration-300 hover:scale-[1.02]"
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleBoxClick(box.url);
                }
              }}
            >
              <div className="absolute inset-x-0 top-0 z-10 min-h-[120px] bg-white group-hover:bg-primary transition-colors duration-300">
                <div className="p-4 md:p-6 flex items-start justify-between">
                  <div className="flex-1 pr-4">
                    {box.region && (
                      <p className="text-xs md:text-sm uppercase tracking-wide text-primary group-hover:text-white">
                        {box.region}
                      </p>
                    )}
                    <h2 className="text-primary text-lg md:text-xl font-bold leading-tight group-hover:text-white break-words max-w-[90%]">
                      {box.title}
                    </h2>
                  </div>
                  <ArrowUpRight
                    className="text-primary group-hover:text-white transition-colors duration-300 flex-shrink-0"
                    size={32}
                  />
                </div>
              </div>
              <div className="absolute inset-x-0 bottom-0 h-[calc(100%-120px)]">
                <img
                  src={box.image}
                  alt={box.alt}
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UseCases;
