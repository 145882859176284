import React from "react";
import usecasepic from "../assets/useCase-hotSaw.jpg";
import TataSteelGraphs from "../components/TataSteelGraphs";
import diduknow from "../assets/diduknow.png";
import { NumberTicker } from "../components/ui/NumberTicker";
import { useMediaQuery } from "react-responsive";
import ContactUs from "../components/ContactUs";
import bigPic from "../assets/hotsaw-wireframe.png";
import uday from "../assets/tslpl.jpg";
import InteractiveInformationOnImage from "../components/InteractiveInformationOnImage";
import HotSawAnatomy from "../components/HotSawAnatomy";
import EmailCapture from "../components/EmailCapture";
import Testimonials2 from "../components/Testimonials2";
import Breadcrumb from "../components/BreadCrumb";
// Typography Components
const PageTitle = ({ children }) => (
  <h1 className="text-3xl md:text-5xl font-bold text-primary max-w-3xl leading-tight">
    {children}
  </h1>
);

const SectionTitle = ({ children }) => (
  <div className="py-8 md:py-10">
    <h2 className="text-3xl md:text-4xl font-bold text-primary mb-2">
      {children}
    </h2>
    <div className="h-1 bg-secondary w-12"></div>
  </div>
);

const SubsectionTitle = ({ children }) => (
  <h3 className="text-2xl md:text-3xl font-bold text-primary mb-2">
    {children}
  </h3>
);

const FeatureTitle = ({ children }) => (
  <h4 className="text-lg md:text-xl font-semibold text-primary mb-2">
    {children}
  </h4>
);

const BodyText = ({ children, className = "" }) => (
  <p
    className={`text-base md:text-lg text-primary leading-relaxed ${className}`}
  >
    {children}
  </p>
);

const ListItem = ({ children, className = "" }) => (
  <li
    className={`text-base md:text-lg text-primary leading-relaxed ${className}`}
  >
    {children}
  </li>
);

const QuoteText = ({ children }) => (
  <p className="text-xl md:text-2xl font-medium text-primary leading-relaxed">
    {children}
  </p>
);

const CTAText = ({ children }) => (
  <h3 className="text-2xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-bold text-white leading-relaxed">
    {children}
  </h3>
);

export default function UseCaseHealthLoadProfile() {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div>
      {/* Hero Section */}
      <div className="pt-20">
        <div className="h-[300px] md:h-[400px] bg-primary-background relative">
          <div className="flex flex-col md:flex-row h-full">
            <div className="flex-1 flex items-center justify-center p-4 md:p-8">
              <PageTitle>
                Understanding Hot Saws Beyond Traditional Monitoring
              </PageTitle>
            </div>
            <div className="w-full md:w-1/3 h-1/2 md:h-full">
              <img
                src={usecasepic}
                alt="Industrial machine"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
      <Breadcrumb />

      {/* Hot Saw Challenge Section */}
      <div className="mx-[5%]">
        <SectionTitle>The Hot Saw Challenge</SectionTitle>

        <div className="flex flex-col lg:flex-row gap-6 items-start">
          <div className="w-full space-y-6">
            <BodyText>
              <b>Hot saws</b> in steel mills do one critical job - cutting{" "}
              <b>hot blooms</b> into precise lengths. But running these machines
              well is challenging. They cut steel at over <b>1000°C</b>, handle
              massive <b>load spikes up to 300%</b> during each{" "}
              <b>2-8 second cut</b>, and operate on a <b>mobile platform</b> in
              harsh conditions. When something goes wrong, it affects everything
              from production flow to product quality.
            </BodyText>

            <BodyText>
              Making the right decisions about this machine isn't just about
              knowing if it's running or broken. It's about understanding how
              well it's cutting different bloom types, how <b>reliable</b> the
              equipment is, whether <b>maintenance</b> is needed, and if it's
              delivering the <b>quality cuts</b> that customers need. This is
              why traditional monitoring that just looks at one or two things
              isn't enough. We need to look at the whole picture - from cut
              quality to equipment health, from performance to costs - to run
              these machines effectively in today's demanding steel operations.
            </BodyText>

            <HotSawAnatomy />

            {/* Fault Detection Section */}
            <div className="space-y-4">
              <SubsectionTitle>
                Understanding Hot Saw Health Through Electrical signatuares
              </SubsectionTitle>

              <BodyText>
                Real examples from a <b>3-month study</b> show how watching{" "}
                <b>Electrical signatuares</b> can spot problems early:
              </BodyText>

              <ul className="list-disc pl-6 space-y-2">
                <ListItem>
                  <span className="font-medium">Motor end:</span> Bearing
                  housing issue detected 10 days before action needed
                </ListItem>
                <ListItem>
                  <span className="font-medium">Transmission:</span> Keyway
                  damage identified with 30-day warning
                </ListItem>
                <ListItem>
                  <span className="font-medium">Hot Saw frame:</span> Structural
                  issues caught 6 days before belt failure
                </ListItem>
              </ul>

              <InteractiveInformationOnImage
                imageSrc={bigPic}
                points={{
                  point1: { cx: "620", cy: "390", title: "Motor" },
                  point2: { cx: "280", cy: "405", title: "Transmission" },
                  point3: { cx: "272", cy: "151", title: "Hot Saw" },
                }}
              />

              <SubsectionTitle>Real Shop Floor Impact</SubsectionTitle>

              <BodyText>
                Finding these issues early means <b>planned fixes</b> instead of
                sudden breakdowns. Electrical signatuares tell us how each part
                of the saw - from motor to blade - is really working, helping
                prevent <b>costly failures</b>.
              </BodyText>
            </div>
          </div>
        </div>
      </div>

      {/* Performance Section */}
      <div className="mx-[5%]">
        <SectionTitle>Making Better Decisions with Operating Data</SectionTitle>
        <div className="bg-white">
          <SubsectionTitle>Getting More from Your Hot Saw</SubsectionTitle>
          <BodyText className="mb-6">
            Beyond just finding problems, analyzing{" "}
            <b>Electrical signatuares</b> tells us how well the saw is actually
            cutting. We can see how it handles different <b>bloom profiles</b>,
            spot when <b>cut quality</b> might be dropping, and know when
            maintenance will be needed - all from watching how the motor works
            during cuts.
          </BodyText>
          <div className="flex justify-center text-primary text-4xl md:text-4xl font-bold">
            Total cuts detected:{" "}
            <span className="text-secondary ml-2">
              {isMobile ? "240" : <NumberTicker value={240} />}
            </span>
          </div>
        </div>
      </div>

      <div className="mx-[5%] pt-5 pb-10">
        <TataSteelGraphs />
      </div>

      <div className="mx-[5%]">
        <SubsectionTitle>Truth over Insights</SubsectionTitle>
        <BodyText className="mb-6">
          The difference is clear: instead of relying on best guesses or data
          analysis alone, we now know exactly what's happening in the hot saw.
          Electrical signatuares don't just suggest - they <b>prove</b> what's
          really going on. Maintenance teams act with confidence, operators
          trust the early warnings, and production plans with certainty. The
          results are measurable: <b>better cuts</b>, <b>less downtime</b>, and{" "}
          <b>fewer costly surprises</b> - real proof that matters in a busy
          steel mill.
        </BodyText>
      </div>

      <div className="pt-5 w-full">
        <Testimonials2 />
      </div>

      {/* Testimonial Section */}
      <div className="w-full min-h-[400px] bg-primary-background p-8">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center gap-8 p-6">
          <div className="w-full md:w-2/5">
            <img
              src={uday}
              alt="testimonial author"
              className="rounded-lg w-full h-auto object-cover shadow-lg"
            />
          </div>
          <div className="w-full md:w-3/5 relative p-8">
            <div className="absolute -left-4 -top-4">
              <div className="w-12 h-12 border-t-4 border-l-4 border-blue-500" />
            </div>
            <QuoteText>
              "Electrical signatuares reveal the complete story of Hot Saw
              operation - from end to end machine health to performance
              dynamics. These huge machines running in extreme conditions and
              doing heavy duty jobs deserve <b>iHz™ like system.</b>"
            </QuoteText>
            <div className="absolute -bottom-4 -right-4">
              <div className="w-12 h-12 border-b-4 border-r-4 border-blue-500" />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5 pb-10  w-full">
        <EmailCapture />
      </div>

      {/* CTA Section */}
      <div className="w-full p-12 bg-primary">
        <div className="flex flex-col md:flex-row items-start mx-[5%] justify-between">
          <div className="flex-grow pr-6">
            <CTAText>
              "The world runs on electricity, including your equipment. So why
              not choose iHz™ systems, based on HD electrical signatures
              technology"
            </CTAText>
          </div>
          <div className="w-full md:w-auto flex items-center justify-center">
            <ContactUs />
          </div>
        </div>
      </div>

      {/* Machine Impossible Section */}
      <div className="flex flex-col md:flex-row bg-primary-background px-[5%] py-16 min-h-[600px]">
        <div className="md:w-1/2 pr-8 flex flex-col justify-center">
          <SubsectionTitle className="text-4xl lg:text-5xl mb-6">
            Machine Impossible...
          </SubsectionTitle>
          <div className="h-1 w-12 bg-secondary mb-8"></div>
          <ul className="space-y-6">
            <ListItem className="text-lg lg:text-xl">
              Billets are cut at <b>temperatures exceeding 1000°C</b>
            </ListItem>
            <ListItem className="text-lg lg:text-xl">
              Motor <b>load spikes up to 300%</b> of rated capacity during
              cutting
            </ListItem>
            <ListItem className="text-lg lg:text-xl">
              Each cut takes between 2-8 seconds depending on bloom size
            </ListItem>
            <ListItem className="text-lg lg:text-xl">
              Blade diameter ranges from 1.5 to 2.5 meters
            </ListItem>
            <ListItem className="text-lg lg:text-xl">
              Blade life ranges from 8,000 to 15,000 cuts under optimal
              conditions
            </ListItem>
            <ListItem className="text-lg lg:text-xl">
              A <b>misaligned blade</b> can reduce life drastically -{" "}
              <b>from thousands of cuts to just tens of cuts</b>
            </ListItem>
          </ul>
        </div>
        <div className="md:w-1/2 mt-8 md:mt-0 flex items-center">
          <div className="w-full h-full relative">
            <img
              src={diduknow}
              alt="Earth viewed from the moon"
              className="w-full h-[400px] md:h-[500px] lg:h-[600px] object-cover rounded-lg shadow-lg"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
