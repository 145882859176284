import React from "react";
import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
const WhatWeAreSection = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/usecase-health-and-load-profile");
  };

  return (
    <div className="py-16  bg-primary-background ">
      <div className="mx-[5%]">
        <h2 className="text-3xl font-bold text-primary mb-2">Technology</h2>
        <div className="w-20 h-1 bg-secondary mb-8"></div>

        <div className="flex flex-col md:flex-row items-start justify-between">
          <div className="flex-grow pr-0 md:pr-8 mb-8 md:mb-0">
            <h3 className="text-2xl sm:text-3xl lg:text-2xl  text-primary leading-tight">
              <span className="font-semibold">
                "Combining Electrical Signature Analysis with our AI-driven
                spiderAI™ platform
              </span>
              , our IoT system can be installed inside electrical panels to
              monitor health and operations in real-time, 24/7. It diagnoses
              electrical and mechanical faults, energy efficiency of the asset
              and precisely assesses the condition and performance of
              sub-systems & critical components in real-time - all to
              <span className="font-semibold">
                {" "}
                maximise asset availability, reliability and efficiency
              </span>
            </h3>
          </div>
          <div className="flex-shrink-0">
            <button
              onClick={handleClick}
              className="inline-flex items-center px-6 py-3 border font-bold border-transparent text-base text-white bg-secondary hover:bg-secondary-dark transition duration-150 ease-in-out"
            >
              Know More
              <ChevronRight className="text-white font-bold ml-2" size={24} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeAreSection;
