import React, { useMemo } from "react";
import FullWidthCard from "./ui/FullWidthCard";

const ApplicationGrid = ({
  cards,
  title = "Featured Projects",
  heroImage = "",
  heroTitle = "Featured Project",
  heroDescription = "Check out our latest work",
  isVideo: forceVideo,
  mainTitle,
  mainText,
}) => {
  // Automatically detect if media is video based on file extension
  const isVideo = useMemo(() => {
    if (typeof forceVideo === "boolean") return forceVideo;

    const videoExtensions = [".mp4", ".webm", ".ogg", ".mov"];
    return videoExtensions.some((ext) =>
      heroImage?.toLowerCase().endsWith(ext)
    );
  }, [heroImage, forceVideo]);

  // Calculate grid columns based on number of cards
  const getGridClass = (cardsLength) => {
    switch (cardsLength) {
      case 1:
        return "grid-cols-1";
      case 2:
        return "grid-cols-1 lg:grid-cols-2";
      default:
        return "grid-cols-1 md:grid-cols-2 lg:grid-cols-3";
    }
  };

  const renderMedia = () => {
    if (isVideo) {
      return (
        <video
          className="w-full h-full object-cover rounded-xl shadow-lg"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={heroImage} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }

    return (
      <img
        src={heroImage}
        alt={heroTitle}
        className="w-full h-full object-cover rounded-xl shadow-lg"
      />
    );
  };

  return (
    <div className="w-full max-w-7xl mx-auto px-4 py-8 md:px-6 md:py-12">
      {/* Main Title and Text Section - Left aligned with improved spacing */}
      {(mainTitle || mainText) && (
        <div className="mb-16 max-w-4xl">
          {mainTitle && (
            <div className="mb-6">
              <h1 className="text-3xl md:text-4xl font-bold text-primary mb-4">
                {mainTitle}
              </h1>
              <div className="w-24 h-1 bg-secondary rounded-full"></div>
            </div>
          )}
          {mainText && (
            <p className="text-base text-primary leading-tight">{mainText}</p>
          )}
        </div>
      )}

      {/* Hero Media Section with enhanced styling */}
      {heroImage && (
        <div className="mb-16">
          <div className="relative w-full h-[480px] overflow-hidden rounded-2xl shadow-xl">
            {/* Media Container with improved overlay */}
            <div className="absolute inset-0">
              {renderMedia()}
              {/* Enhanced gradient overlay */}
              <div className="absolute inset-x-0 bottom-0 h-64 bg-gradient-to-t from-black/60 via-black/30 to-transparent" />
            </div>
            {/* Content Container with better positioning */}
            <div className="absolute inset-x-0 bottom-0 p-8 text-left">
              {/* {heroTitle && (
                <h2 className="text-3xl md:text-4xl font-bold text-white mb-2 shadow-text">
                  {heroTitle}
                </h2>
              )}
              {heroDescription && (
                <p className="text-lg text-gray-200 shadow-text">
                  {heroDescription}
                </p>
              )} */}
            </div>
          </div>
        </div>
      )}

      <div className="w-full">
        {/* Section Title with improved spacing */}
        <div className="mb-12 text-center">
          <h2 className="text-3xl md:text-4xl font-bold text-primary mb-3">
            {title}
          </h2>
          <div className="w-24 h-1 bg-secondary mx-auto rounded-full"></div>
        </div>

        {/* Dynamic Grid with improved spacing */}
        <div className={`grid ${getGridClass(cards.length)} gap-2 md:gap-2`}>
          {cards.map((card, index) => (
            <div
              key={index}
              className={`transform transition-transform duration-300 hover:-translate-y-1 ${
                cards.length === 1 ? "md:col-span-2 lg:col-span-3" : ""
              }`}
            >
              <FullWidthCard
                title={card.title}
                location={card.location}
                imageSrc={card.imageSrc}
              >
                {card.children}
              </FullWidthCard>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ApplicationGrid;
