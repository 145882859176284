import React from "react";
import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

const ProductSection = ({ productImage }) => {
    const navigate = useNavigate();

    return (
        <div className="p-10 max-w-7xl mx-auto">
            <div className="flex flex-col md:flex-row gap-8">
                <div className="flex-1 flex items-center"> {/* Added flex and items-center */}
                    <div className="w-full">
                        <h2 className="text-secondary font-semibold mb-2">Product</h2>
                        <div className="flex gap-4">
                            <div className="w-1 bg-secondary self-stretch"></div> {/* Added self-stretch */}
                            <div className="space-y-6">
                                <p className="text-primary text-lg leading-loose">
                                    We design hardware that accurately captures essential data,
                                    and we enhance it with software and machine learning to
                                    provide real-time insights into the machinery that drives
                                    our world. We build AI to empower industrial engineers and
                                    technicians to operate these systems with world-class
                                    efficiency and reliability.
                                </p>
                                <button
                                    onClick={() => navigate("/ihz")}
                                    className="inline-flex items-center gap-2 px-6 py-3 border border-transparent text-base font-bold text-white bg-secondary hover:bg-secondary-dark transition duration-150 ease-in-out"
                                >
                                    See our Products
                                    <ChevronRight className="text-white" size={24} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-1">
                    <img
                        src={productImage}
                        alt="Product visualization"
                        className="w-full h-[500px] object-cover rounded-lg shadow-lg"
                    />
                </div>
            </div>
        </div>
    );
};

const PeopleSection = ({ peopleImage }) => {
    const navigate = useNavigate();

    return (
        <div className="bg-secondary-background p-10 max-w-7xl mx-auto">
            <div className="flex flex-col-reverse md:flex-row gap-8">
                <div className="flex-1">
                    <img
                        src={peopleImage}
                        alt="Our team"
                        className="w-full h-[500px] rounded-lg object-cover"
                    />
                </div>
                <div className="flex-1 flex items-center"> {/* Added flex and items-center */}
                    <div className="w-full">
                        <h2 className="text-secondary font-semibold mb-2">People</h2>
                        <div className="flex gap-4">
                            <div className="w-1 bg-secondary self-stretch"></div> {/* Added self-stretch */}
                            <div className="space-y-6">
                                <p className="text-primary text-lg leading-loose">
                                    We are a tribe of artisans, designers, hackers and warriors
                                    building intelligent systems to make the world run efficiently.
                                    We adore Leonardo, Newton, Sherlock Holmes and James Bond -
                                    we bring them together to solve the tough problems at our work.
                                </p>
                                <button
                                    onClick={() => navigate("/careers")}
                                    className="inline-flex items-center gap-2 px-6 py-3 border border-transparent text-base font-bold text-white bg-secondary hover:bg-secondary-dark transition duration-150 ease-in-out"
                                >
                                    Careers
                                    <ChevronRight className="text-white" size={24} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ApproachSection = ({ approachImage }) => {
    return (
        <div className="p-10 max-w-7xl mx-auto">
            <div className="flex flex-col md:flex-row gap-8">
                <div className="flex-1 flex items-center"> {/* Added flex and items-center */}
                    <div className="w-full">
                        <h2 className="text-secondary font-semibold mb-2">Approach</h2>
                        <div className="flex gap-4">
                            <div className="w-1 bg-secondary self-stretch"></div> {/* Added self-stretch */}
                            <div className="space-y-6">
                                <p className="text-primary text-lg leading-loose">
                                    We are fueled by a deep passion for uncovering the real-time
                                    truth about what's happening inside machines. Our deep
                                    interest lies in capturing data that holds this truth, and
                                    we are enthusiastic about using data science to unveil these
                                    insights.
                                </p>
                                <p className="text-primary text-lg leading-relaxed">
                                    We deeply value technologies that are accurate and reliable,
                                    making a significant impact on the lives of those who use
                                    them. Our products help technical teams maximize asset
                                    performance & reliability.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-1">
                    <img
                        src={approachImage}
                        alt="Our approach"
                        className="w-full h-[500px] object-cover rounded-lg shadow-lg"
                    />
                </div>
            </div>
        </div>
    );
};

export { ProductSection, PeopleSection, ApproachSection };