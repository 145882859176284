import React, { useState, useRef, useEffect } from "react";
import { LineChart, Line, ResponsiveContainer } from "recharts";
import FaultAnalysis2 from "./FaultAnalysis2";
import FaultAnalysisReport from "./FaultAnalysisReport";
import FaultAnalysis3 from "./FaultAnalysis3";
import { motion, AnimatePresence } from "framer-motion";

// Pointer subcomponent
const Pointer = ({ cx, cy, pointId, selectedPoint, onClick }) => (
  <g
    className="pointer"
    onClick={(e) => {
      e.stopPropagation();
      onClick(pointId, cx, cy);
    }}
  >
    <circle cx={cx} cy={cy} r="4" fill="red" />
    <circle
      cx={cx}
      cy={cy}
      r="8"
      fill="none"
      stroke="red"
      strokeWidth="2"
      opacity="0.6"
    >
      <animate
        attributeName="opacity"
        values="0.6;0.2;0.6"
        dur="1.5s"
        repeatCount="indefinite"
      />
    </circle>
    <circle
      cx={cx}
      cy={cy}
      r="12"
      fill="none"
      stroke="red"
      strokeWidth="1.5"
      opacity="0.3"
    >
      <animate
        attributeName="r"
        values="10;14;10"
        dur="2s"
        repeatCount="indefinite"
      />
      <animate
        attributeName="opacity"
        values="0.3;0.1;0.3"
        dur="2s"
        repeatCount="indefinite"
      />
    </circle>
    {selectedPoint === pointId && (
      <circle
        cx={cx}
        cy={cy}
        r="16"
        fill="none"
        stroke="red"
        strokeWidth="2"
        opacity="0.8"
      />
    )}
  </g>
);

const InteractiveInformationOnImage = ({ imageSrc, points }) => {
  const [selectedPoint, setSelectedPoint] = useState(null);
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const modalRef = useRef(null);

  const handlePointerClick = (pointId) => {
    setSelectedPoint(pointId);
  };

  const handleClose = () => {
    setSelectedPoint(null);
  };

  const handleOverlayClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    function onKeyDown(event) {
      if (event.key === "Escape") {
        handleClose();
      }
    }
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [selectedPoint]);

  const renderFaultAnalysis = (pointId) => {
    switch (pointId) {
      case "point1":
        return <FaultAnalysis2 />;
      case "point2":
        return <FaultAnalysis3 />;
      case "point3":
        return <FaultAnalysisReport />;
      default:
        return null;
    }
  };

  return (
    <div className="relative w-full">
      <div>
        <svg
          ref={svgRef}
          viewBox="0 0 1000 600"
          className="w-full h-auto preserve-aspect"
          preserveAspectRatio="xMidYMid meet"
        >
          <image
            href={imageSrc}
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid meet"
            className="pointer-events-none"
          />
          {Object.entries(points).map(([pointId, point]) => (
            <Pointer
              key={pointId}
              cx={point.cx}
              cy={point.cy}
              pointId={pointId}
              selectedPoint={selectedPoint}
              onClick={handlePointerClick}
            />
          ))}
        </svg>
      </div>

      <AnimatePresence>
        {selectedPoint && (
          <div
            className="fixed inset-0 h-screen z-50 overflow-auto"
            onClick={handleOverlayClick}
          >
            <div className="flex items-center justify-center min-h-screen p-4">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                ref={modalRef}
                className="w-full max-w-lg bg-white shadow-2xl z-[60] p-4 md:p-6 rounded-3xl font-sans relative max-h-[70vh] overflow-y-auto"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  className="absolute top-3 right-3 h-6 w-6 bg-white rounded-full flex items-center justify-center hover:bg-gray-100 transition-colors text-sm"
                  onClick={handleClose}
                >
                  ✕
                </button>
                <div className="py-4">{renderFaultAnalysis(selectedPoint)}</div>
              </motion.div>
            </div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default InteractiveInformationOnImage;
