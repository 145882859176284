import { Globe, Users, Lightbulb, Rocket, Cog } from "lucide-react"

const FilledFace = () => (
    <svg className="w-6 h-6 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-3 5a3 3 0 01-6 0h6z" clipRule="evenodd" />
    </svg>
)

const UnfilledFace = () => (
    <svg className="w-6 h-6 text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
)

export default function ImpactPlayers() {
    return (
        <div className="w-full max-w-7xl mx-auto bg-white overflow-hidden">
            <div className="p-6">
                <h1 className="text-3xl font-bold text-center text-secondary mb-8">The path to joining minto.ai - becoming spider, the courageous builder!</h1>
                <div className="relative">
                    <div className="absolute left-8 top-0 h-[calc(100%-6rem)] w-0.5 bg-primary-background" />

                    <div className="relative z-10 mb-12">
                        <div className="flex items-center mb-2">
                            <div className="flex items-center justify-center w-16 h-16 bg-blue-100 rounded-full">
                                <Globe className="w-8 h-8 text-primary" />
                            </div>
                            <div className="ml-4 flex-grow">
                                <h3 className="text-xl font-semibold text-primary">Believers in Change</h3>
                                <p className="text-sm text-primary mt-1">Many people genuinely believe in creating an impact</p>
                                <div className="mt-2 flex items-center">
                                    {[...Array(5)].map((_, i) => <FilledFace key={i} />)}

                                </div>
                            </div>
                        </div>
                        <div className="ml-20 p-4 bg-blue-50 rounded-lg">
                            <p className="text-sm text-primary">These individuals aspire to make the world a better place to live in</p>
                        </div>
                    </div>

                    <div className="relative z-10 mb-12">
                        <div className="flex items-center mb-2">
                            <div className="flex items-center justify-center w-16 h-16 bg-yellow-100 rounded-full">
                                <Lightbulb className="w-8 h-8 text-primary" />
                            </div>
                            <div className="ml-4 flex-grow">
                                <h3 className="text-xl font-semibold text-primary">Seekers of Knowledge</h3>
                                <p className="text-sm text-primary mt-1">Some people are interested in knowing how to do this</p>
                                <div className="mt-2 flex items-center">
                                    {[...Array(3)].map((_, i) => <FilledFace key={i} />)}
                                    {[...Array(2)].map((_, i) => <UnfilledFace key={i} />)}

                                </div>
                            </div>
                        </div>
                        <div className="ml-20 p-4 bg-yellow-50 rounded-lg">
                            <p className="text-sm text-primary">They actively seek information and strategies to create meaningful change</p>
                        </div>
                    </div>

                    <div className="relative z-10 mb-12">
                        <div className="flex items-center mb-2">
                            <div className="flex items-center justify-center w-16 h-16 bg-red-100 rounded-full">
                                <Rocket className="w-8 h-8 text-primary" />
                            </div>
                            <div className="ml-4 flex-grow">
                                <h3 className="text-xl font-semibold text-primary">Courageous Builders</h3>
                                <p className="text-sm text-primary mt-1">Very few take the plunge to build something impactful</p>
                                <div className="mt-2 flex items-center">
                                    <FilledFace />
                                    {[...Array(4)].map((_, i) => <UnfilledFace key={i} />)}

                                </div>
                            </div>
                        </div>
                        <div className="ml-20 p-4 bg-red-50 rounded-lg">
                            <p className="text-sm text-primary">These rare individuals have the courage to create solutions they believe in</p>
                        </div>
                    </div>

                    <div className="relative z-10">
                        <div className="flex items-center mb-2">
                            <div className="flex items-center justify-center w-16 h-16 bg-green-100 rounded-full">
                                <Cog className="w-8 h-8 text-primary" />
                            </div>
                            <div className="ml-4 flex-grow">
                                <h3 className="text-xl font-semibold text-primary">Minto.ai: Impact in Action</h3>
                                <p className="text-sm text-primary mt-1">Built by courageous impact makers</p>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="mt-12 p-6 bg-primary-background rounded-lg text-center">
                    <p className="text-lg font-semibold text-primary">The past, present, and future of our world is defined and determined by these courageous builders.</p>
                </div>
            </div>
        </div>
    )
}