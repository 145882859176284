import React from "react";
import { Briefcase, Star } from "lucide-react";

const CareersLogo = () => {
  return (
    <div className="relative w-6 h-6 rounded-full bg-primary flex justify-center items-center group">
      <Briefcase
        size={14}
        color="white"
        className="transform group-hover:scale-110 transition-transform duration-300"
      />
      <div className="absolute -top-0.5 -right-0.5 bg-secondary rounded-full w-2.5 h-2.5 flex items-center justify-center animate-pulse">
        <Star size={6} color="white" className="animate-spin" />
      </div>
    </div>
  );
};

export default CareersLogo;
