import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // Immediately set the scroll position to top
    window.scrollTo(0, 0);

    // Disable scrolling
    document.body.style.overflow = "hidden";

    // Re-enable scrolling after a short delay
    const timeoutId = setTimeout(() => {
      document.body.style.overflow = "";
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [pathname]);

  return null;
}
