import React, { useEffect, useRef, useState } from "react";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const generateClusteredData = (
  count,
  centerX,
  centerY,
  radiusX,
  radiusY,
  noise = 1
) => {
  return Array.from({ length: count }, () => {
    const angle = Math.random() * 2 * Math.PI;
    const r = Math.sqrt(Math.random()) * (Math.random() < 0.9 ? 1 : 2);
    return {
      x:
        centerX + r * radiusX * Math.cos(angle) + (Math.random() - 0.5) * noise,
      y:
        centerY + r * radiusY * Math.sin(angle) + (Math.random() - 0.5) * noise,
    };
  });
};

const generateOvalCluster = (
  count,
  centerX,
  centerY,
  radiusX,
  radiusY,
  rotation = 0
) => {
  return Array.from({ length: count }, () => {
    const t = Math.random() * 2 * Math.PI;
    const r = Math.sqrt(Math.random());
    const x = r * radiusX * Math.cos(t);
    const y = r * radiusY * Math.sin(t);
    const rotatedX = x * Math.cos(rotation) - y * Math.sin(rotation);
    const rotatedY = x * Math.sin(rotation) + y * Math.cos(rotation);
    return {
      x: centerX + rotatedX,
      y: centerY + rotatedY,
    };
  });
};

const RiskScoreScatterPlot = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const resizeTimeoutRef = useRef(null);
  const [hoveredPoint, setHoveredPoint] = useState(null);

  useEffect(() => {
    const createChart = () => {
      if (chartRef.current) {
        const ctx = chartRef.current.getContext("2d");
        const isMobile = window.innerWidth < 768;

        const data = {
          datasets: [
            {
              label: "Normal",
              data: generateClusteredData(3000, -5, 5, 15, 10),
              backgroundColor: "rgba(70, 130, 180, 0.6)",
              pointRadius: isMobile ? 1 : 2,
              hoverRadius: isMobile ? 3 : 5,
            },
            {
              label: "FHS-Cutting Arm Actuator misaligned",
              data: generateClusteredData(200, -10, -8, 3, 2),
              backgroundColor: "rgba(255, 99, 71, 0.6)",
              pointRadius: isMobile ? 1 : 2,
              hoverRadius: isMobile ? 3 : 5,
            },
            {
              label: "FHS-Motor-Hosing looseness",
              data: generateOvalCluster(1000, 25, -2, 12, 5, Math.PI / 6),
              backgroundColor: "rgba(60, 179, 113, 0.6)",
              pointRadius: isMobile ? 1 : 2,
              hoverRadius: isMobile ? 3 : 5,
            },
            {
              label: "THS-Base plate looseness",
              data: generateClusteredData(500, -12, -2, 6, 4),
              backgroundColor: "rgba(186, 85, 211, 0.6)",
              pointRadius: isMobile ? 1 : 2,
              hoverRadius: isMobile ? 3 : 5,
            },
            {
              label: "FHS-Present",
              data: generateClusteredData(300, -8, -10, 4, 2),
              backgroundColor: "rgba(255, 165, 0, 0.6)",
              pointRadius: isMobile ? 1 : 2,
              hoverRadius: isMobile ? 3 : 5,
            },
            {
              label: "FHS-Belt snap",
              data: generateClusteredData(400, -5, -8, 5, 3),
              backgroundColor: "rgba(30, 144, 255, 0.6)",
              pointRadius: isMobile ? 1 : 2,
              hoverRadius: isMobile ? 3 : 5,
            },
            {
              label: "Fault Detected",
              data: [{ x: 35, y: 15 }],
              backgroundColor: "rgba(255, 0, 0, 1)",
              borderColor: "rgba(255, 0, 0, 1)",
              pointRadius: isMobile ? 6 : 8,
              hoverRadius: isMobile ? 8 : 10,
              pointStyle: "triangle",
            },
          ],
        };

        const config = {
          type: "scatter",
          data: data,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: {
                right: isMobile ? 0 : 20,
              },
            },
            scales: {
              x: {
                type: "linear",
                position: "bottom",
                title: {
                  display: false,
                },
                grid: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  display: false,
                },
                min: -25,
                max: 45,
                border: {
                  display: false,
                },
              },
              y: {
                type: "linear",
                position: "left",
                title: {
                  display: false,
                },
                grid: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  display: false,
                },
                min: -20,
                max: 25,
                border: {
                  display: false,
                },
              },
            },
            plugins: {
              title: {
                display: false,
              },
              tooltip: {
                enabled: true,
                mode: "nearest",
                intersect: true,
                callbacks: {
                  label: function (context) {
                    const label = context.dataset.label || "";
                    const coordinates = `(${context.parsed.x.toFixed(
                      2
                    )}, ${context.parsed.y.toFixed(2)})`;
                    return `${label}: ${coordinates}`;
                  },
                },
              },
              legend: {
                position: isMobile ? "bottom" : "right",
                align: "start",
                labels: {
                  boxWidth: isMobile ? 8 : 12,
                  padding: isMobile ? 8 : 10,
                  font: {
                    size: isMobile ? 10 : 12,
                  },
                },
              },
            },
            onHover: (event, elements) => {
              const canvas = event.native.target;
              if (elements && elements.length > 0) {
                canvas.style.cursor = "pointer";
                setHoveredPoint(elements[0]);
              } else {
                canvas.style.cursor = "default";
                setHoveredPoint(null);
              }
            },
          },
        };

        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
        chartInstance.current = new Chart(ctx, config);
      }
    };

    const handleResize = () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }

      resizeTimeoutRef.current = setTimeout(() => {
        createChart();
      }, 250);
    };

    createChart();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  return (
    <div className="w-full h-64 md:h-96 max-h-[500px] relative">
      <canvas ref={chartRef} />
    </div>
  );
};

export default RiskScoreScatterPlot;
