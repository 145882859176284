import React from "react";
import ArticleList from "../components/ArticlesList";
import Breadcrumb from "../components/BreadCrumb";
export default function Articles() {
  return (
    <div className="pt-24 bg-white">
      <ArticleList />
      <Breadcrumb />
    </div>
  );
}
