import React from "react";
import {
  Calendar,
  AlertCircle,
  AlertTriangle,
  Clock,
  ChartLine,
  CheckCircle,
  Microscope,
  DollarSign,
  Mail,
} from "lucide-react";
import FrequencySpectrum from "./usecase_graphs/keyway_graphs";

const FaultAnalysis3 = () => {
  const sections = [
    {
      title: "Event Details",
      icon: <AlertCircle className="w-5 h-5 text-primary" />,
      content: {
        type: "grid",
        items: [
          {
            label: "Event Date",
            value: "May 12, 2023",
            icon: <Calendar className="w-4 h-4" />
          },
          {
            label: "Event",
            value: "Driven Pulley keyway changed",
            icon: <AlertTriangle className="w-4 h-4" />
          },
          {
            label: "Fault",
            value: "Driven Pulley keyway damaged",
            icon: <AlertCircle className="w-4 h-4" />
          },
          {
            label: "Days before the Event",
            value: "30 Days",
            valueClass: "font-medium text-primary",
            icon: <Clock className="w-4 h-4" />
          }
        ]
      }
    },
    {
      title: "Observed Fault Signatures",
      icon: <ChartLine className="w-5 h-5 text-primary" />,
      content: {
        type: "list",
        items: [
          "↑ in Saw Assembly's 1X rpm Amplitudes",
          "Noise floor @ Belt 1X rpm",
          "Saw Assembly RPM showing modulation with Belt frequencies"
        ],
        graph: true
      }
    },
    {
      title: "Diagnosis",
      icon: <Microscope className="w-5 h-5 text-primary" />,
      content: {
        type: "list",
        items: [
          "Mechanical issue detected at Saw Assembly-Belt interface",
          "Recommended inspection of pulley, keyway and checking for potential looseness at interface"  // Changed to recommendation
        ]
      }
    },
    {
      title: "Value Impact",
      icon: <DollarSign className="w-5 h-5 text-primary" />,
      content: {
        type: "text",
        value: "Early detection 30 days in advance prevented unplanned downtime and averted major damage to the saw assembly and drive system."
      }
    }
  ];

  return (
    <div className="bg-white min-h-screen">
      {/* Header */}
      <div className="bg-secondary-background rounded-lg p-4 mb-6">
        <div className="flex items-center space-x-3">
          <div className="bg-blue-100 p-3 rounded-lg">
            <CheckCircle className="w-6 h-6 text-primary" />
          </div>
          <h1 className="text-2xl font-semibold text-primary">
            Saw Drive - Pulley Keyway Damage
          </h1>
        </div>
      </div>

      {/* Main Content */}
      <div className="space-y-2">
        {sections.map((section, index) => (
          <div
            key={section.title}
            className="bg-white rounded-lg p-6 hover:bg-gray-50 transition-colors duration-200"
          >
            <div className="flex items-center space-x-3 mb-4">
              <div className="bg-blue-50 p-3 rounded-lg">{section.icon}</div>
              <h2 className="text-xl font-semibold text-primary">
                {section.title}
              </h2>
            </div>

            <div className="pl-2">
              {section.content.type === "grid" && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {section.content.items.map((item, i) => (
                    <div key={i} className="flex items-center space-x-3">
                      <div className="text-primary">{item.icon}</div>
                      <div>
                        <div className="text-sm font-bold text-primary">{item.label}</div>
                        <div className={item.valueClass || "text-primary"}>
                          {item.value}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {section.content.type === "list" && (
                <div className="space-y-4">
                  <ul className="space-y-2">
                    {section.content.items.map((item, i) => (
                      <li key={i} className="flex items-start space-x-2">
                        <div className="w-1.5 h-1.5 rounded-full bg-primary mt-2" />
                        <span className="text-primary">{item}</span>
                      </li>
                    ))}
                  </ul>
                  {section.content.graph && (
                    <div className="mt-4 border rounded-lg bg-gray-50 overflow-hidden">
                      <div className="w-full max-w-full aspect-[16/5]">
                        <FrequencySpectrum />
                      </div>
                    </div>
                  )}
                </div>
              )}

              {section.content.type === "text" && (
                <p className="text-primary">{section.content.value}</p>
              )}
            </div>
          </div>
        ))}

      </div>
    </div>
  );
};

export default FaultAnalysis3;