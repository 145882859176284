import { cn } from "../lib/utils";
import { useEffect, useState, useRef } from "react";
import { AnimatedList } from "../@/components/ui/animated-list";
const notifications = [
  {
    name: "Fault on the Rise",
    description:
      "The issue of Gear Mesh in Rod Mill - 4 has been deteriorating sharply since yesterday and is now approaching a critical cliff. It is recommended to schedule an inspection within 48 hours.",
    time: "8am",
    icon: "⚠️",
    color: "#FF3D71",
  },
  {
    name: "Maintenance Team",
    description:
      "Hey there! @Atul has just completed the replacement of worn out bearing in Sand Mixer -3.",
    time: "3pm",
    icon: "🔧",
    color: "#00C9A7",
  },
  {
    name: "Overload Alert",
    description:
      "We have just detected an overload in Conveyor D-112 within the last 10 seconds. Further updates will follow as events develop.",
    time: "1am",
    icon: "🚨",
    color: "#FFB800",
  },
  {
    name: "Plant summary",
    description:
      "Increased vibration in Pump P-45 and belt wear detected in Sinter Machine SM-10. Monitoring in progress across both machines. All other systems remain stable. Updates to follow.",
    time: "10pm",
    icon: "📊",
    color: "#1E86FF",
  },
  {
    name: "Operating status",
    description:
      "After experiencing a transient bearing wear issue in the Hot Saw for the past two days, it is now operating in stable condition",
    time: "10am",
    icon: "✅",
    color: "#00C9A7",
  },
  {
    name: "Abrupt Fault Escalation",
    description:
      "Machine M-100 has shown a sudden and significant increase in fault activity. Its condition is deteriorating rapidly, and immediate attention is required to prevent further damage or operational downtime.",
    time: "2am",
    icon: "🚨",
    color: "#FF3D71",
  },
];
const Notification = ({ name, description, icon, color, time }) => {
  return (
    <figure
      className={cn(
        "relative mx-auto min-h-fit w-full max-w-[600px] cursor-pointer overflow-hidden rounded-2xl p-4",
        "transition-all duration-4000 ease-in-out hover:scale-[103%]",
        "bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]",
        "transform-gpu "
      )}
    >
      <div className="flex flex-row items-start gap-3">
        <div
          className="flex size-10 items-center justify-center rounded-2xl"
          style={{
            backgroundColor: color,
          }}
        >
          <span className="text-lg">{icon}</span>
        </div>
        <div className="flex flex-col overflow-hidden">
          <figcaption className="flex flex-row items-center whitespace-pre text-lg font-medium  ">
            <span className="text-sm sm:text-lg">{name}</span>
            <span className="mx-1">·</span>
            <span className="text-xs text-gray-500">{time}</span>
          </figcaption>
          <p className="text-sm font-normal">{description}</p>
        </div>
      </div>
    </figure>
  );
};
export function AnimatedListDemo({ className }) {
  const [showGradient, setShowGradient] = useState(false);
  const listRef = useRef(null);

  useEffect(() => {
    const checkScroll = () => {
      if (listRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listRef.current;
        setShowGradient(
          scrollHeight > clientHeight && scrollTop + clientHeight < scrollHeight
        );
      }
    };

    const listElement = listRef.current;
    if (listElement) {
      listElement.addEventListener("scroll", checkScroll);
      // Initial check
      checkScroll();
    }

    return () => {
      if (listElement) {
        listElement.removeEventListener("scroll", checkScroll);
      }
    };
  }, []);

  return (
    <div
      className={cn(
        "relative flex h-[500px] w-full flex-col p-6 overflow-hidden rounded-lg bg-white",
        className
      )}
    >
      <div ref={listRef} className="overflow-auto h-full">
        <AnimatedList>
          {notifications.map((item, idx) => (
            <Notification {...item} key={idx} />
          ))}
        </AnimatedList>
      </div>
      {showGradient && (
        <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-primary-background to-transparent pointer-events-none"></div>
      )}
    </div>
  );
}
