import React from "react";
import { ChevronRight } from "lucide-react";

import ai from "../assets/technologies/ai.png";
import edge from "../assets/technologies/edgeComputing.png";
import esa from "../assets/technologies/esa.png";
import conversational from "../assets/technologies/conversational.png";
import tataSteel from "../assets/clients/tataSteel.jpg";
import bokaro from "../assets/clients/bokaro.jpg";
import patil from "../assets/clients/patil-Kallakal.jpg";
import patilBokaro from "../assets/clients/patil.jpg";
import Breadcrumb from "../components/BreadCrumb";

const ImageOverlay = ({ imageSrc, title, description, category }) => {
  return (
    <div className="relative w-full h-[600px] overflow-hidden">
      <img
        src={imageSrc || "/api/placeholder/1200/900"}
        alt="Background"
        className="w-full h-full object-cover"
      />
      <div className="absolute bottom-8 left-8 bg-primary text-white p-6 max-w-[80%]">
        <p className="text-sm font-semibold mb-2">{category}</p>
        <h3 className="text-2xl font-bold mb-2">{title}</h3>
        <p className="text-base">{description}</p>
        <ChevronRight className="absolute bottom-6 right-6 h-8 w-8" />
      </div>
    </div>
  );
};

const CaseStudies = () => {
  return (
    <div className=" mx-[5%]  pt-24">
      <h1 className="text-5xl font-bold text-primary ">
        From Diagnosis to Intelligence: Customer Success{" "}
      </h1>
      <div className="h-1 bg-secondary w-24 mb-12"></div>

      <p className="text-lg text-primary  max-w-3xl pb-10">
        From Diagnosis to Intelligence: Customer Success" Description: "Dive
        into real-world examples of industrial transformation powered by iHz and
        SpiderAI. These case studies showcase how our technologies work in
        tandem to solve complex challenges across various sectors. Learn how
        iHz's precise diagnostics laid the foundation for proactive maintenance,
        and how SpiderAI's intelligent insights drove strategic decision-making.
        Each story represents a journey from reactive problem-solving to
        predictive, AI-enhanced industrial operations
      </p>

      <Breadcrumb />

      <div>
        <h2 className="text-4xl font-semibold text-primary ">
          To Find out More
        </h2>
        <div className="h-1 bg-secondary w-24 mb-12"></div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16 w-full ">
        <ImageOverlay
          imageSrc={tataSteel}
          category="ABOUT"
          title="Specialists in nuclear design and construction"
          description="Expertise in creating advanced components for nuclear facilities"
        />
        <ImageOverlay
          imageSrc={bokaro}
          category="ABOUT"
          title="Imagining, designing, maintaining and optimizing nuclear reactors"
          description="Comprehensive solutions for the entire lifecycle of nuclear reactors"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
        <ImageOverlay
          imageSrc={patil}
          category="EXPERTISE"
          title="Electrical Signature Analysis"
          description="Simplified explanation of ESA enabling non-invasive monitoring"
        />
        <ImageOverlay
          imageSrc={patilBokaro}
          category="EXPERTISE"
          title="Edge Computing"
          description="Specialized in creating key components for nuclear facilities"
        />
      </div>
    </div>
  );
};

export default CaseStudies;
