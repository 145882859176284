import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";

const TataSteelGraphsOnly = () => {
  // Data points
  const data1 = [
    { time: 0, load1: 47 },
    { time: 1, load1: 47 },
    { time: 1.1, load1: 300 },
    { time: 2, load1: 301 },
    { time: 3, load1: 305 },
    { time: 4, load1: 300 },
    { time: 5.5, load1: 295 },
    { time: 7, load1: 290 },
    { time: 7.1, load1: 510 },
    { time: 10, load1: 455 },
    { time: 12, load1: 400 },
    { time: 13, load1: 50 },
    { time: 14, load1: 50 },
  ];

  const data2 = [
    { time: 0, load2: 45 },
    { time: 2, load2: 43 },
    { time: 4, load2: 47 },
    { time: 6, load2: 44 },
    { time: 8, load2: 45 },
    { time: 8.5, load2: 230 },
    { time: 9, load2: 150 },
    { time: 9.5, load2: 170 },
    { time: 10.3, load2: 45 },
    { time: 11, load2: 47 },
    { time: 12, load2: 44 },
    { time: 13, load2: 46 },
    { time: 14, load2: 45 },
  ];

  const data3 = [
    { time: 0, load3: 45 },
    { time: 2, load3: 45 },
    { time: 3, load3: 45 },
    { time: 3.5, load3: 190 },
    { time: 4, load3: 150 },
    { time: 4.5, load3: 45 },
    { time: 6, load3: 43 },
    { time: 8, load3: 40 },
    { time: 10, load3: 50 },
    { time: 12, load3: 45 },
    { time: 14, load3: 45 },
  ];

  const combinedData = Array.from(
    new Set([
      ...data1.map((d) => d.time),
      ...data2.map((d) => d.time),
      ...data3.map((d) => d.time),
    ])
  )
    .sort((a, b) => a - b)
    .map((time) => ({
      time,
      load1: data1.find((d) => d.time === time)?.load1,
      load2: data2.find((d) => d.time === time)?.load2,
      load3: data3.find((d) => d.time === time)?.load3,
    }));

  return (
    <div className="w-full max-w-md bg-white shadow-lg rounded-lg overflow-hidden">
      {/* Header Section */}

      {/* Graph Container */}
      <div className="p-4">
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={combinedData}
              margin={{ top: 5, right: 5, left: 0, bottom: 5 }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                opacity={0.3}
                vertical={false}
              />
              <XAxis
                dataKey="time"
                stroke="#4a7198"
                tick={{ fill: "#4a7198", fontSize: 10 }}
                tickCount={7}
                domain={[0, 14]}
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                stroke="#4a7198"
                tick={{ fill: "#4a7198", fontSize: 10 }}
                tickCount={5}
                domain={[0, 600]}
                axisLine={false}
                tickLine={false}
                width={30}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: "white",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "12px",
                  padding: "8px",
                }}
                labelFormatter={(value) => `Time: ${value}`}
              />
              <Legend
                align="center"
                verticalAlign="bottom"
                height={36}
                iconSize={8}
                wrapperStyle={{
                  fontSize: "12px",
                  paddingTop: "8px",
                }}
              />
              <Line
                type="monotone"
                dataKey="load1"
                name="Profile 1"
                stroke="#2563eb"
                strokeWidth={1.5}
                dot={false}
                activeDot={{ r: 4 }}
                connectNulls
              />
              <Line
                type="monotone"
                dataKey="load2"
                name="Profile 2"
                stroke="#dc2626"
                strokeWidth={1.5}
                dot={false}
                activeDot={{ r: 4 }}
                connectNulls
              />
              <Line
                type="monotone"
                dataKey="load3"
                name="Profile 3"
                stroke="#16a34a"
                strokeWidth={1.5}
                dot={false}
                activeDot={{ r: 4 }}
                connectNulls
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default TataSteelGraphsOnly;
