import React, { useEffect, useState } from "react";
import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import hero_img from "../assets/gears.jpg";
import BoxReveal from "./ui/BoxReveal";

const GetDemoHeroSection = () => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setOffset(window.pageYOffset);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="relative min-h-[80vh] md:h-[80vh] overflow-hidden flex items-center pt-12 md:pt-20">
      {/* Background Image Container */}
      <div
        className="absolute inset-0 bg-no-repeat md:bg-contain bg-cover md:bg-right bg-center"
        style={{
          backgroundImage: ` url(${hero_img})`, // Replace with your actual image
          transform: `translateY(${offset * 0.5}px)`,
        }}
      />

      {/* Gradient Overlay */}
      <div className="absolute inset-0 w-full md:w-2/3 bg-gradient-to-r from-black via-black/100 to-transparent" />

      {/* Content */}
      <div className="relative z-10 container mx-auto px-4 lg:px-8">
        <div className="max-w-full md:max-w-2xl lg:max-w-3xl">
          <div className="transform transition-transform duration-400 ease-in-out">
            <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-white mb-6 leading-relaxed">
              From{" "}
              <span className="inline-block mb-2">
                <span className="border-b-2 md:border-b-4 border-red-500">
                  anomalies
                </span>
              </span>
              ,{" "}
              <span className="inline-block mb-2">
                <span className="border-b-2 md:border-b-4 border-red-500">
                  symptoms
                </span>
              </span>{" "}
              and{" "}
              <span className="inline-block mb-2">
                <span className="border-b-2 md:border-b-4 border-red-500">
                  faults
                </span>
              </span>{" "}
              to decisions and{" "}
              <span className="inline-block mb-2">
                <span className="border-b-2 md:border-b-4 border-red-500">
                  actions backed by evidence{" "}
                </span>
              </span>{" "}
              —all within the framework of achieving your objective to maximize
              equipment performance in alignment with your operational goals.
            </h1>
          </div>

          <div className="transform transition-transform duration-400 ease-in-out">
            <div className="bg-black/50 backdrop-blur-sm p-4 md:p-6 border-l-2 border-red-500 max-w-full md:max-w-2xl">
              <p className="text-base sm:text-lg lg:text-xl text-white">
                <span className="inline-block mb-2">
                  <span className="border-b-2 border-red-500">
                    Total Equipment Intelligence
                  </span>
                </span>{" "}
                to unleash the{" "}
                <span className="inline-block mb-2">
                  <span className="border-b-2 border-red-500">
                    full potential
                  </span>
                </span>{" "}
                of your machines! IHz, The industrial IoT system your operations
                need!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetDemoHeroSection;
