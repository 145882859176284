import React from 'react';

const FrequencySpectrum = () => {
    const viewBoxWidth = 800;
    const viewBoxHeight = 250;
    const margin = { top: 20, right: 30, bottom: 30, left: 30 };

    const generateSpectrumData = (isAbnormal = false) => {
        const points = [];
        for (let x = 0; x <= 20; x += 0.02) {
            let y = 0;

            y += Math.random() * 0.001;

            if (isAbnormal) {
                y += fftPeak(x, 4, 0.3);
                y += fftPeak(x, 4 - 0.24, 0.12);
                y += fftPeak(x, 4 + 0.24, 0.12);

                y += fftPeak(x, 12.5, 0.45);
                y += fftPeak(x, 12.5 - 0.24, 0.18);
                y += fftPeak(x, 12.5 + 0.24, 0.18);
            } else {
                y += fftPeak(x, 4, 0.25);
                y += fftPeak(x, 12.5, 0.3);
            }

            points.push({ x, y: Math.max(0, y) });
        }
        return points;
    };

    const fftPeak = (x, center, amplitude) => {
        const width = 0.04;
        return amplitude * Math.exp(-Math.pow(x - center, 2) / (2 * Math.pow(width, 2)));
    };

    const xScale = (x) => (x * (viewBoxWidth - margin.left - margin.right) / 20) + margin.left;
    const yScale = (y) => viewBoxHeight - margin.bottom - (y * (viewBoxHeight - margin.top - margin.bottom) / 0.5);

    const createPath = (points) => {
        const path = [];
        path.push(`M ${xScale(points[0].x)},${yScale(points[0].y)}`);

        for (let i = 1; i < points.length - 2; i++) {
            const x0 = xScale(points[i - 1].x);
            const y0 = yScale(points[i - 1].y);
            const x1 = xScale(points[i].x);
            const y1 = yScale(points[i].y);
            const x2 = xScale(points[i + 1].x);
            const y2 = yScale(points[i + 1].y);
            const x3 = xScale(points[i + 2].x);
            const y3 = yScale(points[i + 2].y);

            const tension = 0.3;
            const cp1x = x1 + (x2 - x0) * tension;
            const cp1y = y1 + (y2 - y0) * tension;
            const cp2x = x2 - (x3 - x1) * tension;
            const cp2y = y2 - (y3 - y1) * tension;

            path.push(`C ${cp1x},${cp1y} ${cp2x},${cp2y} ${x2},${y2}`);
        }

        return path.join(' ');
    };

    const normalData = generateSpectrumData(false);
    const abnormalData = generateSpectrumData(true);

    return (
        <div className="w-full max-w-full overflow-hidden">
            <svg
                width="100%"
                height="100%"
                viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
                preserveAspectRatio="xMidYMid meet"
                className="w-full h-auto"
                style={{ maxHeight: '250px' }}
            >
                <path
                    d={`${createPath(normalData)} L ${xScale(20)},${yScale(0)} L ${xScale(0)},${yScale(0)} Z`}
                    fill="#184384"
                    fillOpacity="0.1"
                />
                <path
                    d={`${createPath(abnormalData)} L ${xScale(20)},${yScale(0)} L ${xScale(0)},${yScale(0)} Z`}
                    fill="#ff5757"
                    fillOpacity="0.1"
                />

                <path
                    d={createPath(normalData)}
                    fill="none"
                    stroke="#184384"
                    strokeWidth="1.5"
                    strokeOpacity="0.8"
                />

                <path
                    d={createPath(abnormalData)}
                    fill="none"
                    stroke="#ff5757"
                    strokeWidth="1.5"
                    strokeOpacity="0.8"
                />

                <text
                    x={xScale(4)}
                    y={viewBoxHeight - 5}
                    textAnchor="middle"
                    className="text-xs"
                >
                    Belt RPM
                </text>
                <text
                    x={xScale(12.5)}
                    y={viewBoxHeight - 5}
                    textAnchor="middle"
                    className="text-xs"
                >
                    Motor RPM
                </text>

                <g transform={`translate(${viewBoxWidth - 120}, ${margin.top})`}>
                    <line x1={0} y1={0} x2={20} y2={0} stroke="#184384" strokeWidth="1.5" strokeOpacity="0.8" />
                    <text x={25} y={0} alignmentBaseline="middle" className="text-xs">Normal</text>
                    <line x1={0} y1={20} x2={20} y2={20} stroke="#ff5757" strokeWidth="1.5" strokeOpacity="0.8" />
                    <text x={25} y={20} alignmentBaseline="middle" className="text-xs">Abnormal</text>
                </g>
            </svg>
        </div>
    );
};

export default FrequencySpectrum;