import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ChevronRight } from "lucide-react";

const Breadcrumb = () => {
  const location = useLocation();

  const getBreadcrumbs = () => {
    const pathnames = location.pathname.split("/").filter((x) => x);

    const nameMapping = {
      spiderai: "spiderAI™",
      ihz: "iHz™",
      contactus: "Contact Us",
      getaccess: "Get Demo",
      usecase1: "Use Case 1",
      usecases: "Case Studies",
      casestudies: "Case Studies",
      blog: "Blog",
      technologies: "Technologies",
      about: "About Us",
      careers: "Careers",
      applications: "Applications",
      comingsoon: "Coming Soon",
      "usecase-health-and-load-profile": "Health & Load Profile",
    };

    const blogPostMapping = {
      "embracing-evolution-with-spider-ai":
        "Embracing Evolution with Spider AI",
      "features-of-ai-driven-iot-product": "Features of AI-Driven IoT Product",
      introducing: "Introducing",
      "minto-ai-recognized-as-top-2-innovative-startups":
        "Minto AI Recognition",
      "profound-impact-of-minto-ai": "Profound Impact of Minto AI",
      "unveiling-inefficiencies-in-traditional-maintenance-methods":
        "Unveiling Inefficiencies",
      "bokaro-steel-plant-partners-with-minto-ai":
        "Bokaro Steel Plant Partnership",
    };

    return pathnames.map((name, index) => {
      const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
      let displayName =
        nameMapping[name] ||
        (pathnames[index - 1] === "blog" ? blogPostMapping[name] : null) ||
        name.charAt(0).toUpperCase() + name.slice(1);

      return {
        path: routeTo,
        name: displayName,
      };
    });
  };

  const breadcrumbs = getBreadcrumbs();

  if (breadcrumbs.length === 0) return null;

  return (
    <nav className="absolute top-4 left-4 mt-14 md:mt-20 md:left-8 z-10 mx-[4%]">
      <ol className="flex items-center">
        <li className="flex items-center">
          <Link
            to="/"
            className="text-white hover:text-gray-200 transition-colors text-xs tracking-wide"
          >
            Home
          </Link>
        </li>
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={breadcrumb.path} className="flex items-center">
            <ChevronRight className="h-3 w-3 md:h-4 md:w-4 text-white opacity-70 mx-1" />
            {index === breadcrumbs.length - 1 ? (
              <span className="text-white text-xs tracking-wide opacity-90">
                {breadcrumb.name}
              </span>
            ) : (
              <Link
                to={breadcrumb.path}
                className="text-white hover:text-gray-200 transition-colors text-xs tracking-wide"
              >
                {breadcrumb.name}
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
