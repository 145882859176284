import React from "react";
import teamImage from "../assets/team/team.jpeg";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "lucide-react";

const CareersComponent = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/careers");
  };
  return (
    <div className="bg-white p-10 max-w-6xl mx-auto">
      <div className="flex flex-col md:flex-row gap-8">
        <div className="flex-1">
          <h2 className="text-secondary font-semibold mb-2">Careers</h2>
          <h1 className="text-xl font-bold mb-4 text-primary">
            We are a tribe of artisans , designers, hackers and warriors
            building intelligent systems to make the world run on time
            efficiently.
          </h1>
          <p className="mb-6  text-primary">
            We build hardware that captures the truth efficiently , we power
            this hardware with software & machine learning to empower everyone
            understand how the world is running mechanically, we build AI that
            helps everyone to ensure that it runs on time efficiently.
          </p>

          <div className="flex-shrink-0">
            <button
              onClick={handleClick}
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-bold text-white bg-secondary hover:bg-secondary-dark transition duration-150 ease-in-out"
            >
              Careers
              <ChevronRight className="text-white" size={24} />
            </button>
          </div>
        </div>
        <div className="flex-1">
          <img
            src={teamImage}
            alt="Minto team"
            className="w-full h-auto rounded-lg object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default CareersComponent;
