import React from 'react';

const AnatomySection = ({ title, children }) => (
    <div className="flex-1 min-w-[250px]">
        <div className="mb-3">
            <h3 className="font-semibold text-lg text-secondary">{title}</h3>
        </div>
        {children}
    </div>


);

const FeatureList = ({ items }) => (
    <ul className="ml-7 space-y-1.5 list-disc text-primary">
        {items.map((item, index) => (
            <li key={index}>{item}</li>
        ))}
    </ul>
);

const HotSawAnatomy = () => {
    const features = {
        driveSystem: [
            "Motor: 110 kW power rating",
            "Current: 205A rated current draw",
            "Configuration: High-speed industrial drive"
        ],
        powerTransmission: [
            "V-Belt driven drive system"
        ],
        cuttingAssembly: [
            "Saw Blade: 1.6-meter diameter",
            "Mobile cutting platform",
            "High-temperature operation"
        ]
    };

    return (
        <div className="w-full overflow-hidden">
            <div className="">
                <div className="text-start mb-6">

                    <h2 className="text-2xl md:text-3xl font-bold text-primary mb-2">
                        Anatomy of Hot Saw
                    </h2>
                </div>
                <div className="flex flex-col md:flex-row gap-6">
                    <AnatomySection title="Drive System">
                        <FeatureList items={features.driveSystem} />
                    </AnatomySection>

                    <div className="hidden md:block">
                        <div className="w-px h-full bg-gray-200" />
                    </div>
                    <div className="block md:hidden">
                        <div className="w-full h-px bg-gray-200" />
                    </div>

                    <AnatomySection title="Power Transmission">
                        <FeatureList items={features.powerTransmission} />
                    </AnatomySection>

                    <div className="hidden md:block">
                        <div className="w-px h-full bg-gray-200" />
                    </div>
                    <div className="block md:hidden">
                        <div className="w-full h-px bg-gray-200" />
                    </div>

                    <AnatomySection title="Cutting Assembly">
                        <FeatureList items={features.cuttingAssembly} />
                    </AnatomySection>
                </div>
            </div>
        </div >
    );
};

export default HotSawAnatomy;