import { useState } from 'react';
import { ArrowUpRight, ChevronRight } from 'lucide-react';
import usecaseHeader from "../assets/useCasePageHero.jpg";

const ApplicationsCTA = () => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            className="h-[300px] md:h-[400px] bg-primary relative overflow-hidden group cursor-pointer"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => window.location.href = '/applications'}
        >
            {/* Animated Background Pattern */}
            <div className="absolute inset-0 opacity-20">
                <div className="absolute inset-0 bg-[linear-gradient(45deg,transparent_25%,rgba(255,255,255,0.1)_25%,rgba(255,255,255,0.1)_50%,transparent_50%,transparent_75%,rgba(255,255,255,0.1)_75%)] bg-[length:100px_100px] transform group-hover:scale-110 transition-transform duration-700"></div>
            </div>

            {/* Content Container */}
            <div className="flex flex-col md:flex-row h-full relative z-10">
                {/* Text Content */}
                <div className="flex-1 flex flex-col justify-center p-8 md:p-12">
                    <div className="transform group-hover:-translate-y-2 transition-transform duration-300">
                        <div className="flex items-center space-x-2 mb-4">
                            <span className="text-white/70 text-lg">Explore our</span>
                            <ArrowUpRight className="w-5 h-5 text-white/70" />
                        </div>
                        <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
                            Applications
                        </h2>
                        <p className="text-white/90 text-lg max-w-2xl">
                            ESA transforms plant operations by making machines more efficient, reliable, and performant.
                        </p>
                    </div>

                    {/* Interactive CTA Button */}
                    <div className="mt-8 inline-flex transform group-hover:translate-x-2 transition-transform duration-300">
                        <div className="flex items-center space-x-2 text-white border-b-2 border-white/30 pb-1 group-hover:border-white transition-colors duration-300">
                            <span className="text-lg font-medium">Discover more</span>
                            <ChevronRight className={`w-5 h-5 transform transition-transform duration-300 ${isHovered ? 'translate-x-1' : ''}`} />
                        </div>
                    </div>
                </div>

                {/* Image Container with Overlay */}
                <div className="w-full md:w-1/3 h-1/2 md:h-full relative overflow-hidden">
                    <div className="absolute inset-0 bg-gradient-to-r from-primary/50 to-transparent z-10" />
                    <img
                        src={usecaseHeader}
                        alt="Industrial machine"
                        className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-700"
                    />
                </div>
            </div>

            {/* Hover Effect Border */}
            <div className="absolute inset-x-0 bottom-0 h-1 bg-white/30 transform origin-left transition-transform duration-300 scale-x-0 group-hover:scale-x-100" />
        </div>
    );
};

export default ApplicationsCTA;