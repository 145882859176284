import React from "react";

const CaseStudiesLogo = () => {
  return (
    <div className="relative w-6 h-6 rounded-full bg-[#184384] flex justify-center items-center overflow-hidden">
      <svg
        className="w-5 h-5"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="7"
          y="6"
          width="10"
          height="14"
          stroke="white"
          strokeWidth="1.5"
        />
        <path d="M8 9H16" stroke="white" strokeWidth="1" />
        <path d="M8 12H16" stroke="white" strokeWidth="1" />
        <path d="M8 15H16" stroke="white" strokeWidth="1" />
        <circle cx="12" cy="18" r="1.5" fill="#ff5757">
          <animate
            attributeName="r"
            values="1;1.5;1"
            dur="2s"
            repeatCount="indefinite"
          />
        </circle>
        <path d="M5 4H19" stroke="white" strokeWidth="1.5">
          <animate
            attributeName="stroke-dasharray"
            values="0,14;14,0"
            dur="2s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  );
};

export default CaseStudiesLogo;
