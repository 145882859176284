import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

// Custom Card Component
const Card = ({ children, className = "" }) => (
  <div className={`bg-white rounded-lg shadow-lg overflow-hidden ${className}`}>
    {children}
  </div>
);

const CardHeader = ({ children, className = "" }) => (
  <div className={`px-6 py-4 border-b border-gray-200 ${className}`}>
    {children}
  </div>
);

const CardTitle = ({ children, className = "" }) => (
  <h3 className={`text-lg font-semibold text-gray-900 ${className}`}>
    {children}
  </h3>
);

const CardContent = ({ children, className = "" }) => (
  <div className={`px-6 py-4 ${className}`}>{children}</div>
);

const BentoGraphs = () => {
  // Status data for donut chart
  const statusData = [
    { status: "Running", value: 20, color: "#4169E1" },
    { status: "Idle", value: 5, color: "#F08080" },
    { status: "Stopped", value: 18, color: "#A9A9A9" },
    { status: "Unknown", value: 7, color: "#D3D3D3" },
  ];

  // Sample profile data
  const generateProfileData = (values) => {
    return values.map((value, index) => ({
      time: index,
      current: value,
    }));
  };

  const profile1Data = generateProfileData([
    0, 300, 300, 300, 280, 280, 540, 400, 0,
  ]);
  const profile2Data = generateProfileData([45, 45, 45, 230, 160, 180, 45, 45]);
  const profile3Data = generateProfileData([45, 45, 200, 140, 45, 55, 45, 45]);

  // Calculate percentage for donut chart
  const total = statusData.reduce((sum, item) => sum + item.value, 0);
  let cumulativePercent = 0;

  const createDonutSegments = () => {
    return statusData.map((item, index) => {
      const percent = (item.value / total) * 100;
      const startPercent = cumulativePercent;
      cumulativePercent += percent;

      return (
        <circle
          key={index}
          cx="50"
          cy="50"
          r="40"
          fill="none"
          stroke={item.color}
          strokeWidth="20"
          strokeDasharray={`${percent} ${100}`}
          strokeDashoffset={-startPercent}
          style={{ transform: "rotate(-90deg)", transformOrigin: "50px 50px" }}
        />
      );
    });
  };

  return (
    <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 bg-gray-100">
      {/* Status Overview Card */}
      <Card>
        <CardHeader>
          <CardTitle>Machine Status Overview</CardTitle>
        </CardHeader>
        <CardContent className="flex justify-center">
          <div className="relative w-48 h-48">
            <svg viewBox="0 0 100 100" className="transform -rotate-90">
              {createDonutSegments()}
            </svg>
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <div className="text-xl font-bold">{statusData[0].value}</div>
              <div className="text-sm text-gray-500">Running</div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Machine Details Card */}
      <Card>
        <CardHeader>
          <CardTitle>R112</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            <div className="text-sm text-gray-500">
              200 kW, 1485 rpm, 338 Amps
            </div>
            <div className="flex items-center space-x-2">
              <div className="h-2 w-2 rounded-full bg-green-500"></div>
              <span className="text-green-500 font-medium">Running</span>
            </div>
            <div className="grid grid-cols-3 gap-4 mt-4">
              <div>
                <div className="text-sm text-gray-500">Idle</div>
                <div className="font-medium">00:00</div>
              </div>
              <div>
                <div className="text-sm text-gray-500">Running</div>
                <div className="font-medium">31:47</div>
              </div>
              <div>
                <div className="text-sm text-gray-500">Stopped</div>
                <div className="font-medium">02:05</div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Current Profile Charts */}
      <Card className="col-span-1 md:col-span-2 lg:col-span-3">
        <CardHeader>
          <CardTitle>Current Profiles</CardTitle>
        </CardHeader>
        <CardContent className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {[
            { data: profile1Data, title: "Profile-1", cuts: 95 },
            { data: profile2Data, title: "Profile-2", cuts: 85 },
            { data: profile3Data, title: "Profile-3", cuts: 60 },
          ].map((profile, index) => (
            <div key={index} className="h-64">
              <div className="flex justify-between mb-2">
                <span className="text-blue-600 font-medium">
                  {profile.title}
                </span>
                <span className="text-red-400">{profile.cuts} cuts</span>
              </div>
              <LineChart
                width={300}
                height={200}
                data={profile.data}
                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="time"
                  label={{ value: "Time in Seconds", position: "bottom" }}
                />
                <YAxis
                  label={{
                    value: "Current (% of full Load Current)",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="current"
                  stroke="#8884d8"
                  dot={false}
                />
              </LineChart>
            </div>
          ))}
        </CardContent>
      </Card>
    </div>
  );
};

export default BentoGraphs;
