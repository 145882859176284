import React from "react";
import { Heart, Settings } from "lucide-react";

const OrchestrationEngineLogo = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          width: "100px",
          height: "100px",
          borderRadius: "50%",
          backgroundColor: "#ff5757",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div>
          <Heart
            size={48}
            color="white"
            style={{
              animation: "pulse 2s infinite",
            }}
          />
        </div>

        {[0, 1, 2].map((i) => (
          <div
            key={i}
            style={{
              position: "absolute",
              animation: `orbit${i} ${3 + i}s linear infinite`,
            }}
          >
            <Settings
              size={16}
              color="white"
              style={{
                animation: "spin 2s linear infinite",
              }}
            />
          </div>
        ))}
      </div>
      <style jsx>{`
        @keyframes pulse {
          0%,
          100% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.1);
          }
        }
        @keyframes orbit0 {
          from {
            transform: rotate(0deg) translateX(40px) rotate(0deg);
          }
          to {
            transform: rotate(360deg) translateX(40px) rotate(-360deg);
          }
        }
        @keyframes orbit1 {
          from {
            transform: rotate(120deg) translateX(40px) rotate(-120deg);
          }
          to {
            transform: rotate(480deg) translateX(40px) rotate(-480deg);
          }
        }
        @keyframes orbit2 {
          from {
            transform: rotate(240deg) translateX(40px) rotate(-240deg);
          }
          to {
            transform: rotate(600deg) translateX(40px) rotate(-600deg);
          }
        }
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
};

export default OrchestrationEngineLogo;
