import React from "react";
import { Users, MessageCircle } from "lucide-react";

const CommunicationCollaborationLogo = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          padding: "1.5rem",
          borderRadius: "50%",
          backgroundColor: "#184384",
          position: "relative",
        }}
      >
        <Users
          size={48}
          color="white"
          style={{
            animation: "bounce 1s infinite",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            padding: "0.5rem",
            borderRadius: "50%",
            backgroundColor: "#ff5757",
            animation: "ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite",
          }}
        >
          <MessageCircle size={24} color="white" />
        </div>
      </div>
      <style jsx>{`
        @keyframes bounce {
          0%,
          100% {
            transform: translateY(-10%);
          }
          50% {
            transform: translateY(0);
          }
        }
        @keyframes ping {
          75%,
          100% {
            transform: scale(2);
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default CommunicationCollaborationLogo;
