import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const testimonials = [
  {
    text: "Before minto.ai's solution, we had 83 hours of production loss over three months, resulting in approximately 57,000 lost inserts. After implementing spidersense™, minto.ai's condition monitoring platform, 90% of this downtime was eliminated. Our maintenance engineers now have better awareness of machines and failure mechanisms, with some months seeing no downtime at all.",
    author: "Sivaprasad Sanda ",
    position: "Manager Automation",
    group: "Patil Group",
    image: "/api/placeholder/48/48",
  },
  {
    text: "After installing Minto Ai's system, we quickly identified an issue in the intermediate gearbox. Their system has been helpful in diagnosing the stalling motor and the gearbox shaft problem.",
    author: "Shyam Kar",
    position: "Maintenance Engineer",
    group: "SAIL Bokaro",
    image: "/api/placeholder/48/48",
  },
  {
    text: "Kudos to the team at Minto Ai for identifying the problem accurately. Their timely detection helped us replace the mandrel assembly, preventing further damage caused by the smaller pulley key and keyway getting crushed.",
    author: "Pothuri Bala Subrahmanyam",
    position: "Deputy Divisional Head,",
    group: "TATA Steel Long Products Limited",
    image: "/api/placeholder/48/48",
  },
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000); // Change testimonial every 5 seconds

    return () => clearInterval(timer);
  }, []);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  return (
    <div className="bg-secondary-background py-8 px-4 sm:py-12 sm:px-6 lg:px-8 w-full">
      <div className="max-w-7xl mx-auto">
        <h3 className="text-xl sm:text-2xl font-semibold text-center mb-8 sm:mb-12 text-primary">
          Real results. Real impact.
          <br className="hidden sm:inline" />
          <span className="sm:hidden"> </span>
          See how our diagnostic system is transforming industries.
        </h3>
        <div className="relative bg-secondary-background p-4 sm:p-8 overflow-hidden">
          <button
            onClick={prevTestimonial}
            className="absolute left-0 sm:left-2 top-1/2 transform -translate-y-1/2 text-secondary hover:text-secondary focus:outline-none z-10"
            aria-label="Previous testimonial"
          >
            <ChevronLeft className="w-6 h-6 sm:w-8 sm:h-8" />
          </button>
          <div className="overflow-hidden">
            <div
              className="transition-transform duration-500 ease-in-out flex"
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
              }}
            >
              {testimonials.map((testimonial, index) => (
                <div key={index} className="w-full flex-shrink-0">
                  <div className="max-w-3xl mx-auto px-2 sm:px-4">
                    <p className="text-primary mb-4 sm:mb-6 text-base sm:text-xl">
                      {testimonial.text}
                    </p>
                    <div className="flex items-center">
                      <div>
                        <p className="text-xs sm:text-sm text-primary">
                          {testimonial.position}
                        </p>
                        <p className="text-base sm:text-lg font-semibold text-primary">
                          {testimonial.group}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={nextTestimonial}
            className="absolute right-0 sm:right-2 top-1/2 transform -translate-y-1/2 text-secondary hover:text-secondary focus:outline-none z-10"
            aria-label="Next testimonial"
          >
            <ChevronRight className="w-6 h-6 sm:w-8 sm:h-8" />
          </button>
        </div>
        <div className="flex justify-center mt-4 ">
          {testimonials.map((_, index) => (
            <button
              key={index}
              className={`w-2 h-2 mx-1 rounded-full ${index === currentIndex ? "bg-secondary" : "bg-gray-300"
                }`}
              onClick={() => setCurrentIndex(index)}
              aria-label={`Go to testimonial ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
