import React, { useState } from "react";
import {
  MessageSquare,
  Eye,
  Music,
  BrainCircuit,
  Code,
  Leaf,
  Coffee,
} from "lucide-react";

const MessageSection = () => {
  const messages = [
    {
      text: "We talk to machines using AI !",
      icon: MessageSquare,
      color: "bg-blue-50 hover:bg-blue-100",
      iconColor: "text-blue-600",
    },
    {
      text: "We savour coffee",
      icon: Coffee,
      color: "bg-orange-50 hover:bg-orange-100",
      iconColor: "text-orange-600",
    },
    {
      text: "We dance with machines",
      icon: Music,
      color: "bg-blue-100 hover:bg-blue-200",
      iconColor: "text-blue-700",
    },
    {
      text: "We build machine learning as a spider build's its web",
      icon: BrainCircuit,
      color: "bg-orange-100 hover:bg-orange-200",
      iconColor: "text-orange-700",
    },
    {
      text: "We love nature",
      icon: Leaf,
      color: "bg-blue-200 hover:bg-blue-300",
      iconColor: "text-blue-800",
    },
    {
      text: "We craft software- lean but also robust, elegant but also functional",
      icon: Code,
      color: "bg-orange-200 hover:bg-orange-300",
      iconColor: "text-orange-800",
    },
    {
      text: "We see what machine's consume and produce !",
      icon: Eye,
      color: "bg-blue-300 hover:bg-blue-400",
      iconColor: "text-blue-900",
    },
  ];

  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className="w-full max-w-6xl mx-auto p-4 md:p-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
        {messages.map((message, index) => {
          const Icon = message.icon;
          return (
            <div
              key={index}
              className={`
                ${message.color}
                px-4 py-3
                rounded-xl
                
                transform transition-all duration-300
                cursor-pointer
                ${hoveredIndex === index
                  ? "scale-105 -rotate-1"
                  : "scale-100 rotate-0"
                }
                shadow-sm hover:shadow-lg
                flex items-center gap-3
                ${index === messages.length - 1
                  ? "sm:col-span-2 lg:col-span-1"
                  : ""
                }
                ${index === messages.length - 2 ? "lg:col-span-2" : ""}
                min-h-[60px]
              `}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div
                className={`
                  ${message.iconColor}
                  transform transition-transform duration-300
                  flex-shrink-0
                  ${hoveredIndex === index ? "scale-110" : "scale-100"}
                `}
              >
                <Icon className="w-5 h-5" />
              </div>
              <p
                className={`
                  text-sm
                  text-gray-700 font-medium
                  transition-all duration-300
                  leading-tight
                  ${hoveredIndex === index ? "transform translate-x-1" : ""}
                `}
              >
                {message.text}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MessageSection;
