import React from "react";

const UseCaseType = () => {
  const useCases = [
    "Predictive Maintenance",
    "Condition Monitoring",
    "Reliability Engineering",
    "Root Cause Analysis / FMEA",
    "Digital Transformation",
  ];

  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-12 md:py-16">
      {/* Title Section */}
      <div className="mb-12">
        <h2 className="text-3xl md:text-4xl font-bold text-[#14387F] mb-4">
          Use-Case Type
        </h2>
        <div className="w-24 h-1 bg-secondary rounded-full mb-8"></div>

        {/* Description paragraphs with improved typography and spacing */}
        <div className="space-y-6 text-primary text-base leading-tight">
          <p>
            Clients approached us with the following use cases, and we
            collaborated to refine their understanding, carefully defining each
            use case to evaluate the potential application of iHz™.
          </p>
          <p>
            A customer approached us to reduce unplanned breakdowns in their
            plant, where we identified that the core issue at the execution
            level was the need for real-time condition monitoring of subsystems
            for half of the machines in the plant, along with alerting the
            maintenance team. After deploying iHz™ the plant has seen instances
            where there is not even a single downtime continuously for 3 months.
          </p>
          <p>
            A large steel manufacturing company engaged us to trial our product
            as part of their digital transformation efforts. We implemented iHz™
            after reframing the challenge as early and precise fault detection
            in the drive train to allow for planned maintenance. Within three
            months of deployment, we received written testimonials from the
            client on the technology's effectiveness.
          </p>
          <p>
            If you're considering similar challenges, we're here to partner with
            you. We'll help you refine the problem and deliver a measurable
            impact you can see firsthand.
          </p>
        </div>
      </div>

      {/* Simple grid of use cases */}
      <div className="max-w-3xl">
        <ul className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {useCases.map((useCase, index) => (
            <li
              key={index}
              className="text-primary text-base p-2 flex items-start"
            >
              <span className="mr-2">•</span>
              {useCase}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UseCaseType;
