import React from "react";

export default function SeamLessCollab() {
  const iframeStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };
  return (
    <iframe
      className="w-full h-full"
      src="https://app.supademo.com/embed/cm2ue0kz41jihesgv2uy3fm4z?embed_v=2"
      loading="lazy"
      title="spiderAI™"
      allow="clipboard-write"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      style={iframeStyle}
    />
  );
}
