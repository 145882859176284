import React, { useState } from "react";
import { Plus, Minus } from "lucide-react";
import { AnimatedListDemo } from "./Notifications";
import { DiagnosticAlgo } from "./DiagnosticAlgo";
import RiskScoreScatterPlot from "./RiskScoreScatterPlot";

const Intelligence = () => {
  const [expandedSection, setExpandedSection] = useState("anomalyDetection");

  const sections = {
    anomalyDetection: {
      title: "Anomaly Detection",
      description:
        "spiderAI™ uses advanced AI models to spot unusual patterns. It learns from your feedback, constantly improving its ability to find and prioritize anomalies and never takes a break.",
      content: <RiskScoreScatterPlot />,
    },
    notification: {
      title: "LLM Driven Notification Engine ",
      description:
        "Notifications that speak your language. Set up alert rules naturally, define what matters most, and let spiderAI™ handle the rest. Context-aware intelligence ensures you get precise, meaningful alerts tailored to your needs.",
      content: <AnimatedListDemo />,
    },
    DiagnosticAlgo: {
      title: "Diagnostic Algorithms",
      description:
        "spiderAI™ uses advanced physics and detective-like reasoning to solve complex machine issues. It learns from past cases to give maintenance teams clear, actionable insights, boosting reliability and efficiency.",
      content: <DiagnosticAlgo />,
    },
  };

  const handleSectionClick = (key) => {
    setExpandedSection(key);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <div className="max-w-6xl mx-auto p-4 lg:p-6">
        <div className="text-center mb-8 lg:mb-12">
          <div className="text-secondary font-semibold mb-4">INTELLIGENCE</div>
          <h1 className="text-2xl lg:text-3xl text-primary font-bold text-navy-900 max-w-3xl mx-auto">
            AI-Powered Intelligence for Industrial Operations
          </h1>
        </div>

        <div className="bg-white rounded-2xl shadow-lg p-4 lg:p-8 mb-8">
          <div className="flex flex-col lg:flex-row gap-4 lg:gap-8">
            <div className="order-2 lg:order-1 lg:w-2/3">
              <div className="rounded-lg">
                <div className="w-full">
                  {sections[expandedSection].content}
                </div>
              </div>
            </div>

            <div className="order-1 lg:order-2 lg:w-1/3">
              {Object.entries(sections).map(([key, section]) => (
                <div
                  key={key}
                  className="border-b border-gray-200 last:border-b-0"
                >
                  <div className="py-4">
                    <button
                      className={`w-full flex items-center justify-between text-left transition-colors duration-200 ease-in-out ${
                        expandedSection === key
                          ? "text-primary"
                          : "text-gray-700 hover:text-primary"
                      }`}
                      onClick={() => handleSectionClick(key)}
                    >
                      <span className="font-semibold text-sm lg:text-base">
                        {section.title}
                      </span>
                      <span className="transform transition-transform duration-200 flex-shrink-0 ml-2">
                        {expandedSection === key ? (
                          <Minus className="w-4 h-4 lg:w-5 lg:h-5" />
                        ) : (
                          <Plus className="w-4 h-4 lg:w-5 lg:h-5" />
                        )}
                      </span>
                    </button>
                    <div
                      className={`transition-all duration-300 ease-in-out ${
                        expandedSection === key
                          ? "max-h-40 opacity-100 mt-2"
                          : "max-h-0 opacity-0"
                      }`}
                    >
                      {section.description && (
                        <div className="text-xs lg:text-sm text-primary">
                          {section.description}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intelligence;
