// Navigation.js
import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, X, ChevronRight, ChevronDown } from "lucide-react";
import ContactUs from "./ContactUs";
import logo from "../assets/logo.png";

import "./styles/Navigation.css";
import Getdemo from "./getdemo";
import { FileText } from "lucide-react";
import AboutUsLogo from "./CustomLogo/Navbar/AboutUsLogo";
import BlogsLogo from "./CustomLogo/Navbar/BlogLogo";
import CareersLogo from "./CustomLogo/Navbar/CareersLogo";
import ContactUsLogo from "./CustomLogo/Navbar/ContactusLogo";
import CaseStudiesLogo from "./CustomLogo/Navbar/CaseStudiesLogo";
import Embarace from "../assets/blog-images/embrace-evolution/image1-evol.webp";

const Navigation = () => {
  const GetDemoWrapper = () => {
    return (
      <div
        onClick={() => {
          closeDropdown();
          setIsMobileMenuOpen(false);
        }}
      >
        <Getdemo />
      </div>
    );
  };

  const [activeItem, setActiveItem] = useState(null);
  const [activeSubItem, setActiveSubItem] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Updated navItems with direct links
  const navItems = [
    { name: "Products", type: "dropdown" },
    { name: "Applications", type: "link", link: "/applications" },
    { name: "Case Studies", type: "link", link: "/usecases" },
    { name: "Resources", type: "dropdown" },
    { name: "Company", type: "dropdown" },
  ];

  const dropdownRef = useRef(null);
  const navRef = useRef(null);
  const navigate = useNavigate();

  const underlineClass =
    "before:content-[''] before:absolute before:bottom-0 before:left-0 before:w-full before:h-0.5 before:bg-secondary before:origin-left before:transition-transform before:duration-300 before:transform";

  // Dropdown content structure
  const DropdownContent = {
    Products: [
      [
        {
          name: "iHz™ - Total Equipment Intelligence",
          link: "/ihz",
          tag: "",
          section: "Industrial IoT Systems",
        },
        {
          name: "spiderAI™ - Asset Intelligence Platform",
          link: "/spiderai",
          tag: "",
          section: "Software",
        },
      ],
    ],
    Resources: [
      [
        {
          name: "Blogs",
          link: "/blog",
          Logo: BlogsLogo,
          description: "Insightful articles on MCSA",
        },
      ],
      [
        {
          name: "Use Cases",
          link: "/comingsoon",
          Logo: CaseStudiesLogo,
          description: "Success stories from our clients",
        },
      ],
    ],
    Company: [
      [
        {
          name: "About us",
          link: "/about",
          description: "What is minto.ai?",
          Logo: AboutUsLogo,
        },
        {
          name: "Careers",
          link: "/careers",
          description: "Find out what it's like to work at minto.ai",
          isNew: true,
          Logo: CareersLogo,
        },
        {
          name: "Contact",
          link: "/contactus",
          description:
            "Reach out if you need help, are interested in partnership, or just have a general enquiry",
          Logo: ContactUsLogo,
        },
      ],
    ],
  };

  const popularResources = [
    {
      title: "Embrace evolution with spiderAI™",
      image: Embarace,
      link: "/blog/embracing-evolution-with-spider-ai",
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        navRef.current &&
        !navRef.current.contains(event.target) &&
        ((dropdownRef.current && !dropdownRef.current.contains(event.target)) ||
          isMobileMenuOpen)
      ) {
        setActiveDropdown(null);
        setIsMobileMenuOpen(false);
        setActiveItem(null);
        setActiveSubItem(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  const handleMouseEnter = (index) => {
    const item = navItems[index];
    if (item.type === "dropdown") {
      setActiveDropdown(index);
    } else {
      setActiveDropdown(null);
    }
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeDropdown = () => {
    setActiveDropdown(null);
    setIsMobileMenuOpen(false);
  };

  const handleItemClick = (item, index) => {
    if (item.type === "link") {
      navigate(item.link);
      closeDropdown();
      setIsMobileMenuOpen(false);
    } else {
      handleMouseEnter(index);
    }
  };

  const LinkWrapper = ({ to, children, className, onClick }) => (
    <Link
      to={to}
      className={className}
      onClick={(e) => {
        if (onClick) onClick(e);
        closeDropdown();
        setIsMobileMenuOpen(false);
        setActiveItem(null);
        setActiveSubItem(null);
      }}
    >
      {children}
    </Link>
  );

  const renderDropdownContent = (itemName) => {
    if (itemName === "Products") {
      return (
        <div className="w-full">
          <div className="flex">
            <div className="w-1/3 pr-8">
              <h2 className="text-xl font-bold text-primary mb-4">
                Explore Our Products
              </h2>
              <p className="text-primary mb-4 text-sm">
                Discover how our products can streamline your workflow and boost
                productivity.
              </p>
            </div>
            <div className="w-2/3 grid grid-cols-2 gap-8">
              {/* Systems Section */}
              <div>
                <h3 className="font-normal text-sm  text-primary mb-4">Industrial IoT Systems</h3>
                <ul className="space-y-4">
                  {DropdownContent.Products[0]
                    .filter((item) => item.section === "Industrial IoT Systems")
                    .map((product, index) => (
                      <li key={index} className="text-primary cursor-pointer">
                        <LinkWrapper to={product.link} className="inline-block">
                          <div className="flex flex-col">
                            <div className="inline-block">
                              <span className="font-semibold custom-menu-item">
                                {product.name}
                              </span>
                              {product.isPillBadge ? (
                                <span className="ml-2 inline-flex items-center rounded-full bg-[#FFF5F2] px-2 py-0.5 text-xs font-medium text-primary">
                                  Coming Soon
                                </span>
                              ) : (
                                product.tag && (
                                  <span className="text-sm text-gray-600 font-normal mt-1">
                                    {product.tag}
                                  </span>
                                )
                              )}
                            </div>
                          </div>
                        </LinkWrapper>
                      </li>
                    ))}
                </ul>
              </div>
              {/* Solutions Section */}
              <div>
                <h3 className="font-normal text-sm text-primary mb-4">Software</h3>
                <ul className="space-y-4">
                  {DropdownContent.Products[0]
                    .filter((item) => item.section === "Software")
                    .map((product, index) => (
                      <li key={index} className="text-primary cursor-pointer">
                        <LinkWrapper to={product.link} className="inline-block">
                          <div className="flex flex-col">
                            <div className="inline-block">
                              <span className="font-semibold custom-menu-item">
                                {product.name}
                              </span>
                              {product.tag && (
                                <span className="text-sm text-gray-600 font-normal mt-1">
                                  {product.tag}
                                </span>
                              )}
                            </div>
                          </div>
                        </LinkWrapper>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (itemName === "Resources") {
      return (
        <div className="w-full px-6 py-8 flex flex-col lg:flex-row bg-white">
          {/* Main content section */}
          <div className="w-full lg:w-2/3 lg:pr-12">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-8">
              <div>
                <h2 className="text-2xl font-bold text-primary mb-6">
                  Resources
                </h2>
                <ul className="space-y-6">
                  {DropdownContent.Resources[0].map((subItem, subIndex) => (
                    <li key={subIndex} className="flex items-start">
                      <div>
                        <LinkWrapper
                          to={subItem.link}
                          className="text-primary font-semibold"
                        >
                          <div className="cursor-pointer flex">
                            <span className="custom-menu-item transition-colors duration-300 ease-in-out">
                              {subItem.name}
                            </span>
                          </div>
                        </LinkWrapper>
                        <p className="text-sm text-primary mt-1">
                          {subItem.description}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h2 className="text-2xl font-bold text-white mb-6">.</h2>
                <ul className="space-y-6">
                  {DropdownContent.Resources[1].map((subItem, subIndex) => (
                    <li key={subIndex} className="flex items-start">
                      <div>
                        <LinkWrapper
                          to={subItem.link}
                          className="text-primary font-semibold"
                        >
                          <div className="cursor-pointer flex">
                            <span className="custom-menu-item transition-colors duration-300 ease-in-out">
                              {subItem.name}
                            </span>
                          </div>
                        </LinkWrapper>
                        <p className="text-sm text-primary mt-1">
                          {subItem.description}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Most popular section */}
          <div className="lg:w-1/3 mt-8 lg:mt-0 hidden sm:block md:hidden lg:block">
            <h2 className="text-2xl font-bold text-primary mb-6">
              Most popular
            </h2>
            <div className="space-y-6 bg-[#FFF5F2] p-6 rounded-lg">
              {popularResources.map((resource, index) => (
                <LinkWrapper key={index} to={resource.link} className="block">
                  <div className="bg-white rounded-lg overflow-hidden shadow-sm">
                    {resource.image ? (
                      <img
                        src={resource.image}
                        alt={resource.title}
                        className="w-full h-32 object-cover"
                      />
                    ) : (
                      <div className="w-full h-32 bg-gray-200 flex items-center justify-center">
                        <FileText className="text-gray-400" size={48} />
                      </div>
                    )}
                    <div className="p-4">
                      <h3 className="font-semibold text-primary">
                        {resource.title}
                      </h3>
                      <p className="text-sm text-primary mt-2">
                        {resource.description}
                      </p>
                      <p className="text-sm text-secondary mt-2 flex items-center hover:underline">
                        Learn more <ChevronRight size={16} className="ml-1" />
                      </p>
                    </div>
                  </div>
                </LinkWrapper>
              ))}
              <LinkWrapper
                to="/blog"
                className="block text-secondary hover:underline flex items-center"
              >
                View all resources <ChevronRight size={16} className="ml-1" />
              </LinkWrapper>
            </div>
          </div>
        </div>
      );
    }

    if (itemName === "Company") {
      return (
        <div className="w-full px-4 py-6">
          <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {DropdownContent.Company[0].map((subItem, subIndex) => (
              <li key={subIndex} className="flex items-start">
                <div>
                  <LinkWrapper
                    to={subItem.link}
                    className="text-primary font-semibold"
                  >
                    <div className="cursor-pointer flex">
                      <span className="custom-menu-item transition-colors duration-300 ease-in-out">
                        {subItem.name}
                      </span>
                    </div>
                  </LinkWrapper>
                  <p className="text-sm text-primary mt-1">
                    {subItem.description}
                  </p>
                </div>
              </li>
            ))}
          </ul>
          <div className="mt-8 text-center">
            <LinkWrapper to="./contactus">
              <p className="text-secondary font-medium hover:underline cursor-pointer">
                Looking for a new career? Get in touch
              </p>
            </LinkWrapper>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <nav
      className="bg-white fixed z-50 top-0 left-0 w-full shadow-md transition-shadow duration-300"
      ref={navRef}
      onMouseLeave={handleMouseLeave}
    >
      <div className="w-full px-4 sm:px-6 lg:px-[5vw] border-b border-gray-200">
        <div className="max-w-[1440px] mx-auto">
          <div className="flex items-center justify-between h-16 md:h-20">
            {/* Logo */}
            <div className="flex-shrink-0 w-32 sm:w-40 md:w-48 lg:min-w-[180px] mr-4 sm:mr-6 lg:mr-8">
              <LinkWrapper to="/">
                <img
                  src={logo}
                  alt="Minto.ai logo"
                  className="h-auto w-full max-w-[180px] object-contain"
                />
              </LinkWrapper>
            </div>

            {/* Navigation Menu */}
            <div className="hidden landscape:md:flex portrait:hidden items-center flex-grow justify-center">
              <ul className="flex space-x-2 md:space-x-4 lg:space-x-12">
                {navItems.map((item, index) => (
                  <li
                    key={index}
                    className="text-primary text-sm lg:text-base font-semibold cursor-pointer custom-menu-item flex items-center whitespace-nowrap"
                    onMouseEnter={() => handleMouseEnter(index)}
                    onClick={() => handleItemClick(item, index)}
                  >
                    {item.type === "link" ? (
                      <Link
                        to={item.link}
                        className="custom-menu-item px-2 py-1"
                        onMouseEnter={() => setActiveDropdown(null)}
                      >
                        {item.name}
                      </Link>
                    ) : (
                      <div className="flex items-center gap-1 px-2 py-1 group">
                        <span>{item.name}</span>
                        <ChevronDown
                          size={16}
                          className={`transition-transform duration-200 ${activeDropdown === index ? 'rotate-180' : ''
                            }`}
                        />
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>

            {/* Demo Button */}
            <div className="hidden landscape:md:block portrait:hidden flex-shrink-0 ml-4 lg:ml-8">
              <GetDemoWrapper />
            </div>

            {/* Mobile Menu Button */}
            <div className="landscape:md:hidden">
              <button onClick={toggleMobileMenu} className="text-primary p-2">
                {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Dropdown Menus */}
      <div
        ref={dropdownRef}
        className={`absolute left-0 w-full bg-white shadow-lg overflow-hidden transition-all duration-300 ease-in-out ${activeDropdown !== null && navItems[activeDropdown]?.type === "dropdown"
          ? "opacity-100 border-b border-gray-200 max-h-[500px]"
          : "opacity-0 max-h-0"
          } hidden landscape:md:block`}
      >
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6 lg:py-8">
          {navItems.map(
            (item, index) =>
              item.type === "dropdown" && (
                <div
                  key={index}
                  className={`transition-opacity duration-300 ${activeDropdown === index ? "opacity-100" : "opacity-0 hidden"
                    }`}
                >
                  {renderDropdownContent(item.name)}
                </div>
              )
          )}
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`landscape:md:hidden bg-white w-full transition-all duration-300 ease-in-out ${isMobileMenuOpen
          ? "max-h-screen opacity-100 shadow-lg border-b border-gray-200"
          : "max-h-0 opacity-0"
          } overflow-hidden`}
      >
        <div className="container mx-auto px-4 py-4">
          <ul className="space-y-4">
            {navItems.map((item, index) => (
              <li key={index} className="text-primary font-semibold">
                {item.type === "link" ? (
                  <LinkWrapper
                    to={item.link}
                    className="relative w-full text-left py-2 block"
                  >
                    <span className="custom-menu-item inline-block">
                      {item.name}
                    </span>
                  </LinkWrapper>
                ) : (
                  <>
                    <button
                      onClick={() =>
                        setActiveItem(activeItem === item.name ? null : item.name)
                      }
                      className="relative w-full text-left py-2 flex items-center justify-between"
                    >
                      <span className="custom-menu-item inline-block">
                        {item.name}
                      </span>
                      <ChevronDown
                        size={16}
                        className={`transition-transform duration-200 ${activeItem === item.name ? 'rotate-180' : ''
                          }`}
                      />
                    </button>
                    {item.type === "dropdown" && (
                      <ul
                        className={`ml-4 mt-2 space-y-2 transition-all duration-300 ${activeItem === item.name ? "block" : "hidden"
                          }`}
                      >
                        {DropdownContent[item.name]
                          .flat()
                          .map((subItem, subIndex) => (
                            <li
                              key={subIndex}
                              className="text-primary cursor-pointer"
                            >
                              <LinkWrapper
                                to={subItem.link}
                                className="relative py-1"
                              >
                                <span className="custom-menu-item inline-block">
                                  {subItem.name}
                                </span>
                              </LinkWrapper>
                            </li>
                          ))}
                      </ul>
                    )}
                  </>
                )}
              </li>
            ))}
          </ul>
          <div className="mt-6">
            <GetDemoWrapper />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;