import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import embrace from "../../assets/blog-images/embrace-evolution/image1-evol.webp";
import bokaro from "../../assets/blog-images/bokaro/bokaro.jpg";
import FeaturesofaI from "../../assets/blog-images/features-of-ai/unplanned_dt.jpg";
import MintoRecognisedas from "../../assets/blog-images/minto-recognised-as/PHOTO-2019-11-28-12-45-32-2-1-1024x576-1.jpg";
import ProFoundimpact from "../../assets/blog-images/profound-impact/profound_impact.jpg";
import unveilingThe from "../../assets/blog-images/unveiling-the/unveiling.jpg";

import { Twitter, Linkedin, Mail, Link, Clock } from "lucide-react";

import image1 from "../../assets/blog-images/profound-impact/tumblr_nwi2zenjUT1u3mnzlo1_1280-1024x576-1.png";

const ProFoundImpact = () => {
  const blogPostsData = [
    {
      author: "spiders",
      categories: ["Product"],
      date: "2019-02-21T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title:
        "Features of an AI-Driven IoT Product That Solves the Problem of Unplanned Downtime",
      description:
        "In our previous articles, we've discussed the significant financial losses manufacturing companies and asset owners face due to unplanned downtime and why existing maintenance methods fall short. In this final article of the series, we will explore the features of an AI-driven IoT product that can dramatically reduce downtime and extend the lifespan of machines, providing a compelling investment opportunity for asset owners.",
      sortOrder: 5,
      featuredImage: FeaturesofaI,
      route: "/blog/features-of-ai-driven-iot-product",
    },
    {
      author: "spiders",
      categories: ["Value"],
      date: "2019-09-03T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title: "The Profound Impact of minto.ai™ Beyond Monetary Benefits",
      description:
        "In my journey with minto.ai™, I often pondered whether our product truly makes a difference in the world and brings meaningful change to people's lives. Beyond the financial gains, are we adding significant value to individuals and organizations?",
      sortOrder: 4,
      featuredImage: ProFoundimpact,
      route: "/blog/profound-impact-of-minto-ai",
    },
    {
      author: "spiders",
      categories: ["Awards"],
      date: "2019-06-12T00:00:00.000Z",
      featured: false,
      image: "../../assets/favicon.ico",
      title:
        "minto.ai™ recognized as top 2 innovative startups in the country by CK Birla group",
      description:
        "minto.ai is recognized by Birlasoft as one of the top two startups and runner-up across the country in the grand Finale happened at Birlasoft Xccelerate4startups event in Noida.",
      sortOrder: 3,
      featuredImage: MintoRecognisedas,
      route: "/blog/minto-ai-recognized-as-top-2-innovative-startups",
    },
    {
      author: "spiders",
      categories: ["Customers"],
      date: "2021-11-24T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title:
        "Bokaro Steel Plant(SAIL) partners with minto.ai™ to begin trials of AI-based Predictive Monitoring system",
      description:
        "We at minto.ai™ started the deployment of its Industrial IoT system connected to its platform **spidersense™** in steel plants. With the ongoing and future deployments in Steel Plants, we have entered an important segment where we believe there is a need to add value with our Industrial IoT platform called **spidersense™.**",
      sortOrder: 2,
      featuredImage: bokaro,
      route: "/blog/bokaro-steel-plant-partners-with-minto-ai",
    },
    {
      author: "Raviteja Valluri",
      categories: ["Product"],
      date: "2024-05-18T00:00:00.000Z",
      featured: false,
      image: embrace,
      title:
        "Embracing Evolution From Domesticators to Innovators with spiderAI™",
      description:
        "Humanity's journey from hunter-gatherers to technological pioneers is a testament to our species' ingenuity and resilience. Our relationship with tools are very old that in 2011, researchers",
      sortOrder: 1,
      featuredImage: embrace,
      route: "/blog/embracing-evolution-with-spider-ai",
    },
    {
      author: "spiders",
      categories: ["Problem"],
      date: "2019-02-06T00:00:00.000Z",
      featured: false,
      title: "Unveiling the Inefficiencies in Traditional Maintenance Methods",
      description:
        "As we delve further into the complexities of machine failures and disruptions in manufacturing facilities, it's evident that even the best maintenance strategies—such as RCM, FEMA, RBI, Failure Elimination, and Root Cause Analysis—fall short in",
      sortOrder: 6,
      image: "../../assets/people/ape-ravi.jpeg",
      featuredImage: unveilingThe,
      route:
        "/blog/unveiling-inefficiencies-in-traditional-maintenance-methods",
    },
  ];

  const navigate = useNavigate();
  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    const shuffled = [...blogPostsData].sort(() => 0.5 - Math.random());
    setRelatedArticles(shuffled.slice(0, 3));
  }, []);

  const handleArticleClick = (route) => {
    navigate(route);
  };

  return (
    <div className="pt-20 bg-white">
      <div className="max-w-3xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-primary  mb-6">
          The Profound Impact of minto.ai™: Beyond Monetary Benefits
        </h1>

        <div className="flex items-center text-primary mb-6 space-x-4">
          <span>September 3, 2019</span>
        </div>

        <div className="text-primary mb-8">
          Topic: <span className="text-secondary">Value</span>
        </div>

        <img
          src={image1}
          alt="The Impact of minto.ai™"
          className="w-full h-64 object-cover mb-6"
        />

        <p className="mb-8 text-primary ">
          In my journey with minto.ai™, I often pondered whether our product
          truly makes a difference in the world and brings meaningful change to
          people's lives. Beyond the financial gains, are we adding significant
          value to individuals and organizations?
        </p>

        <p className="mb-8 text-primary ">
          Life is fleeting, and I am committed to ensuring that what we are
          building at minto.ai has a profound impact. Creating a product that
          genuinely matters to people and organisations is challenging if it
          focuses solely on monetary benefits.
        </p>

        <p className="mb-8 text-primary ">
          Recently, I stumbled upon an article that offered a fresh perspective
          on building a product that goes beyond financial advantages. It
          highlighted the importance of creating something that brings
          efficiency, enhances life experiences, and adds substantial value.
        </p>

        <p className="mb-8 text-primary ">
          The article discussed a seminal paper, "MAN ON A BICYCLE," published
          in Scientific American in 1973.
        </p>

        <h2 className="text-2xl font-bold text-primary  mb-6">
          A Study on Efficiency: Human vs. Machine
        </h2>

        <p className="mb-6 text-primary ">
          The article states, "In March 1973, S.S. Wilson published an 11-page
          article in Scientific American, highlighting the results of a study
          comparing the efficiency of locomotion across various animals.
          Different species, from snakes to great cats to birds, were ranked by
          the energy they spend to move 1 kilogram of their mass 1 kilometre.
          While humans performed well, they were not the best: an unaided
          walking man consumes about 0.75 calories per gram per kilometre,
          lagging behind a horse or a salmon. The condor topped the list,
          utilizing its large wingspan and air currents to move with
          unparalleled efficiency.
        </p>

        <p className="mb-6 text-primary ">
          The study did not stop there. Wilson also included various machines in
          the comparison and discovered that 'with the aid of a bicycle, a man's
          energy consumption for a given distance is reduced to about a fifth
          (roughly 0.15 calories per gram per kilometre).' Remarkably, this
          significant extension of human capabilities comes with no
          environmental cost and minimal additional mass. From an energy
          efficiency standpoint, a man on a bicycle surpasses a man in a car or
          a jet plane."
        </p>

        <h2 className="text-2xl font-bold text-primary  mb-6">
          The Humble Bicycle: A Revolutionary Impact
        </h2>

        <p className="mb-6 text-primary ">
          It is intriguing why such a simple device as the bicycle has
          profoundly accelerated technology. The answer lies in its sheer
          humanity. The bicycle's purpose is to make individual movement easier,
          achieving this in a way that surpasses natural evolution.
        </p>

        <p className="mb-6 text-primary ">
          Apart from increasing unaided speed by a factor of three or four, the
          cyclist improves their efficiency rating to No. 1 among moving
          creatures and machines. Beyond efficiency, the bicycle grants the
          rider more time, enabling them to increase their value in other
          pursuits.
        </p>

        <h2 className="text-2xl font-bold text-primary  mb-6">
          Inspiring minto.ai™'s Product Development
        </h2>

        <p className="mb-6 text-primary ">
          This article inspired me to plan our product development activities at
          minto.ai™ with a focus on humanity. We aim to create a product that is
          not just financially beneficial but significantly valuable to people's
          lives.
        </p>

        <p className="mb-6 text-primary ">
          Our criteria for making minto.ai™'s product highly valuable are:
        </p>

        <h3 className="text-xl font-bold text-primary  mb-4">
          1. Improving Efficiency by At Least Four Times:
        </h3>

        <p className="mb-6 text-primary ">
          Similar to how a bicycle enhances human efficiency, our goal is for
          minto.ai™ to dramatically improve productivity. For example, if users
          spend 4 hours monitoring machines, they should spend just 1 hour with
          the help of minto.ai™'s intelligent tool.
        </p>

        <h3 className="text-xl font-bold text-primary  mb-4">
          2. Saving Time for More Valuable Work:
        </h3>

        <p className="mb-6 text-primary ">
          Like a bicycle saving time and inspiring humans to do more, our tool
          should enable users to focus on more critical tasks. By eliminating
          repetitive and mundane activities, users can establish better systems
          and understand their machines more deeply, making them more valuable
          professionals.
        </p>

        <p className="mb-6 text-primary ">
          minto.ai™'s tool should provide real-time operational parameters of
          machines, their running, and health status, allowing users to avoid
          unnecessary factory time anticipating failures. Instead, they can
          deeply understand machine behaviour to enhance efficiency, plan for
          spare parts, and schedule maintenance jobs effectively.
        </p>

        <h2 className="text-2xl font-bold text-primary  mb-6">Conclusion</h2>

        <p className="mb-6 text-primary ">
          The "Man on a Bicycle" inspired us to build a product that improves
          efficiency and helps users become more valuable professionals. When
          our product achieves this, it truly matters.
        </p>

        <p className="mb-6 text-primary ">
          <strong>Does your product matter to the users?</strong>
        </p>

        <p className="mb-6 text-primary ">
          We'd love to hear your thoughts. Please share this article with your
          colleagues in the industry and let's start a conversation about
          creating products that make a real difference.
        </p>
      </div>

      <div className="px-4 sm:px-8 md:px-16 lg:px-24 pb-6 sm:pb-8 md:pb-10">
        <div className="mt-8 sm:mt-12 md:mt-16 px-4 sm:px-8 md:px-16 lg:px-24">
          <h2 className="text-xl sm:text-2xl font-bold text-secondary mb-6 sm:mb-8">
            Related Articles
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
            {relatedArticles.map((article, index) => (
              <div
                key={index}
                className="flex flex-col cursor-pointer"
                onClick={() => handleArticleClick(article.route)}
              >
                <img
                  src={article.featuredImage}
                  alt={article.title}
                  className="w-full h-48 object-cover mb-4"
                />
                <div className="text-xs sm:text-sm font-semibold text-primary mb-2">
                  {article.categories[0]}
                </div>
                <h3 className="text-base sm:text-lg font-bold text-primary mb-2">
                  {article.title}
                </h3>
                <div className="mt-auto">
                  <div className="text-xs sm:text-sm text-primary mt-1 flex">
                    <span>Topic: </span>
                    <div className="text-secondary ml-1">
                      {article.categories.join(", ")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProFoundImpact;
