import React from "react";
import { MessageSquare, Brain } from "lucide-react";

const ConversationalAILogo = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="relative w-24 h-24 bg-primary rounded-full flex justify-center items-center">
        <div>
          <MessageSquare size={48} color="white" className="animate-pulse" />
        </div>

        <div className="absolute bottom-2 right-2 bg-secondary rounded-full p-1">
          <Brain size={24} color="white" className="animate-spin" />
        </div>
      </div>
      <style jsx>{`
        @keyframes pulse {
          0%,
          100% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.1);
          }
        }
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
        .animate-pulse {
          animation: pulse 2s infinite;
        }
        .animate-spin {
          animation: spin 4s linear infinite;
        }
      `}</style>
    </div>
  );
};

export default ConversationalAILogo;
