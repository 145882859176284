import React, { useState } from "react";
import { Plus, Minus } from "lucide-react";
import deployment from "../../assets/deployment.jpg";
import ApplicationGrid from "../ApplicationGrid";

import Kallakal from "../Projects/Kallakal";
import kallakal from "../../assets/foundery_kalakal_website_display.jpg";
import Bokaro from "../Projects/Bokaro";
import HIL from "../Projects/HIL";

import hil from "../../assets/clients/HIL.jpg";
import tata from "../../assets/clients/tataSteel.jpg";
import bokaro from "../../assets/clients/bokaro.jpg";
import sail from "../../assets/clients/sail.jpg";
import SailBilai from "../Projects/SailBilai";
import SailBokaro from "../Projects/SailBokaro";

const cardData1 = [
  {
    title: "Blooming Mill",
    location: "",
    imageSrc: tata,
    children: <Bokaro />,
  },
  {
    title: "Sail Bhilai",
    location: "",
    imageSrc: sail,
    children: <SailBilai />,
  },
];

const cardData2 = [
  {
    title: "Sail Bokaro",
    location: "",
    imageSrc: bokaro,
    children: <SailBokaro />,
  },
  {
    title: "Blooming Mill",
    location: "",
    imageSrc: tata,
    children: <Bokaro />,
  },
  {
    title: "Kallakal",
    location: "",
    imageSrc: kallakal,
    children: <Kallakal />,
  },
];

const cardData3 = [
  {
    title: "Blooming Mill",
    location: "",
    imageSrc: tata,
    children: <Bokaro />,
  },
  {
    title: "Sail Bokaro",
    location: "",
    imageSrc: bokaro,
    children: <SailBokaro />,
  },
];

const cardData4 = [
  {
    title: "Blooming Mill",
    location: "",
    imageSrc: tata,
    children: <Bokaro />,
  },
  {
    title: "HIL",
    location: "",
    imageSrc: hil,
    children: <HIL />,
  },
];

const TeamsAccordon = () => {
  // Changed initial state to match first section key
  const [expandedSection, setExpandedSection] = useState("operations");

  const sections = {
    operations: {
      title: "Operation ",
      description:
        "iHz™ equips operations teams with machine diagnostics to execute production targets effectively. By detecting issues weeks to months in advance, they can confidently run equipment without fear of sudden failures. During shifts, real-time insights about equipment condition help them optimize load distribution and prevent overloading. This predictability helps maintain production schedules even for equipment in harsh, inaccessible, or hazardous environments.",
      content: (
        <ApplicationGrid
          cards={cardData1}
          title="Featured Projects"
          heroImage=""
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    maintenace: {
      title: "Maintenance",
      description:
        "iHz™ transforms maintenance from reactive to time-wasting routines into focused, condition-based interventions. Teams receive specific alerts about component-level issues with clear action recommendations, eliminating guesswork. By detecting faults up to 6 months before failure, maintenance can be scheduled during planned downtimes. This predictive capability allows teams to reduce safety stock, minimize routine inspections, and deploy resources only where needed.",
      content: (
        <ApplicationGrid
          cards={cardData2}
          title="Featured Projects"
          heroImage=""
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    reliabilty: {
      title: "Reliabiity ",
      description:
        "iHz™ provides comprehensive diagnostic data that reveals patterns in equipment behavior where previous systems failed. Teams can analyze fault progression across similar machines, including those in underground, underwater, or harsh environments where traditional sensors struggle. The system's ability to detect both electrical and mechanical faults through electrical signature analysis gives reliability teams unprecedented insight into asset health, helping optimize maintenance intervals and guide equipment investments.",
      content: (
        <ApplicationGrid
          cards={cardData3}
          title="Featured Projects"
          heroImage=""
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    production: {
      // Note: there's a typo in "Component" that you might want to fix
      title: "Production ",
      description:
        "iHz™'s diagnostics enable production teams to set achievable targets by providing clear equipment health visibility. Weeks of advance warning about developing issues lets them coordinate maintenance windows without disrupting production plans. The system's ability to monitor even hard-to-reach equipment ensures production schedules account for the real condition of all critical assets, maximizing uptime while avoiding unplanned disruptions.",
      content: (
        <ApplicationGrid
          cards={cardData4}
          title="Featured Projects"
          heroImage=""
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Other: {
      title: "Other",
      description: "",
      content: (
        <div className="flex h-full w-full items-center justify-center p-8 sm:p-12">
          <p className="max-w-2xl text-center text-lg sm:text-xl md:text-2xl font-medium leading-relaxed text-primary">
            Yeah! Our applications extend across numerous use cases and
            industries.{" "}
            <span className="relative inline-block">
              <span className="relative z-10">
                Let's connect and explore how iHz™ can address your unique
                monitoring needs.
              </span>
            </span>
          </p>
        </div>
      ),
    },
  };

  const handleSectionClick = (key) => {
    setExpandedSection(expandedSection === key ? null : key);
    // Modified to allow toggling sections closed
  };

  return (
    <div className="max-w-6xl mx-auto p-4 sm:p-6">
      <div className="bg-white/80 backdrop-blur-sm rounded-xl p-4 sm:p-6 lg:p-8 mb-8 shadow-sm border border-blue-100">
        <div className="flex flex-col lg:flex-row gap-6 lg:gap-8">
          {/* Content preview section */}
          <div className="w-full lg:w-2/3 order-2 lg:order-1">
            <div className="rounded-lg p-3 sm:p-4 transition-all duration-300 ease-in-out min-h-[250px] sm:min-h-[300px] lg:min-h-[400px]">
              {expandedSection && sections[expandedSection]?.content}
            </div>
          </div>

          {/* Accordion section */}
          <div className="w-full lg:w-1/3 order-1 lg:order-2">
            {Object.entries(sections).map(([key, section]) => (
              <div
                key={key}
                className="border-b border-blue-100 last:border-b-0"
              >
                <div className="py-3 sm:py-4">
                  <button
                    className={`w-full flex items-center justify-between text-left transition-colors duration-200 ease-in-out ${
                      expandedSection === key
                        ? "text-primary"
                        : "text-gray-700 hover:text-primary"
                    }`}
                    onClick={() => handleSectionClick(key)}
                    aria-expanded={expandedSection === key}
                    aria-controls={`fault-content-${key}`}
                  >
                    <span className="font-semibold text-sm sm:text-base pr-2">
                      {section.title}
                    </span>
                    <span
                      className="flex-shrink-0 transform transition-transform duration-200 ease-in-out"
                      aria-hidden="true"
                    >
                      {expandedSection === key ? (
                        <Minus className="w-4 h-4" />
                      ) : (
                        <Plus className="w-4 h-4" />
                      )}
                    </span>
                  </button>
                  <div
                    id={`fault-content-${key}`}
                    className={`transition-all duration-300 ease-in-out overflow-hidden ${
                      expandedSection === key
                        ? "max-h-72 opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                  >
                    {section.description && (
                      <div className="mt-2 text-sm text-primary pr-4">
                        {section.description}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamsAccordon;
