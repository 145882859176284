import React from "react";
import { Book, PenTool } from "lucide-react";

const BlogsLogo = () => {
  return (
    <div className="relative w-6 h-6 rounded-full bg-primary flex justify-center items-center group">
      <Book
        size={14}
        color="white"
        className="transform group-hover:scale-110 transition-transform duration-300 animate-pulse"
      />
      <div className="absolute -top-0.5 -right-0.5 bg-secondary rounded-full w-2.5 h-2.5 flex items-center justify-center animate-pulse">
        <PenTool
          size={6}
          color="white"
          className="animate-[wiggle_1s_ease-in-out_infinite]"
        />
      </div>
    </div>
  );
};

export default BlogsLogo;
