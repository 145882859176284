import { ParallaxScroll } from "./ui/ParallaxScroll";
import Bokaro from "../assets/clients/bokaro.jpg";
import Hil from "../assets/clients/HIL.jpg";
import Patil from "../assets/clients/patil.jpg";
import Sail from "../assets/clients/sail.jpg";
import PatilKallikal from "../assets/clients/patil-Kallakal.jpg";
import tataSteel from "../assets/clients/tataSteel.jpg";

export default function MahcineCoverage() {
  return <ParallaxScroll images={images} />;
}

const images = [Bokaro, Hil, Patil, Sail, PatilKallikal, tataSteel];
