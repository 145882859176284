import React, { useRef, useState } from "react";
import { cn } from "../lib/utils";

import howToWinFrineds from "../assets/How-to-win-friends.png";
import GettingThings from "../assets/getting-things-done.png";
import navalRaviKanth from "../assets/naval-ravikanth.png";
import OffTheShelf from "../assets/OffTheShelf.png";
import theOneStraw from "../assets/the-one-straw.png";
import upstream from "../assets/upStream.png";

const CoffeeGrid = ({
  subtitle = "Discover Our Favorites",
  title = "Inspiring Spaces & Reads",
  data = [],
}) => {
  const scrollContainerRef = useRef(null);

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 400;
      const container = scrollContainerRef.current;
      const newScrollPosition =
        container.scrollLeft +
        (direction === "right" ? scrollAmount : -scrollAmount);

      container.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

  const CaseCard = ({ title, description, backgroundImage }) => {
    return (
      <div className="min-w-[300px] w-[300px] flex-shrink-0">
        <div
          className={cn(
            "w-full cursor-pointer overflow-hidden relative card h-96 rounded-md shadow-xl mx-auto flex flex-col justify-end p-4 border border-transparent dark:border-neutral-800 bg-cover bg-center"
          )}
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          {/* Stronger gradient overlay that covers more of the card */}
          <div className="absolute inset-x-0 bottom-0 h-64 bg-gradient-to-t from-primary via-primary/70 to-transparent"></div>

          {/* Text container with additional text shadow for better readability */}
          <div className="relative z-10">
            <h1
              className="font-bold text-xl md:text-3xl text-white drop-shadow-lg"
              style={{ textShadow: "0 2px 4px rgba(0,0,0,0.5)" }}
            >
              {title}
            </h1>
            <p
              className="font-medium text-base text-gray-100 my-4 drop-shadow-lg"
              style={{ textShadow: "0 1px 2px rgba(0,0,0,0.5)" }}
            >
              {description}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const defaultData = [
    {
      title: "Upstream",
      description: "Ravi",
      backgroundImage: upstream,
    },
    {
      title: "Getting Things Done",
      description: "Ravi",
      backgroundImage: GettingThings,
    },
    {
      title: "Off The Shelf - Books & Stationery",
      description: "Ravi",
      backgroundImage: OffTheShelf,
    },
    {
      title: "The Almanack Of Naval Ravikant",
      description: "Adhitya",
      backgroundImage: navalRaviKanth,
    },
    {
      title: "How to Win Friends And Influence People",
      description: "Ravi",
      backgroundImage: howToWinFrineds,
    },
    {
      title: "Th One-Straw Revolution ",
      description: "Bhargav",
      backgroundImage: theOneStraw,
    },
  ];

  const caseStudiesData = data.length > 0 ? data : defaultData;

  return (
    <div className="max-w-8xl mx-[5%] px-4 py-8">
      {/* Header Section */}






      <div className="flex flex-col gap-2 mb-8">

        <div className="flex items-center gap-2">


        </div>

        <div className="flex justify-between items-center">
          <h1 className="text-4xl font-semibold text-gray-900"></h1>

          <div className="flex gap-4 items-center">
            <div className="flex gap-2">
              <button
                onClick={() => scroll("left")}
                className="p-2 rounded-full border border-secondary hover:bg-secondary-background text-secondary transition-colors"
              >
                <svg
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path d="M15 19l-7-7 7-7" />
                </svg>
              </button>
              <button
                onClick={() => scroll("right")}
                className="p-2 rounded-full border border-secondary hover:bg-secondary-background transition-colors text-secondary"
              >
                <svg
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Scrollable Cards Section */}
      <div
        ref={scrollContainerRef}
        className="flex gap-6 overflow-x-auto scrollbar-hide scroll-smooth"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        {caseStudiesData.map((item, index) => (
          <CaseCard
            key={index}
            title={item.title}
            description={item.description}
            backgroundImage={item.backgroundImage}
          />
        ))}
      </div>
    </div>
  );
};

export default CoffeeGrid;
