import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const CheckmarkIcon = () => (
  <svg viewBox="0 0 24 24" className="w-6 h-6 text-secondary">
    <path
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
      fill="currentColor"
    />
  </svg>
);

const TestimonialCarousel = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleSlideChange = (newIndex) => {
    if (isAnimating) return;
    setIsAnimating(true);
    setCurrentSlide(newIndex);
    setTimeout(() => setIsAnimating(false), 500);
  };

  const nextSlide = () => {
    const newIndex = (currentSlide + 1) % slides.length;
    handleSlideChange(newIndex);
  };

  const prevSlide = () => {
    const newIndex = currentSlide === 0 ? slides.length - 1 : currentSlide - 1;
    handleSlideChange(newIndex);
  };

  return (
    <div className="w-full bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="relative">
          {/* Mobile view */}
          <div className="relative md:hidden h-[1200px] overflow-hidden">
            <div className="absolute inset-0">
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={`absolute inset-0 w-full transition-all duration-500 ease-in-out ${
                    index === currentSlide
                      ? "translate-x-0 opacity-100 pointer-events-auto"
                      : index < currentSlide
                      ? "-translate-x-full opacity-0 pointer-events-none"
                      : "translate-x-full opacity-0 pointer-events-none"
                  }`}
                >
                  <div className="flex flex-col space-y-4 px-1">
                    <div className="bg-secondary-background rounded-2xl overflow-hidden shadow-sm h-auto">
                      <img
                        src={slide.image}
                        alt="Profile"
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="bg-secondary-background rounded-2xl p-6 shadow-sm">
                      <div className="flex items-center gap-2 mb-4">
                        <CheckmarkIcon />
                        <span className="font-bold text-lg text-secondary">
                          {slide.title}
                        </span>
                      </div>
                      <div className="mb-6 text-primary">
                        {typeof slide.component === "string" ? (
                          <div className="break-words text-primary">
                            {slide.component}
                          </div>
                        ) : (
                          <div className="prose max-w-full break-words text-primary">
                            {slide.component}
                          </div>
                        )}
                      </div>
                      <div className="mt-4 text-secondary">
                        <p className="font-bold text-lg mt-2 text-primary">
                          {slide.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Desktop view */}
          <div className="relative hidden md:block h-[550px]">
            <div className="absolute inset-0">
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={`absolute inset-0 w-full transition-all duration-500 ease-in-out ${
                    index === currentSlide
                      ? "translate-x-0 opacity-100 pointer-events-auto"
                      : index < currentSlide
                      ? "-translate-x-full opacity-0 pointer-events-none"
                      : "translate-x-full opacity-0 pointer-events-none"
                  }`}
                >
                  <div className="flex gap-6 h-[600px]">
                    <div className="w-2/5 bg-white rounded-2xl overflow-hidden shadow-sm">
                      <img
                        src={slide.image}
                        alt="Profile"
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="w-3/5 bg-secondary-background rounded-2xl p-12 shadow-sm flex flex-col">
                      <div className="flex items-center gap-2 mb-6">
                        <CheckmarkIcon />
                        <span className="font-bold text-lg text-secondary">
                          {slide.title}
                        </span>
                      </div>
                      <div className="flex-grow overflow-auto">
                        {typeof slide.component === "string" ? (
                          <div className="break-words">{slide.component}</div>
                        ) : (
                          <div className="prose max-w-full break-words">
                            {slide.component}
                          </div>
                        )}
                      </div>
                      <div className="mt-4 text-secondary">
                        <p className="font-bold text-lg mt-2">{slide.name}</p>
                      </div>
                      <div className="mt-6 flex justify-end gap-2">
                        <button
                          onClick={prevSlide}
                          className="p-3 border rounded-lg hover:bg-gray-50 transition-colors"
                          aria-label="Previous slide"
                        >
                          <ChevronLeft className="w-4 h-4" />
                        </button>
                        <button
                          onClick={nextSlide}
                          className="p-3 border rounded-lg hover:bg-gray-50 transition-colors"
                          aria-label="Next slide"
                        >
                          <ChevronRight className="w-4 h-4" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        <div className="md:hidden flex justify-center gap-2 mt-6">
          <button
            onClick={prevSlide}
            className="p-3 border rounded-lg bg-white hover:bg-gray-50 transition-colors"
            aria-label="Previous slide"
          >
            <ChevronLeft className="w-4 h-4" />
          </button>
          <button
            onClick={nextSlide}
            className="p-3 border rounded-lg bg-white hover:bg-gray-50 transition-colors"
            aria-label="Next slide"
          >
            <ChevronRight className="w-4 h-4" />
          </button>
        </div>

        {/* Pagination Dots */}
        <div className="flex justify-center gap-2 mt-6">
          {slides.map((_, index) => (
            <button
              key={index}
              onClick={() => handleSlideChange(index)}
              className={`w-2 h-2 rounded-full transition-colors ${
                currentSlide === index ? "bg-primary" : "bg-gray-200"
              }`}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
