import React from "react";

import solutioning from "../../assets/solutioning.png";

import poster from "../../assets/How_it_works_poster.png";

export default function RobustConn() {
  const features = [
    "Easy to get started with low CapEx",
    "Minimal infrastructure setup",
    "Network setup limited to the MCC, enabling plant-wide monitoring without extensive networking",
  ];

  return (
    <div className="w-full max-w-7xl mx-auto">
      <div className="flex flex-col lg:flex-row bg-white rounded-xl  overflow-hidden">
        <div className="lg:w-1/2 p-8 lg:p-12 ">
          <div className="space-y-6">
            <h2 className="text-3xl lg:text-4xl font-bold text-primary">
              Scale across your plant with reliable connectivity
            </h2>

            <ul className="space-y-4">
              {features.map((feature, index) => (
                <li key={index} className="flex items-center space-x-3">
                  <span className="flex-shrink-0 w-5 h-5 bg-secondary rounded-full flex items-center justify-center">
                    <svg
                      className="w-3 h-3 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={3}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </span>
                  <span className="text-primary">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="lg:w-1/2">
          <div className="h-full relative flex items-center justify-cente">
            <video
              src="https://minto-webapp-v3.s3.us-east-2.amazonaws.com/How_it_works.mp4"
              autoPlay
              loop
              muted
              playsInline // Prevents iOS from automatically going fullscreen
              webkit-playsinline="true"
              poster={poster}
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
