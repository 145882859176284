import React from "react";

// Note: Image imports would typically be handled differently in a React project.
// For this example, we'll assume these imports are correctly set up.
import raviImage from "../assets/pictures/ravi_edited.jpg";
import suryaImage from "../assets/pictures/surya_edited.jpg";
import bhargavImage from "../assets/pictures/bhargav_edited.jpg";
import udayImage from "../assets/pictures/uday.jpg";
import sairamImage from "../assets/pictures/sairam_edited.jpg";
import koushikImage from "../assets/pictures/koushik_edited-2.jpeg";
import adithyaImage from "../assets/pictures/adithya-2-2.jpeg";

const teamMembers = [
  {
    name: "Raviteja Valluri",
    role: "CEO & Co-founder",
    image: raviImage,
    socialLinks: [
      {
        href: "https://www.linkedin.com/in/raviteja-valluri-1bb41631/",
        color: "#39569c",
        iconPath:
          "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm0 3c1.11 0 2 .89 2 2s-.89 2-2 2-2-.89-2-2 .89-2 2-2zm0 14c-2.5 0-4.71-1.28-6-3.22 0-2 4-3.08 6-3.08 2.01 0 6 1.07 6 3.08C16.71 17.72 14.5 18 12 18z",
      },
    ],
  },
  {
    name: "Bhargav Krishna",
    role: "AI & ML Engineer",
    image: bhargavImage,
    socialLinks: [
      {
        href: "https://www.linkedin.com/in/bhargav-krishna-b63a5680/",
        color: "#39569c",
        iconPath:
          "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm0 3c1.11 0 2 .89 2 2s-.89 2-2 2-2-.89-2-2 .89-2 2-2zm0 14c-2.5 0-4.71-1.28-6-3.22 0-2 4-3.08 6-3.08 2.01 0 6 1.07 6 3.08C16.71 17.72 14.5 18 12 18z",
      },
    ],
  },
  {
    name: "Uday Kumar Galidevara",
    role: "Software Engineering",
    image: udayImage,
    socialLinks: [
      {
        href: "https://www.linkedin.com/in/udaya-kumar-galidevara-115a70127/",
        color: "#39569c",
        iconPath:
          "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm0 3c1.11 0 2 .89 2 2s-.89 2-2 2-2-.89-2-2 .89-2 2-2zm0 14c-2.5 0-4.71-1.28-6-3.22 0-2 4-3.08 6-3.08 2.01 0 6 1.07 6 3.08C16.71 17.72 14.5 18 12 18z",
      },
    ],
  },
  {
    name: "Sai Ram Varma",
    role: "IoT & Solutions",
    image: sairamImage,
    socialLinks: [
      {
        href: "https://www.linkedin.com/in/sairam-varma-nadimpalli",
        color: "#39569c",
        iconPath:
          "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm0 3c1.11 0 2 .89 2 2s-.89 2-2 2-2-.89-2-2 .89-2 2-2zm0 14c-2.5 0-4.71-1.28-6-3.22 0-2 4-3.08 6-3.08 2.01 0 6 1.07 6 3.08C16.71 17.72 14.5 18 12 18z",
      },
    ],
  },
  {
    name: "Koushik Gupta",
    role: "AI & ML Engineer",
    image: koushikImage,
    socialLinks: [
      {
        href: "https://www.linkedin.com/in/koushik-gupta-b07ab5207/?originalSubdomain=in",
        color: "#39569c",
        iconPath:
          "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm0 3c1.11 0 2 .89 2 2s-.89 2-2 2-2-.89-2-2 .89-2 2-2zm0 14c-2.5 0-4.71-1.28-6-3.22 0-2 4-3.08 6-3.08 2.01 0 6 1.07 6 3.08C16.71 17.72 14.5 18 12 18z",
      },
    ],
  },
  {
    name: "Adhitya Vardhan",
    role: "Software Engineering",
    image: adithyaImage,
    socialLinks: [
      {
        href: "https://in.linkedin.com/in/adhitya-vardhan",
        color: "#39569c",
        iconPath:
          "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm0 3c1.11 0 2 .89 2 2s-.89 2-2 2-2-.89-2-2 .89-2 2-2zm0 14c-2.5 0-4.71-1.28-6-3.22 0-2 4-3.08 6-3.08 2.01 0 6 1.07 6 3.08C16.71 17.72 14.5 18 12 18z",
      },
    ],
  },
];

const TeamMember = ({ member }) => (
  <div className="text-center text-primary " data-aos="fade-up">
    <img
      src={member.image}
      alt={`${member.name} Avatar`}
      className="mx-auto mb-4 w-36 h-36 rounded-full grayscale hover:grayscale-0"
    />
    <h3 className="mb-1 text-2xl font-bold tracking-tight text-primary ">
      <a href="#">{member.name}</a>
    </h3>
    <p className="text-secondary">{member.role}</p>
    <ul className="flex justify-center mt-4 space-x-4">
      {member.socialLinks.map((link, index) => (
        <li key={index}>
          <a href={link.href} className="text-primary hover:text-gray-500  ">
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d={link.iconPath} />
            </svg>
          </a>
        </li>
      ))}
    </ul>
  </div>
);

const TeamMembers = () => {
  return (
    <section className="bg-white">
      <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
        <div
          className="mx-auto mb-8 max-w-screen-md lg:mb-16"
          data-aos="fade-up"
        >

          <h3 className="mb-4 text-2xl tracking-tight font-bold text-primary">
            INTRODUCING TEAM!
          </h3>
          <p className="sm:text-xl text-primary">
            Artisans, Designers, hackers, warriors building accurate and
            reliable technology to ensure that the world run on time sustainable
            and efficiently!
          </p>
        </div>

        <div className="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
          {teamMembers.map((member, index) => (
            <TeamMember key={index} member={member} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamMembers;
