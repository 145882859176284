import React, { useState } from "react";
import { Plus, Minus } from "lucide-react";
import deployment from "../assets/deployment.jpg";
import SignalCapture from "./SignalCapture";
import Agile from "../assets/agiile-and-adaptive.jpg";

const Robust = () => {
  return (
    <img
      src={deployment}
      alt="deployment"
      className="w-full h-auto object-cover rounded-lg"
    />
  );
};

const Gile = () => {
  return (
    <img
      src={Agile}
      alt="Agile"
      className="w-full h-auto object-cover rounded-lg"
    />
  );
};

const Hardware = () => {
  const [expandedSection, setExpandedSection] = useState("deployment");

  const sections = {
    deployment: {
      title: "Robust Industrial-Grade Deployment",
      description:
        "Secure data collection from motor control cabinets. Protected from environmental factors, ensuring consistent and scalable data acquisition across your industrial setup.",
      content: <Robust />,
    },
    signalCapture: {
      title: "High-Resolution Signal Capture",
      description:
        "See every detail in your machine's electrical signature. High-frequency sampling captures the tiniest variations in behavior, giving you the resolution needed for accurate motor current analysis",
      content: <SignalCapture />,
    },
    agile: {
      title: "Agile and Adaptive Technology",
      description:
        "Flexible system adapts to changing software, compute, storage, and network needs. On-device processing and ML capabilities reduce costs and open new optimization opportunities.",
      content: <Gile />,
    },
  };

  const handleSectionClick = (key) => {
    if (key !== expandedSection) {
      setExpandedSection(key);
    }
  };

  return (
    <div className="bg-gradient-to-b from-blue-50 to-white">
      <div className="max-w-6xl mx-auto p-4 sm:p-6">
        {/* Main heading */}
        <div className="text-center mb-8 sm:mb-12">
          <div className="text-secondary font-semibold mb-3 sm:mb-4">
            HARDWARE
          </div>
          <h1 className="text-xl sm:text-2xl lg:text-3xl font-bold text-primary max-w-3xl mx-auto px-2">
            Industrial-grade hardware for complete asset visibility and
            intelligent monitoring
          </h1>
        </div>

        {/* Main content area */}
        <div className="bg-white/80 backdrop-blur-sm rounded-xl p-4 sm:p-6 lg:p-8 mb-8 shadow-sm border border-blue-100">
          <div className="flex flex-col lg:flex-row gap-6 lg:gap-8">
            {/* Content preview - Full width on mobile, 2/3 on desktop */}
            <div className="w-full lg:w-2/3 order-2 lg:order-1">
              <div className="rounded-lg p-3 sm:p-4 transition-all duration-300 ease-in-out min-h-[250px] sm:min-h-[300px] lg:min-h-[400px]">
                {sections[expandedSection].content}
              </div>
            </div>

            {/* Accordion - Full width on mobile, 1/3 on desktop */}
            <div className="w-full lg:w-1/3 order-1 lg:order-2">
              {Object.entries(sections).map(([key, section]) => (
                <div
                  key={key}
                  className="border-b border-blue-100 last:border-b-0"
                >
                  <div className="py-3 sm:py-4">
                    <button
                      className={`w-full flex items-center justify-between text-left transition-colors duration-200 ease-in-out ${
                        expandedSection === key
                          ? "text-primary"
                          : "text-gray-700 hover:text-primary"
                      }`}
                      onClick={() => handleSectionClick(key)}
                    >
                      <span className="font-semibold text-sm sm:text-base pr-2">
                        {section.title}
                      </span>
                      <span
                        className={`flex-shrink-0 transform transition-transform duration-200 ease-in-out ${
                          expandedSection === key ? "rotate-0" : "rotate-0"
                        }`}
                      >
                        {expandedSection === key ? (
                          <Minus className="w-4 h-4" />
                        ) : (
                          <Plus className="w-4 h-4" />
                        )}
                      </span>
                    </button>
                    <div
                      className={`transition-all duration-300 ease-in-out overflow-hidden ${
                        expandedSection === key
                          ? "max-h-40 opacity-100"
                          : "max-h-0 opacity-0"
                      }`}
                    >
                      {section.description && (
                        <div className="mt-2 text-sm text-primary pr-4">
                          {section.description}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hardware;
