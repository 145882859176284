import React from "react";
import {
  Factory,
  Wrench,
  AlertTriangle,
  Lightbulb,
  MessageSquare,
  ExternalLink,
  Mail,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Kallakal = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contactus");
  };

  const sections = [
    {
      title: "Plant Summary",
      icon: <Factory className="w-5 h-5 text-primary" />,
      content: `This is a sand casting plant manufacturing inserts for Railway sleepers - the fastener between rails and concrete foundational blocks. Its a plant with 6000 tonnes of annual capacity, having a disa 45kW sandmixer at its heart, pumps, conveyors, polygonal sieves and critical vibro motors, and exhaust fans operating for sandmixing and logistics, pumps for pumping water through the furnace, fans for cooling water, conveyors to transport sand across the circuit and vibro motors to shake out the sand out from the Castings, followed by finishing machines like shot blasts, EoT for operations.`,
    },
    {
      title: "Requirement",
      icon: <Wrench className="w-5 h-5 text-primary" />,
      content: "Reduce Breakdowns immediately",
    },
    {
      title: "Execution Challenges",
      icon: <AlertTriangle className="w-5 h-5 text-primary" />,
      content: `The foundry's harsh conditions - high temperatures, airborne sand, impact loads, and electromagnetic interference - accelerate equipment deterioration through lateral loads and dusty environments. The sand mixer's criticality makes downtime severely disruptive, while persistent vibro motor failures and conveyor issues indicate systemic problems. Though the plant aims to shift from reactive to preventive maintenance using IoT sensors, traditional monitoring methods face significant challenges: sensor durability issues in extreme conditions, drive train variability, signal interference, complex cable routing, and limited machine accessibility.`,
    },
    {
      title: "Solution",
      icon: <Lightbulb className="w-5 h-5 text-primary" />,
      content: `We began by thoroughly understanding the complexities of plant operations, identifying the forces impacting machines, and analyzing the drive train mechanics. Following this, we reviewed historical faults and concluded that monitoring less than 50% of the machines with iHz could avert at least 80% of unplanned breakdowns.`,
    },
  ];

  return (
    <div className="bg-gray-50 min-h-screen p-6">
      {/* Main Content */}
      <div>
        {sections.map((section, index) => (
          <div
            key={section.title}
            className="mb-8 last:mb-0 hover:bg-gray-50 rounded-lg transition-colors duration-200 p-4"
          >
            <div className="flex items-center space-x-3 mb-4">
              <div className="bg-blue-50 p-3 rounded-lg">{section.icon}</div>
              <h2 className="text-xl font-semibold text-gray-800">
                {section.title}
              </h2>
            </div>
            <div className="pl-14">
              <p className="text-gray-600 leading-relaxed">{section.content}</p>
            </div>
          </div>
        ))}

        {/* Testimonial Section */}
        <div className="mt-8 bg-blue-50 rounded-lg p-6">
          <div className="flex items-center space-x-3 mb-4">
            <div className="bg-blue-100 p-3 rounded-lg">
              <MessageSquare className="w-5 h-5 text-primary" />
            </div>
            <h2 className="text-xl font-semibold text-gray-800">
              Client Testimonial
            </h2>
          </div>
          <div className="pl-14">
            <div className="w-full max-w-2xl mx-auto p-4 sm:p-6 bg-blue-50">
              <div className="space-y-4 sm:space-y-6">
                <div className="relative">
                  {/* Quote mark - responsive size */}
                  <div className="absolute -left-2 sm:-left-6 top-0 text-4xl sm:text-6xl text-blue-500 font-serif select-none">
                    "
                  </div>

                  {/* Quote text - responsive font size and padding */}
                  <p className="text-lg sm:text-xl md:text-2xl text-gray-800 leading-relaxed pl-4 sm:pl-4">
                    Before minto.ai's solution, we had 83 hours of production
                    loss over three months, resulting in approximately 57,000
                    lost inserts. After implementing spidersense™, minto.ai's
                    condition monitoring platform, 90% of this downtime was
                    eliminated. Our maintenance engineers now have better
                    awareness of machines and failure mechanisms, with some
                    months seeing no downtime at all.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Case Study Link */}
        <div className="mt-8 border-t pt-6">
          <a
            href="#"
            className="inline-flex items-center space-x-2 text-blue-600 hover:text-blue-800 transition-colors duration-200"
          >
            <ExternalLink className="w-4 h-4" />
            <Link to="/usecase-health-and-load-profile">
              <span>View Fault Diagnosis Case Study Details</span>
            </Link>
          </a>
        </div>

        {/* Contact Section */}
        <div className="mt-8 bg-gradient-to-r from-blue-50 to-indigo-50 rounded-lg p-8 text-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Ready to Get Started?
          </h2>
          <p className="text-gray-600 mb-6">
            Contact us to discuss your equipment monitoring needs
          </p>
          <button
            onClick={handleClick}
            className="inline-flex items-center space-x-2 bg-secondary text-white px-6 py-3 rounded-lg hover:bg-secondary transition-colors duration-200"
          >
            <Mail className="w-5 h-5" />
            <span>Contact Us</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Kallakal;
