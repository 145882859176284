import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import React, { useEffect } from 'react';
import HomePage from "./pages/HomePage";
import Layout from "./pages/Layout";
import AboutUs from "./pages/AboutUs";
import SpiderAi from "./pages/SpiderAi";
import Articles from "./pages/Articles";
import Technologies from "./pages/Technologies";
import ContactUs from "./pages/ContactUs";
import GetAccess from "./pages/GetAccess";
import Ihz from "./pages/iHz";
import ScrollToTop from "./components/ScrollToTop";
import "./App.css";
import CaseStudies from "./pages/Usecase";
import UseCases from "./pages/UseCases";
import Bokaro from "./components/Blogs/Bokaro";
import EmbraceEvolution from "./components/Blogs/EmbraceEvolution";
import FeaturesofAI from "./components/Blogs/FeaturesOfAi";
import Introducing from "./components/Blogs/Introducing";
import MintoRecognisedas from "./components/Blogs/MintoRecognisedas";
import ProFoundImpact from "./components/Blogs/ProFoundImpact";
import UnveilingThe from "./components/Blogs/UnveilingThe";
import Careers from "./pages/Careers";
import UseCasePage from "./pages/UseCasePage";
import UseCaseHealthLoadProfile from "./pages/UseCaseHealthLoadProfile";
import ComingSoon from "./pages/ComingSoon";
import Applications from "./pages/Applications";
import SEO from "./components/SEO";
import { defaultSEO } from "./config/seo";


// Import SEO testing utilities
import testSEO from './utils/seoTester';

// Make testSEO available globally in development
if (process.env.NODE_ENV === 'development') {
  window.testSEO = testSEO;
}

// code starts here

function App() {

  useEffect(() => {
    const preloadUrls = [
      `https://d3dviy6vivq6am.cloudfront.net/ps_11_DFFT_animation_500x500.html`,
      `https://d3dviy6vivq6am.cloudfront.net/Machine_utilisation_of_a_Conveyor_Belt.html`,
      `https://d3dviy6vivq6am.cloudfront.net/scatter_plot.html`
    ];

    preloadUrls.forEach(url => {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'fetch';
      link.href = url;
      link.crossOrigin = 'anonymous';
      document.head.appendChild(link);
    });
  }, []);



  return (
    <Router>
      {/* Default SEO that will be overridden by specific pages */}
      <SEO {...defaultSEO} />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="spiderai" element={<SpiderAi />} />
          <Route path="ihz" element={<Ihz />} />
          <Route path="blog" element={<Articles />} />

          <Route path="technologies" element={<Technologies />} />
          <Route path="contactus" element={<ContactUs />} />
          <Route path="getaccess" element={<GetAccess />} />
          <Route
            path="/blog/embracing-evolution-with-spider-ai"
            element={<EmbraceEvolution />}
          />
          <Route
            path="/blog/features-of-ai-driven-iot-product"
            element={<FeaturesofAI />}
          />
          <Route path="blog/introducing" element={<Introducing />} />
          <Route
            path="/blog/minto-ai-recognized-as-top-2-innovative-startups"
            element={<MintoRecognisedas />}
          />
          <Route
            path="/blog/profound-impact-of-minto-ai"
            element={<ProFoundImpact />}
          />
          <Route
            path="/blog/unveiling-inefficiencies-in-traditional-maintenance-methods"
            element={<UnveilingThe />}
          />
          <Route
            path="/blog/bokaro-steel-plant-partners-with-minto-ai"
            element={<Bokaro />}
          />
          <Route path="careers" element={<Careers />} />
          <Route path="usecases" element={<UseCases />} />
          <Route path="casestudies" element={<CaseStudies />} />
          <Route path="usecase1" element={<UseCasePage />} />
          <Route path="applications" element={<Applications />} />
          <Route
            path="usecase-health-and-load-profile"
            element={<UseCaseHealthLoadProfile />}
          />
          <Route path="comingsoon" element={<ComingSoon />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
