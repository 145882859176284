import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import embrace from "../../assets/blog-images/embrace-evolution/image1-evol.webp";
import bokaro from "../../assets/blog-images/bokaro/bokaro.jpg";
import FeaturesofaI from "../../assets/blog-images/features-of-ai/unplanned_dt.jpg";
import MintoRecognisedas from "../../assets/blog-images/minto-recognised-as/PHOTO-2019-11-28-12-45-32-2-1-1024x576-1.jpg";
import ProFoundImpact from "../../assets/blog-images/profound-impact/profound_impact.jpg";
import unveilingThe from "../../assets/blog-images/unveiling-the/unveiling.jpg";

import { Twitter, Linkedin, Mail, Link, Clock } from "lucide-react";
import image from "../../assets/blog-images/features-of-ai/IMG_0763-1024x683-1.jpg";

const FeaturesofAI = () => {
  const blogPostsData = [
    {
      author: "spiders",
      categories: ["Product"],
      date: "2019-02-21T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title:
        "Features of an AI-Driven IoT Product That Solves the Problem of Unplanned Downtime",
      description:
        "In our previous articles, we've discussed the significant financial losses manufacturing companies and asset owners face due to unplanned downtime and why existing maintenance methods fall short. In this final article of the series, we will explore the features of an AI-driven IoT product that can dramatically reduce downtime and extend the lifespan of machines, providing a compelling investment opportunity for asset owners.",
      sortOrder: 5,
      featuredImage: FeaturesofaI,
      route: "/blog/features-of-ai-driven-iot-product",
    },
    {
      author: "spiders",
      categories: ["Value"],
      date: "2019-09-03T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title: "The Profound Impact of minto.ai™ Beyond Monetary Benefits",
      description:
        "In my journey with minto.ai™, I often pondered whether our product truly makes a difference in the world and brings meaningful change to people's lives. Beyond the financial gains, are we adding significant value to individuals and organizations?",
      sortOrder: 4,
      featuredImage: ProFoundImpact,
      route: "/blog/profound-impact-of-minto-ai",
    },
    {
      author: "spiders",
      categories: ["Awards"],
      date: "2019-06-12T00:00:00.000Z",
      featured: false,
      image: "../../assets/favicon.ico",
      title:
        "minto.ai™ recognized as top 2 innovative startups in the country by CK Birla group",
      description:
        "minto.ai is recognized by Birlasoft as one of the top two startups and runner-up across the country in the grand Finale happened at Birlasoft Xccelerate4startups event in Noida.",
      sortOrder: 3,
      featuredImage: MintoRecognisedas,
      route: "/blog/minto-ai-recognized-as-top-2-innovative-startups",
    },
    {
      author: "spiders",
      categories: ["Customers"],
      date: "2021-11-24T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title:
        "Bokaro Steel Plant(SAIL) partners with minto.ai™ to begin trials of AI-based Predictive Monitoring system",
      description:
        "We at minto.ai™ started the deployment of its Industrial IoT system connected to its platform **spidersense™** in steel plants. With the ongoing and future deployments in Steel Plants, we have entered an important segment where we believe there is a need to add value with our Industrial IoT platform called **spidersense™.**",
      sortOrder: 2,
      featuredImage: bokaro,
      route: "/blog/bokaro-steel-plant-partners-with-minto-ai",
    },
    {
      author: "Raviteja Valluri",
      categories: ["Product"],
      date: "2024-05-18T00:00:00.000Z",
      featured: false,
      image: embrace,
      title:
        "Embracing Evolution From Domesticators to Innovators with spiderAI™",
      description:
        "Humanity's journey from hunter-gatherers to technological pioneers is a testament to our species' ingenuity and resilience. Our relationship with tools are very old that in 2011, researchers",
      sortOrder: 1,
      featuredImage: embrace,
      route: "/blog/embracing-evolution-with-spider-ai",
    },
    {
      author: "spiders",
      categories: ["Problem"],
      date: "2019-02-06T00:00:00.000Z",
      featured: false,
      title: "Unveiling the Inefficiencies in Traditional Maintenance Methods",
      description:
        "As we delve further into the complexities of machine failures and disruptions in manufacturing facilities, it's evident that even the best maintenance strategies—such as RCM, FEMA, RBI, Failure Elimination, and Root Cause Analysis—fall short in",
      sortOrder: 6,
      image: "../../assets/people/ape-ravi.jpeg",
      featuredImage: unveilingThe,
      route:
        "/blog/unveiling-inefficiencies-in-traditional-maintenance-methods",
    },
  ];

  const navigate = useNavigate();
  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    const shuffled = [...blogPostsData].sort(() => 0.5 - Math.random());
    setRelatedArticles(shuffled.slice(0, 3));
  }, []);

  const handleArticleClick = (route) => {
    navigate(route);
  };

  return (
    <div className="pt-20 bg-white ">
      <div className="max-w-3xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-primary mb-6">
          Features of an AI-Driven IoT Product That Solves the Problem of
          Unplanned Downtime
        </h1>

        <div className="flex items-center text-primary  mb-6 space-x-4">
          <span>February 21, 2019</span>
        </div>

        <div className="text-primary mb-8">
          Topic: <span className="text-secondary ">Product</span>
        </div>

        <img
          src={image}
          alt="AI-Driven IoT Product"
          className="w-full h-full object-cover mb-6"
        />

        <p className="mb-8 text-primary ">
          In our previous articles, we've discussed the significant financial
          losses manufacturing companies and asset owners face due to unplanned
          downtime and why existing maintenance methods fall short. In this
          final article of the series, we will explore the features of an
          AI-driven IoT product that can dramatically reduce downtime and extend
          the lifespan of machines, providing a compelling investment
          opportunity for asset owners.
        </p>

        <h2 className="text-2xl font-bold text-primary  mb-6">
          Characteristics of an Effective Solution
        </h2>

        <p className="mb-6 text-primary ">
          The following characteristics define an effective solution for
          addressing unplanned downtime:
        </p>

        <h3 className="text-xl font-bold text-primary  mb-4">
          1. Continuous Monitoring of Critical Assets
        </h3>

        <p className="mb-6 text-primary ">
          Continuous monitoring is essential to prevent machine failures that
          can occur between scheduled data collection cycles. The technology
          should automate this process, addressing challenges related to
          variable operating conditions, consistency of readings, and
          scalability to handle hundreds of machines.
        </p>

        <p className="mb-6 text-primary ">
          At minto.ai™, we have developed an edge intelligence device that,
          after just a few minutes of installation, continuously monitors
          machines 24/7. Any abnormal behavior triggers notifications to users,
          ensuring no critical event is missed. This capability helps reduce
          unplanned downtime by 30-50%, according to industry benchmarks.
        </p>

        <h3 className="text-xl font-bold text-primary  mb-4">
          2. Access to Real-Time Data Insights
        </h3>

        <p className="mb-6 text-primary ">
          Real-time data insights enable companies to maintain their machines in
          optimal condition and control inefficiencies such as excessive power
          consumption, overloading, and insufficient cooling time.
        </p>

        <p className="mb-6 text-primary ">
          The technology should allow users to monitor critical machine
          parameters in real-time from any location, accessible to every
          authenticated user within the company. minto.ai™'s mobile app provides
          instantaneous values of machine functional parameters, acting as a
          mini collaboration platform for maintenance teams. This real-time
          monitoring can improve machine efficiency by up to 20% and reduce
          energy costs by 15%.
        </p>

        <h3 className="text-xl font-bold text-primary  mb-4">
          3. Intelligence
        </h3>

        <p className="mb-6 text-primary ">
          Intelligent systems help companies understand machine health and
          status without needing experts for frequency analysis and data
          interpretation. Advanced machine learning and AI technologies should
          interpret functional data and predict machine health in real-time.
        </p>

        <p className="mb-6 text-primary ">
          minto.ai™'s proprietary algorithm can determine whether a machine is
          healthy and becomes more accurate as it monitors more machines. This
          intelligence reduces the need for expert intervention by 60% and
          improves diagnostic accuracy by 25%.
        </p>

        <h3 className="text-xl font-bold text-primary  mb-4">
          4. Easy Installation & Non-Invasive
        </h3>

        <p className="mb-6 text-primary ">
          The technology should be easy to deploy and implement quickly without
          the need for wiring sensors or disturbing existing infrastructure,
          which is a common requirement with standard maintenance tools.
        </p>

        <p className="mb-6 text-primary ">
          This is particularly beneficial for industries with hard-to-reach
          machines, such as lift pumps in Oil & Gas, motors in hot rolling
          mills, and cooling fans in the power industry. minto.ai™'s technology
          allows operations and maintenance managers to remotely check the
          health of equipment like Modular Oil Pumping Stations (MOPS) in just 2
          minutes from anywhere, significantly reducing on-site maintenance time
          by 40%.
        </p>

        <h3 className="text-xl font-bold text-primary  mb-4">
          5. Cost-Effective
        </h3>

        <p className="mb-6 text-primary ">
          minto.ai™ challenges the traditional approach of upfront payments for
          maintenance tools. With the advent of Cloud & IoT technologies,
          companies should pay based on usage rather than investing heavily
          upfront.
        </p>

        <p className="mb-6 text-primary ">
          Our technology is designed to be 35-40 times less expensive than
          standard enterprise-level maintenance equipment. Additionally, we
          offer a flexible monthly subscription model, making it accessible and
          affordable for companies of all sizes. This cost-effective approach
          can reduce maintenance expenses by up to 50%.
        </p>

        <h2 className="text-2xl font-bold text-primary  mb-6">Conclusion</h2>

        <p className="mb-6 text-primary ">
          The five features outlined above are essential for any IIoT product
          aimed at reducing unplanned downtime. A product like minto.ai™, built
          with these features, represents a true IoT solution ready to transform
          the industry. Any product lacking these features risks wasting time
          and budgets, particularly for operations departments in manufacturing
          companies.
        </p>

        <p className="mb-6 text-primary ">
          Investing in an AI-driven IoT solution with these capabilities not
          only prevents downtime but also ensures a significant return on
          investment through improved efficiency, reduced maintenance costs, and
          extended machine life. Share this article with your colleagues to help
          spread the word about the future of maintenance technology.
        </p>
      </div>

      <div className="px-4 sm:px-8 md:px-16 lg:px-24 pb-6 sm:pb-8 md:pb-10">
        <div className="mt-8 sm:mt-12 md:mt-16 px-4 sm:px-8 md:px-16 lg:px-24">
          <h2 className="text-xl sm:text-2xl font-bold text-secondary mb-6 sm:mb-8">
            Related Articles
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
            {relatedArticles.map((article, index) => (
              <div
                key={index}
                className="flex flex-col cursor-pointer"
                onClick={() => handleArticleClick(article.route)}
              >
                <img
                  src={article.featuredImage}
                  alt={article.title}
                  className="w-full h-48 object-cover mb-4"
                />
                <div className="text-xs sm:text-sm font-semibold text-primary mb-2">
                  {article.categories[0]}
                </div>
                <h3 className="text-base sm:text-lg font-bold text-primary mb-2">
                  {article.title}
                </h3>
                <div className="mt-auto">
                  <div className="text-xs sm:text-sm text-primary mt-1 flex">
                    <span>Topic: </span>
                    <div className="text-secondary ml-1">
                      {article.categories.join(", ")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesofAI;
