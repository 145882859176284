import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Play, Pause, Volume2, VolumeX, MicVocal } from "lucide-react";

const formatTime = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${mins}:${secs.toString().padStart(2, "0")}`;
};

const WaveAnimation = ({ isPlaying, volume }) => {
  const generateBars = () => {
    const totalBars = 128; // More bars for fuller width
    const bars = [];

    for (let i = 0; i < totalBars; i++) {
      // Create a more natural wave pattern using multiple sine waves
      const position = i / totalBars;
      const wave1 = Math.sin(position * Math.PI * 2) * 0.5;
      const wave2 = Math.sin(position * Math.PI * 4) * 0.3;
      const wave3 = Math.sin(position * Math.PI * 6) * 0.2;

      const height = (wave1 + wave2 + wave3) * 100;
      bars.push(height); // Can be positive or negative now
    }
    return bars;
  };

  const bars = generateBars();

  return (
    <div className="flex items-center space-x-px h-32 w-full overflow-hidden">
      {bars.map((height, i) => {
        const absHeight = Math.abs(height);
        const heightAbove = height > 0 ? height : 0;
        const heightBelow = height < 0 ? -height : 0;

        return (
          <div
            key={i}
            className="flex-1 min-w-1 flex flex-col items-center justify-center h-full"
          >
            {/* Bar above centerline */}
            <motion.div
              className="w-full rounded-t"
              style={{
                backgroundColor: `rgba(107, 114, 128, ${isPlaying ? 0.7 : 0.2
                  })`,
                transformOrigin: "bottom",
              }}
              animate={{
                height: isPlaying
                  ? [
                    absHeight * volume * 0.4,
                    absHeight * volume * (0.3 + Math.random() * 0.5),
                    absHeight * volume * 0.4,
                  ]
                  : absHeight * volume * 0.2,
                backgroundColor: isPlaying
                  ? [
                    "rgba(107, 114, 128, 0.5)",
                    "rgba(107, 114, 128, 0.7)",
                    "rgba(107, 114, 128, 0.5)",
                  ]
                  : "rgba(107, 114, 128, 0.2)",
              }}
              transition={{
                duration: 1 + Math.random() * 0.5,
                repeat: Infinity,
                delay: i * 0.02,
                ease: "easeInOut",
              }}
            />

            {/* 1px separator line */}
            <div className="h-px w-full bg-gray-100" />

            {/* Bar below centerline */}
            <motion.div
              className="w-full rounded-b"
              style={{
                backgroundColor: `rgba(107, 114, 128, ${isPlaying ? 0.7 : 0.2
                  })`,
                transformOrigin: "top",
              }}
              animate={{
                height: isPlaying
                  ? [
                    absHeight * volume * 0.4,
                    absHeight * volume * (0.3 + Math.random() * 0.5),
                    absHeight * volume * 0.4,
                  ]
                  : absHeight * volume * 0.2,
                backgroundColor: isPlaying
                  ? [
                    "rgba(107, 114, 128, 0.5)",
                    "rgba(107, 114, 128, 0.7)",
                    "rgba(107, 114, 128, 0.5)",
                  ]
                  : "rgba(107, 114, 128, 0.2)",
              }}
              transition={{
                duration: 1 + Math.random() * 0.5,
                repeat: Infinity,
                delay: i * 0.02,
                ease: "easeInOut",
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

const ProgressBar = ({ value, max, onChange }) => {
  const progressRef = useRef(null);

  const handleClick = (e) => {
    if (!progressRef.current) return;
    const rect = progressRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const percentage = x / rect.width;
    onChange(percentage * max);
  };

  const percentage = (value / max) * 100;

  return (
    <div
      ref={progressRef}
      className="h-1.5 bg-primary-background rounded-full relative cursor-pointer group"
      onClick={handleClick}
    >
      <motion.div
        className="absolute top-0 left-0 h-full bg-primary rounded-full group-hover:bg-gray-700 transition-colors"
        style={{ width: `${percentage}%` }}
        animate={{ width: `${percentage}%` }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      />
    </div>
  );
};

const VolumeSlider = ({ value, onChange }) => {
  return (
    <div className="w-20 h-1.5 bg-primary-background rounded-full relative cursor-pointer group">
      <motion.div
        className="absolute top-0 left-0 h-full bg-primary rounded-full group-hover:bg-primary transition-colors"
        style={{ width: `${value * 100}%` }}
        animate={{ width: `${value * 100}%` }}
      />
      <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={value}
        onChange={(e) => onChange(parseFloat(e.target.value))}
        className="absolute inset-0 opacity-0 cursor-pointer"
      />
    </div>
  );
};

const AudioPlayer = ({
  audioSrc,
  title = "Podcast Episode",
  subtitle = "Latest Episode",
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(0.7);
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
    setIsMuted(newVolume === 0);
  };

  const toggleMute = () => {
    if (isMuted) {
      handleVolumeChange(volume || 0.7);
    } else {
      handleVolumeChange(0);
    }
  };

  return (
    <motion.div
      className="bg-white rounded-2xl shadow-lg border border-gray-200 p-8 max-w-4xl mx-auto"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <audio
        ref={audioRef}
        src={audioSrc}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        onEnded={() => setIsPlaying(false)}
      />

      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center space-x-4">
          <div className="w-12 h-12 rounded-full bg-secondary flex items-center justify-center">
            <MicVocal className="text-primary-background" size={24} />
          </div>
          <div>
            <h3 className="font-semibold text-secondary mb-1">{title}</h3>
            <p className="text-sm text-primary">{subtitle}</p>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <WaveAnimation isPlaying={isPlaying} volume={isMuted ? 0 : volume} />
      </div>

      <ProgressBar
        value={currentTime}
        max={duration || 100}
        onChange={(time) => {
          if (audioRef.current) {
            audioRef.current.currentTime = time;
            setCurrentTime(time);
          }
        }}
      />

      <div className="flex justify-between text-xs text-gray-500 mt-2 mb-6">
        <span>{formatTime(currentTime)}</span>
        <span>{formatTime(duration)}</span>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <motion.button
            onClick={toggleMute}
            className="p-2 text-primary hover:text-primary transition-colors"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {isMuted ? <VolumeX size={20} /> : <Volume2 size={20} />}
          </motion.button>
          <VolumeSlider
            value={isMuted ? 0 : volume}
            onChange={handleVolumeChange}
          />
        </div>

        <motion.button
          onClick={togglePlay}
          className="w-14 h-14 rounded-full bg-primary flex items-center justify-center text-white shadow-md hover:bg-primary transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <AnimatePresence mode="wait">
            {isPlaying ? (
              <motion.div
                key="pause"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
              >
                <Pause className="w-6 h-6" />
              </motion.div>
            ) : (
              <motion.div
                key="play"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
              >
                <Play className="w-6 h-6 ml-0.5" />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.button>

        <div className="w-24" />
      </div>
    </motion.div>
  );
};

const StyledInfoComponent = ({ audioSrc, imageSrc }) => {
  return (
    <div className="w-full bg-white">
      <div className="max-w-6xl mx-auto px-6 py-16">
        <div className="space-y-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-primary mb-4">
              Listen to our first Episode of the iHz™ introduction podcast
              series!
            </h2>
            <p className="text-primary max-w-2xl mx-auto">
              Listen to our team mates <b>Ria</b> and <b>Rio</b> discussing on <b>iHz™</b> for Total
              Equipment Intelligence. By the way, these two are not humans !
            </p>
          </div>
          <AudioPlayer className="bg-primary-background"
            audioSrc={audioSrc}
            title="The Future of Technology"
            subtitle="• Tech Insights Series"
          />
        </div>
      </div>
    </div>
  );
};

export default StyledInfoComponent;
