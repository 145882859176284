import React, { useState } from "react";
import { ChevronRight } from "lucide-react";

// API endpoint - replace with your actual API endpoint
const API_ENDPOINT =
  "https://ugkluryc86.execute-api.us-east-2.amazonaws.com/prod/v4/spiderAI/website/contactus";

const Alert = ({ type, message }) => {
  const bgColor = type === "success" ? "bg-green-100" : "bg-red-100";
  const textColor = type === "success" ? "text-green-800" : "text-red-800";
  const borderColor =
    type === "success" ? "border-green-400" : "border-red-400";

  return (
    <div
      className={`${bgColor} ${textColor} ${borderColor} border-l-4 p-4 mb-4 rounded`}
    >
      <p className="text-sm">{message}</p>
    </div>
  );
};

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneCountry: "+91",
    phoneNumber: "",
    organisation: "",
    description: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [errors, setErrors] = useState({});

  // Country codes array remains the same as in your original code
  const countryCodes = [
    { code: "+91", country: "India" },
    { code: "+93", country: "Afghanistan" },
    { code: "+355", country: "Albania" },
    { code: "+213", country: "Algeria" },
    { code: "+376", country: "Andorra" },
    { code: "+244", country: "Angola" },
    { code: "+54", country: "Argentina" },
    { code: "+374", country: "Armenia" },
    { code: "+61", country: "Australia" },
    { code: "+43", country: "Austria" },
    { code: "+994", country: "Azerbaijan" },
    { code: "+973", country: "Bahrain" },
    { code: "+880", country: "Bangladesh" },
    { code: "+375", country: "Belarus" },
    { code: "+32", country: "Belgium" },
    { code: "+975", country: "Bhutan" },
    { code: "+591", country: "Bolivia" },
    { code: "+387", country: "Bosnia" },
    { code: "+267", country: "Botswana" },
    { code: "+55", country: "Brazil" },
    { code: "+359", country: "Bulgaria" },
    { code: "+855", country: "Cambodia" },
    { code: "+237", country: "Cameroon" },
    { code: "+1", country: "Canada" },
    { code: "+56", country: "Chile" },
    { code: "+57", country: "Colombia" },
    { code: "+506", country: "Costa Rica" },
    { code: "+385", country: "Croatia" },
    { code: "+53", country: "Cuba" },
    { code: "+357", country: "Cyprus" },
    { code: "+420", country: "Czech Republic" },
    { code: "+45", country: "Denmark" },
    { code: "+20", country: "Egypt" },
    { code: "+372", country: "Estonia" },
    { code: "+251", country: "Ethiopia" },
    { code: "+358", country: "Finland" },
    { code: "+33", country: "France" },
    { code: "+995", country: "Georgia" },
    { code: "+49", country: "Germany" },
    { code: "+30", country: "Greece" },
    { code: "+299", country: "Greenland" },
    { code: "+502", country: "Guatemala" },
    { code: "+852", country: "Hong Kong" },
    { code: "+36", country: "Hungary" },
    { code: "+354", country: "Iceland" },
    { code: "+62", country: "Indonesia" },
    { code: "+98", country: "Iran" },
    { code: "+964", country: "Iraq" },
    { code: "+353", country: "Ireland" },
    { code: "+972", country: "Israel" },
    { code: "+39", country: "Italy" },
    { code: "+225", country: "Ivory Coast" },
    { code: "+962", country: "Jordan" },

    { code: "+254", country: "Kenya" },
    { code: "+82", country: "Korea, South" },
    { code: "+965", country: "Kuwait" },
    { code: "+856", country: "Laos" },
    { code: "+371", country: "Latvia" },
    { code: "+961", country: "Lebanon" },
    { code: "+218", country: "Libya" },
    { code: "+423", country: "Liechtenstein" },
    { code: "+370", country: "Lithuania" },
    { code: "+352", country: "Luxembourg" },
    { code: "+853", country: "Macau" },
    { code: "+389", country: "Macedonia" },
    { code: "+261", country: "Madagascar" },
    { code: "+60", country: "Malaysia" },
    { code: "+356", country: "Malta" },
    { code: "+52", country: "Mexico" },
    { code: "+377", country: "Monaco" },
    { code: "+976", country: "Mongolia" },
    { code: "+212", country: "Morocco" },
    { code: "+95", country: "Myanmar" },
    { code: "+977", country: "Nepal" },
    { code: "+31", country: "Netherlands" },
    { code: "+64", country: "New Zealand" },
    { code: "+234", country: "Nigeria" },
    { code: "+47", country: "Norway" },
    { code: "+968", country: "Oman" },
    { code: "+92", country: "Pakistan" },
    { code: "+970", country: "Palestine" },
    { code: "+507", country: "Panama" },
    { code: "+63", country: "Philippines" },
    { code: "+48", country: "Poland" },
    { code: "+351", country: "Portugal" },
    { code: "+974", country: "Qatar" },
    { code: "+40", country: "Romania" },

    // Change to this:
    { code: "+7-KZ", country: "Kazakhstan (+7)" },
    // ... other codes ...
    { code: "+7-RU", country: "Russia (+7)" },
    { code: "+966", country: "Saudi Arabia" },
    { code: "+381", country: "Serbia" },
    { code: "+65", country: "Singapore" },
    { code: "+421", country: "Slovakia" },
    { code: "+386", country: "Slovenia" },
    { code: "+27", country: "South Africa" },
    { code: "+34", country: "Spain" },
    { code: "+94", country: "Sri Lanka" },
    { code: "+46", country: "Sweden" },
    { code: "+41", country: "Switzerland" },
    { code: "+886", country: "Taiwan" },
    { code: "+992", country: "Tajikistan" },
    { code: "+66", country: "Thailand" },
    { code: "+216", country: "Tunisia" },
    { code: "+90", country: "Turkey" },
    { code: "+993", country: "Turkmenistan" },
    { code: "+256", country: "Uganda" },
    { code: "+380", country: "Ukraine" },
    { code: "+971", country: "United Arab Emirates" },
    { code: "+598", country: "Uruguay" },
    { code: "+998", country: "Uzbekistan" },
    { code: "+58", country: "Venezuela" },
    { code: "+84", country: "Vietnam" },
    { code: "+967", country: "Yemen" },
    { code: "+260", country: "Zambia" },
    { code: "+263", country: "Zimbabwe" },
  ];

  const validateForm = () => {
    const newErrors = {};

    // Name validation
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    } else if (formData.name.length < 2) {
      newErrors.name = "Name must be at least 2 characters long";
    }

    // Email validation
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)
    ) {
      newErrors.email = "Please enter a valid email address";
    }

    // Phone validation
    if (!formData.phoneNumber.trim()) {
      newErrors.phoneNumber = "Phone number is required";
    } else {
      const phoneDigits = formData.phoneNumber.replace(/\D/g, "");
      if (phoneDigits.length < 10 || phoneDigits.length > 15) {
        newErrors.phoneNumber = "Phone number must be between 10 and 15 digits";
      }
    }

    // Organisation validation
    if (!formData.organisation.trim()) {
      newErrors.organisation = "Organisation is required";
    } else if (formData.organisation.length < 2) {
      newErrors.organisation =
        "Organisation name must be at least 2 characters long";
    }

    // Description validation
    if (!formData.description.trim()) {
      newErrors.description = "Description is required";
    } else if (formData.description.length < 10) {
      newErrors.description =
        "Please provide a more detailed description (minimum 10 characters)";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear error for the field being edited
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }

    // Clear any API errors when the user starts typing again
    if (apiError) {
      setApiError(null);
    }
  };

  const formatPhoneNumber = (phoneCountry, phoneNumber) => {
    const cleanNumber = phoneNumber.replace(/\D/g, "");
    return `${phoneCountry}${cleanNumber}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setApiError(null);

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const formattedData = {
        ...formData,
        phoneNumber: formatPhoneNumber(
          formData.phoneCountry,
          formData.phoneNumber
        ),
      };

      const response = await fetch(API_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formattedData),
      });

      if (!response.ok) {
        // Handle different HTTP error status codes
        let errorMessage = "Failed to submit form. ";

        switch (response.status) {
          case 400:
            errorMessage += "Please check your input and try again.";
            break;
          case 429:
            errorMessage += "Too many requests. Please try again later.";
            break;
          case 500:
            errorMessage += "Server error. Please try again later.";
            break;
          default:
            errorMessage += "Please try again later.";
        }

        throw new Error(errorMessage);
      }

      const data = await response.json();
      setShowSuccess(true);

      // Reset form
      setFormData({
        name: "",
        email: "",
        phoneCountry: "+91",
        phoneNumber: "",
        organisation: "",
        description: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiError(error.message || "Failed to submit form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (showSuccess) {
    return (
      <div className="max-w-xl mx-auto p-8 bg-white rounded-lg">
        <Alert
          type="success"
          message="Thank you for your interest. We'll be in touch shortly."
        />
      </div>
    );
  }

  return (
    <div className="w-full max-w-3xl mx-auto bg-secondary-background p-4 sm:p-8">
      <div className="py-6">
        <h1 className="text-3xl font-bold text-center text-secondary mb-2">
          Contact Us
        </h1>
      </div>

      {apiError && <Alert type="error" message={apiError} />}

      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Name and Email */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="name" className="block text-sm text-primary mb-1">
              Name <span className="text-secondary">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={`w-full border-b ${
                errors.name ? "border-secondary" : "border-primary-background"
              } pb-2 focus:border-primary focus:outline-none bg-transparent`}
              aria-invalid={errors.name ? "true" : "false"}
            />
            {errors.name && (
              <p className="text-secondary text-xs mt-1" role="alert">
                {errors.name}
              </p>
            )}
          </div>

          <div>
            <label htmlFor="email" className="block text-sm text-primary mb-1">
              Email <span className="text-secondary">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={`w-full border-b ${
                errors.email ? "border-secondary" : "border-primary-background"
              } pb-2 focus:border-primary focus:outline-none bg-transparent`}
              aria-invalid={errors.email ? "true" : "false"}
            />
            {errors.email && (
              <p className="text-secondary text-xs mt-1" role="alert">
                {errors.email}
              </p>
            )}
          </div>
        </div>

        {/* Organisation */}
        <div>
          <label
            htmlFor="organisation"
            className="block text-sm text-primary mb-1"
          >
            Organisation <span className="text-secondary">*</span>
          </label>
          <input
            type="text"
            id="organisation"
            name="organisation"
            value={formData.organisation}
            onChange={handleChange}
            className={`w-full border-b ${
              errors.organisation
                ? "border-secondary"
                : "border-primary-background"
            } pb-2 focus:border-primary focus:outline-none bg-transparent`}
            aria-invalid={errors.organisation ? "true" : "false"}
          />
          {errors.organisation && (
            <p className="text-secondary text-xs mt-1" role="alert">
              {errors.organisation}
            </p>
          )}
        </div>

        {/* Phone Number */}
        <div className="space-y-4">
          <label htmlFor="phoneNumber" className="block text-sm text-primary">
            Phone Number <span className="text-secondary">*</span>
          </label>
          <div className="flex flex-col md:flex-row gap-4">
            <div className="md:w-1/3">
              <div className="relative">
                <select
                  id="phoneCountry"
                  name="phoneCountry"
                  value={formData.phoneCountry}
                  onChange={handleChange}
                  className="w-full border-b border-primary-background pb-2 focus:border-primary focus:outline-none bg-transparent appearance-none"
                >
                  {countryCodes.map(({ code, country }) => (
                    <option
                      key={code}
                      value={code.split("-")[0]}
                      className="text-primary"
                    >
                      {`${code.split("-")[0]} (${country})`}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-primary">
                  <ChevronRight className="w-4 h-4 transform rotate-90" />
                </div>
              </div>
            </div>
            <div className="md:w-2/3">
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className={`w-full border-b ${
                  errors.phoneNumber
                    ? "border-secondary"
                    : "border-primary-background"
                } pb-2 focus:border-primary focus:outline-none bg-transparent`}
                placeholder="Phone number"
                aria-invalid={errors.phoneNumber ? "true" : "false"}
              />
              {errors.phoneNumber && (
                <p className="text-secondary text-xs mt-1" role="alert">
                  {errors.phoneNumber}
                </p>
              )}
            </div>
          </div>
        </div>

        {/* Description */}
        <div>
          <label
            htmlFor="description"
            className="block text-sm text-primary mb-1"
          >
            Description <span className="text-secondary">*</span>
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            rows={4}
            className={`w-full border-b ${
              errors.description
                ? "border-secondary"
                : "border-primary-background"
            } pb-2 focus:border-primary focus:outline-none bg-transparent resize-none`}
            placeholder="Tell us about your requirements"
            aria-invalid={errors.description ? "true" : "false"}
          />
          {errors.description && (
            <p className="text-secondary text-xs mt-1" role="alert">
              {errors.description}
            </p>
          )}
        </div>

        {/* Submit Button */}
        <div className="flex justify-end pt-8">
          <button
            type="submit"
            disabled={isSubmitting}
            className="flex items-center ml-6 gap-2.5 px-6 py-3 text-sm transition-colors duration-200 ease-in-out font-medium tracking-wide text-white uppercase bg-secondary hover:bg-secondary disabled:bg-secondary disabled:cursor-not-allowed"
          >
            <span className="flex-grow font-bold">
              {isSubmitting ? "Sending..." : "SUBMIT"}
            </span>
            <ChevronRight className="flex-shrink-0" size={18} />
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
