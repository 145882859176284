import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Search, ChevronLeft, ChevronRight } from "lucide-react";
import embrace from "../assets/blog-images/embrace-evolution/image1-evol.webp";
import bokaro from "../assets/blog-images/bokaro/bokaro.jpg";
import FeaturesofAI from "../assets/blog-images/features-of-ai/unplanned_dt.jpg";
import MintoRecognisedas from "../assets/blog-images/minto-recognised-as/PHOTO-2019-11-28-12-45-32-2-1-1024x576-1.jpg";
import ProFoundImpact from "../assets/blog-images/profound-impact/profound_impact.jpg";
import unveilingThe from "../assets/blog-images/unveiling-the/unveiling.jpg";

const ArticleList = () => {
  const blogPostsData = [
    {
      author: "spiders",
      categories: ["Product"],
      date: "2019-02-21T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title:
        "Features of an AI-Driven IoT Product That Solves the Problem of Unplanned Downtime",
      description:
        "In our previous articles, we've discussed the significant financial losses manufacturing companies and asset owners face due to unplanned downtime and why existing maintenance methods fall short. In this final article of the series, we will explore the features of an AI-driven IoT product that can dramatically reduce downtime and extend the lifespan of machines, providing a compelling investment opportunity for asset owners.",
      sortOrder: 5,
      featuredImage: FeaturesofAI,
      route: "/blog/features-of-ai-driven-iot-product",
    },
    {
      author: "spiders",
      categories: ["Value"],
      date: "2019-09-03T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title: "The Profound Impact of minto.ai™ Beyond Monetary Benefits",
      description:
        "In my journey with minto.ai™, I often pondered whether our product truly makes a difference in the world and brings meaningful change to people's lives. Beyond the financial gains, are we adding significant value to individuals and organizations?",
      sortOrder: 4,
      featuredImage: ProFoundImpact,
      route: "/blog/profound-impact-of-minto-ai",
    },
    {
      author: "spiders",
      categories: ["Awards"],
      date: "2019-06-12T00:00:00.000Z",
      featured: false,
      image: "../../assets/favicon.ico",
      title:
        "minto.ai™ recognized as top 2 innovative startups in the country by CK Birla group",
      description:
        "minto.ai is recognized by Birlasoft as one of the top two startups and runner-up across the country in the grand Finale happened at Birlasoft Xccelerate4startups event in Noida.",
      sortOrder: 3,
      featuredImage: MintoRecognisedas,
      route: "/blog/minto-ai-recognized-as-top-2-innovative-startups",
    },
    {
      author: "spiders",
      categories: ["Customers"],
      date: "2021-11-24T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title:
        "Bokaro Steel Plant(SAIL) partners with minto.ai™ to begin trials of AI-based Predictive Monitoring system",
      description:
        "We at minto.ai™ started the deployment of its Industrial IoT system connected to its platform **spidersense™** in steel plants. With the ongoing and future deployments in Steel Plants, we have entered an important segment where we believe there is a need to add value with our Industrial IoT platform called **spidersense™.**",
      sortOrder: 2,
      featuredImage: bokaro,
      route: "/blog/bokaro-steel-plant-partners-with-minto-ai",
    },
    {
      author: "Raviteja Valluri",
      categories: ["Product"],
      date: "2024-05-18T00:00:00.000Z",
      featured: false,
      image: embrace,
      title:
        "Embracing Evolution From Domesticators to Innovators with spiderAI™",
      description:
        "Humanity's journey from hunter-gatherers to technological pioneers is a testament to our species' ingenuity and resilience. Our relationship with tools are very old that in 2011, researchers",
      sortOrder: 1,
      featuredImage: embrace,
      route: "/blog/embracing-evolution-with-spider-ai",
    },
    {
      author: "spiders",
      categories: ["Problem"],
      date: "2019-02-06T00:00:00.000Z",
      featured: false,
      title: "Unveiling the Inefficiencies in Traditional Maintenance Methods",
      description:
        "As we delve further into the complexities of machine failures and disruptions in manufacturing facilities, it's evident that even the best maintenance strategies—such as RCM, FEMA, RBI, Failure Elimination, and Root Cause Analysis—fall short in",
      sortOrder: 6,
      image: "../../assets/people/ape-ravi.jpeg",
      featuredImage: unveilingThe,
      route:
        "/blog/unveiling-inefficiencies-in-traditional-maintenance-methods",
    },
  ];

  const [articles, setArticles] = useState(blogPostsData);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredArticles, setFilteredArticles] = useState(articles);
  const articlesPerPage = 6;

  useEffect(() => {
    const filtered = blogPostsData.filter((article) => {
      const searchLower = searchTerm.toLowerCase();
      const titleMatch = article.title.toLowerCase().includes(searchLower);
      const categoryMatch = article.categories.some((category) =>
        category.toLowerCase().includes(searchLower)
      );
      const descriptionMatch = article.description
        .toLowerCase()
        .includes(searchLower);

      return titleMatch || categoryMatch || descriptionMatch;
    });

    setFilteredArticles(filtered);
    setCurrentPage(1); // Reset to first page when search changes
  }, [searchTerm]);

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="container mx-auto px-4 sm:px-8 md:px-16 pb-4 lg:px-24">
      <div className="px-4 sm:px-8 md:px-16 lg:px-24">
        <h1 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-primary">
          Article
        </h1>

        <div className="relative mb-4 md:mb-6">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search by title, topic, or content..."
            className="w-full p-2 pl-10 border bg-white border-gray-300 rounded"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 bg-white text-gray-400" />
        </div>

        {currentArticles.length === 0 ? (
          <div className="text-center py-8 text-gray-600">
            No articles found matching your search criteria.
          </div>
        ) : (
          currentArticles.map((article) => (
            <Link
              to={article.route}
              key={article.title}
              className="block mb-6 md:mb-8 border-b pb-4 md:pb-6 hover:bg-gray-50 transition-colors duration-200"
            >
              <div className="flex flex-col md:flex-row">
                <img
                  src={article.featuredImage}
                  alt={article.title}
                  className="w-full md:w-1/3 h-48 object-cover mb-4 md:mb-0 md:mr-6"
                />
                <div className="flex-1">
                  <h2 className="text-lg md:text-xl font-bold text-primary mb-2">
                    {article.title}
                  </h2>
                  <p className="text-primary mb-2">{article.description}</p>
                  <div className="flex items-center text-sm text-primary">
                    <span>
                      {new Date(article.date).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </span>
                  </div>
                  <div className="mt-auto">
                    <div className="text-xs sm:text-sm text-primary mt-1 flex flex-wrap gap-1">
                      <span>Topic: </span>
                      {article.categories.map((category, index) => (
                        <span
                          key={index}
                          className="text-secondary hover:text-secondary-dark"
                        >
                          {category}
                          {index < article.categories.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))
        )}

        {filteredArticles.length > articlesPerPage && (
          <div className="flex justify-center items-center space-x-2">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="p-1 md:p-2 border rounded disabled:opacity-50"
            >
              <ChevronLeft className="w-4 h-4 md:w-5 md:h-5" />
            </button>
            {Array.from({
              length: Math.ceil(filteredArticles.length / articlesPerPage),
            }).map((_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`px-2 md:px-3 py-1 border rounded text-sm md:text-base ${
                  currentPage === index + 1 ? "bg-primary text-white" : ""
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={
                currentPage ===
                Math.ceil(filteredArticles.length / articlesPerPage)
              }
              className="p-1 md:p-2 border rounded disabled:opacity-50"
            >
              <ChevronRight className="w-4 h-4 md:w-5 md:h-5" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleList;
