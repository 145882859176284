import React from "react";
import { Users, Info } from "lucide-react";

const AboutUsLogo = () => {
  return (
    <div className="relative w-6 h-6 rounded-full bg-secondary flex justify-center items-center group">
      <Users
        size={14}
        color="white"
        className="transform group-hover:scale-110 transition-transform duration-300"
      />
      <div className="absolute -top-0.5 -right-0.5 bg-primary rounded-full w-2.5 h-2.5 flex items-center justify-center animate-pulse">
        <Info size={6} color="white" className="animate-bounce" />
      </div>
    </div>
  );
};

export default AboutUsLogo;
