// src/components/SEO.jsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { defaultSEO } from '../config/seo';

const SEO = ({
    title,
    description,
    image = "https://s3.us-east-2.amazonaws.com/minto.ai-resources/website/spiderAI_favicon.png",
    url = 'https://minto.ai',
    type = 'website',
    keywords
}) => {
    return (
        <Helmet prioritizeSeoTags={true}>
            {/* Standard meta tags */}
            <title>{title || defaultSEO.title}</title>
            <meta name="description" content={description || defaultSEO.description} />
            <meta name="keywords" content={keywords || defaultSEO.keywords} />

            {/* Open Graph / Facebook */}
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title || defaultSEO.title} />
            <meta property="og:description" content={description || defaultSEO.description} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={url} />
            <meta property="og:site_name" content="minto.ai" />

            {/* Twitter Card */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title || defaultSEO.title} />
            <meta name="twitter:description" content={description || defaultSEO.description} />
            <meta name="twitter:image" content={image} />

            {/* Additional meta tags */}
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <link rel="canonical" href={url} />

            {/* Favicon */}
            <link rel="icon" href="https://s3.us-east-2.amazonaws.com/minto.ai-resources/website/favicon.ico" />
            <link rel="apple-touch-icon" href="https://s3.us-east-2.amazonaws.com/minto.ai-resources/website/spiderAI_favicon.png" />
        </Helmet>
    );
};

export default SEO;